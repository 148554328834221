{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-02-01",
    "endpointPrefix": "account",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Account",
    "serviceId": "Account",
    "signatureVersion": "v4",
    "signingName": "account",
    "uid": "account-2021-02-01"
  },
  "operations": {
    "DeleteAlternateContact": {
      "http": {
        "requestUri": "/deleteAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {}
        }
      },
      "idempotent": true
    },
    "DisableRegion": {
      "http": {
        "requestUri": "/disableRegion",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "RegionName"
        ],
        "members": {
          "AccountId": {},
          "RegionName": {}
        }
      }
    },
    "EnableRegion": {
      "http": {
        "requestUri": "/enableRegion",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "RegionName"
        ],
        "members": {
          "AccountId": {},
          "RegionName": {}
        }
      }
    },
    "GetAlternateContact": {
      "http": {
        "requestUri": "/getAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AlternateContact": {
            "type": "structure",
            "members": {
              "AlternateContactType": {},
              "EmailAddress": {
                "shape": "Sa"
              },
              "Name": {
                "shape": "Sb"
              },
              "PhoneNumber": {
                "shape": "Sc"
              },
              "Title": {
                "shape": "Sd"
              }
            }
          }
        }
      }
    },
    "GetContactInformation": {
      "http": {
        "requestUri": "/getContactInformation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactInformation": {
            "shape": "Sg"
          }
        }
      }
    },
    "GetRegionOptStatus": {
      "http": {
        "requestUri": "/getRegionOptStatus",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "RegionName"
        ],
        "members": {
          "AccountId": {},
          "RegionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegionName": {},
          "RegionOptStatus": {}
        }
      }
    },
    "ListRegions": {
      "http": {
        "requestUri": "/listRegions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "RegionOptStatusContains": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Regions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RegionName": {},
                "RegionOptStatus": {}
              }
            }
          }
        }
      }
    },
    "PutAlternateContact": {
      "http": {
        "requestUri": "/putAlternateContact",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AlternateContactType",
          "EmailAddress",
          "Name",
          "PhoneNumber",
          "Title"
        ],
        "members": {
          "AccountId": {},
          "AlternateContactType": {},
          "EmailAddress": {
            "shape": "Sa"
          },
          "Name": {
            "shape": "Sb"
          },
          "PhoneNumber": {
            "shape": "Sc"
          },
          "Title": {
            "shape": "Sd"
          }
        }
      },
      "idempotent": true
    },
    "PutContactInformation": {
      "http": {
        "requestUri": "/putContactInformation",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactInformation"
        ],
        "members": {
          "AccountId": {},
          "ContactInformation": {
            "shape": "Sg"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sa": {
      "type": "string",
      "sensitive": true
    },
    "Sb": {
      "type": "string",
      "sensitive": true
    },
    "Sc": {
      "type": "string",
      "sensitive": true
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Sg": {
      "type": "structure",
      "required": [
        "AddressLine1",
        "City",
        "CountryCode",
        "FullName",
        "PhoneNumber",
        "PostalCode"
      ],
      "members": {
        "AddressLine1": {
          "shape": "Sh"
        },
        "AddressLine2": {
          "shape": "Sh"
        },
        "AddressLine3": {
          "shape": "Sh"
        },
        "City": {
          "type": "string",
          "sensitive": true
        },
        "CompanyName": {
          "type": "string",
          "sensitive": true
        },
        "CountryCode": {
          "type": "string",
          "sensitive": true
        },
        "DistrictOrCounty": {
          "type": "string",
          "sensitive": true
        },
        "FullName": {
          "type": "string",
          "sensitive": true
        },
        "PhoneNumber": {
          "type": "string",
          "sensitive": true
        },
        "PostalCode": {
          "type": "string",
          "sensitive": true
        },
        "StateOrRegion": {
          "type": "string",
          "sensitive": true
        },
        "WebsiteUrl": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sh": {
      "type": "string",
      "sensitive": true
    }
  }
}