{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "rolesanywhere",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "IAM Roles Anywhere",
    "serviceId": "RolesAnywhere",
    "signatureVersion": "v4",
    "signingName": "rolesanywhere",
    "uid": "rolesanywhere-2018-05-10"
  },
  "operations": {
    "CreateProfile": {
      "http": {
        "requestUri": "/profiles",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "roleArns"
        ],
        "members": {
          "durationSeconds": {
            "type": "integer"
          },
          "enabled": {
            "type": "boolean"
          },
          "managedPolicyArns": {
            "shape": "S4"
          },
          "name": {},
          "requireInstanceProperties": {
            "type": "boolean"
          },
          "roleArns": {
            "shape": "S7"
          },
          "sessionPolicy": {},
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "shape": "Se"
      }
    },
    "CreateTrustAnchor": {
      "http": {
        "requestUri": "/trustanchors",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "source"
        ],
        "members": {
          "enabled": {
            "type": "boolean"
          },
          "name": {},
          "notificationSettings": {
            "shape": "Sl"
          },
          "source": {
            "shape": "Sq"
          },
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "shape": "Su"
      }
    },
    "DeleteCrl": {
      "http": {
        "method": "DELETE",
        "requestUri": "/crl/{crlId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S10"
      },
      "output": {
        "shape": "S11"
      },
      "idempotent": true
    },
    "DeleteProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/profile/{profileId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S14"
      },
      "output": {
        "shape": "Se"
      },
      "idempotent": true
    },
    "DeleteTrustAnchor": {
      "http": {
        "method": "DELETE",
        "requestUri": "/trustanchor/{trustAnchorId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S15"
      },
      "output": {
        "shape": "Su"
      },
      "idempotent": true
    },
    "DisableCrl": {
      "http": {
        "requestUri": "/crl/{crlId}/disable",
        "responseCode": 200
      },
      "input": {
        "shape": "S10"
      },
      "output": {
        "shape": "S11"
      }
    },
    "DisableProfile": {
      "http": {
        "requestUri": "/profile/{profileId}/disable",
        "responseCode": 200
      },
      "input": {
        "shape": "S14"
      },
      "output": {
        "shape": "Se"
      }
    },
    "DisableTrustAnchor": {
      "http": {
        "requestUri": "/trustanchor/{trustAnchorId}/disable",
        "responseCode": 200
      },
      "input": {
        "shape": "S15"
      },
      "output": {
        "shape": "Su"
      }
    },
    "EnableCrl": {
      "http": {
        "requestUri": "/crl/{crlId}/enable",
        "responseCode": 200
      },
      "input": {
        "shape": "S10"
      },
      "output": {
        "shape": "S11"
      }
    },
    "EnableProfile": {
      "http": {
        "requestUri": "/profile/{profileId}/enable",
        "responseCode": 200
      },
      "input": {
        "shape": "S14"
      },
      "output": {
        "shape": "Se"
      }
    },
    "EnableTrustAnchor": {
      "http": {
        "requestUri": "/trustanchor/{trustAnchorId}/enable",
        "responseCode": 200
      },
      "input": {
        "shape": "S15"
      },
      "output": {
        "shape": "Su"
      }
    },
    "GetCrl": {
      "http": {
        "method": "GET",
        "requestUri": "/crl/{crlId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S10"
      },
      "output": {
        "shape": "S11"
      }
    },
    "GetProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/profile/{profileId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S14"
      },
      "output": {
        "shape": "Se"
      }
    },
    "GetSubject": {
      "http": {
        "method": "GET",
        "requestUri": "/subject/{subjectId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "subjectId"
        ],
        "members": {
          "subjectId": {
            "location": "uri",
            "locationName": "subjectId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "subject": {
            "type": "structure",
            "members": {
              "createdAt": {
                "shape": "Sg"
              },
              "credentials": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "enabled": {
                      "type": "boolean"
                    },
                    "failed": {
                      "type": "boolean"
                    },
                    "issuer": {},
                    "seenAt": {
                      "shape": "Sg"
                    },
                    "serialNumber": {},
                    "x509CertificateData": {}
                  }
                }
              },
              "enabled": {
                "type": "boolean"
              },
              "instanceProperties": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "failed": {
                      "type": "boolean"
                    },
                    "properties": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    },
                    "seenAt": {
                      "shape": "Sg"
                    }
                  }
                }
              },
              "lastSeenAt": {
                "shape": "Sg"
              },
              "subjectArn": {},
              "subjectId": {},
              "updatedAt": {
                "shape": "Sg"
              },
              "x509Subject": {}
            }
          }
        }
      }
    },
    "GetTrustAnchor": {
      "http": {
        "method": "GET",
        "requestUri": "/trustanchor/{trustAnchorId}",
        "responseCode": 200
      },
      "input": {
        "shape": "S15"
      },
      "output": {
        "shape": "Su"
      }
    },
    "ImportCrl": {
      "http": {
        "requestUri": "/crls",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "crlData",
          "name",
          "trustAnchorArn"
        ],
        "members": {
          "crlData": {
            "type": "blob"
          },
          "enabled": {
            "type": "boolean"
          },
          "name": {},
          "tags": {
            "shape": "Sa"
          },
          "trustAnchorArn": {}
        }
      },
      "output": {
        "shape": "S11"
      }
    },
    "ListCrls": {
      "http": {
        "method": "GET",
        "requestUri": "/crls",
        "responseCode": 200
      },
      "input": {
        "shape": "S1j"
      },
      "output": {
        "type": "structure",
        "members": {
          "crls": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/profiles",
        "responseCode": 200
      },
      "input": {
        "shape": "S1j"
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "profiles": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          }
        }
      }
    },
    "ListSubjects": {
      "http": {
        "method": "GET",
        "requestUri": "/subjects",
        "responseCode": 200
      },
      "input": {
        "shape": "S1j"
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "subjects": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "shape": "Sg"
                },
                "enabled": {
                  "type": "boolean"
                },
                "lastSeenAt": {
                  "shape": "Sg"
                },
                "subjectArn": {},
                "subjectId": {},
                "updatedAt": {
                  "shape": "Sg"
                },
                "x509Subject": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/ListTagsForResource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "ListTrustAnchors": {
      "http": {
        "method": "GET",
        "requestUri": "/trustanchors",
        "responseCode": 200
      },
      "input": {
        "shape": "S1j"
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "trustAnchors": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          }
        }
      }
    },
    "PutNotificationSettings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/put-notifications-settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "notificationSettings",
          "trustAnchorId"
        ],
        "members": {
          "notificationSettings": {
            "shape": "Sl"
          },
          "trustAnchorId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trustAnchor"
        ],
        "members": {
          "trustAnchor": {
            "shape": "Sv"
          }
        }
      }
    },
    "ResetNotificationSettings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/reset-notifications-settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "notificationSettingKeys",
          "trustAnchorId"
        ],
        "members": {
          "notificationSettingKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "event"
              ],
              "members": {
                "channel": {},
                "event": {}
              }
            }
          },
          "trustAnchorId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trustAnchor"
        ],
        "members": {
          "trustAnchor": {
            "shape": "Sv"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/TagResource",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/UntagResource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {
              "shape": "Sc"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCrl": {
      "http": {
        "method": "PATCH",
        "requestUri": "/crl/{crlId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "crlId"
        ],
        "members": {
          "crlData": {
            "type": "blob"
          },
          "crlId": {
            "location": "uri",
            "locationName": "crlId"
          },
          "name": {}
        }
      },
      "output": {
        "shape": "S11"
      }
    },
    "UpdateProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/profile/{profileId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "profileId"
        ],
        "members": {
          "durationSeconds": {
            "type": "integer"
          },
          "managedPolicyArns": {
            "shape": "S4"
          },
          "name": {},
          "profileId": {
            "location": "uri",
            "locationName": "profileId"
          },
          "roleArns": {
            "shape": "S7"
          },
          "sessionPolicy": {}
        }
      },
      "output": {
        "shape": "Se"
      },
      "idempotent": true
    },
    "UpdateTrustAnchor": {
      "http": {
        "method": "PATCH",
        "requestUri": "/trustanchor/{trustAnchorId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trustAnchorId"
        ],
        "members": {
          "name": {},
          "source": {
            "shape": "Sq"
          },
          "trustAnchorId": {
            "location": "uri",
            "locationName": "trustAnchorId"
          }
        }
      },
      "output": {
        "shape": "Su"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {
            "shape": "Sc"
          },
          "value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Sc": {
      "type": "string",
      "sensitive": true
    },
    "Se": {
      "type": "structure",
      "members": {
        "profile": {
          "shape": "Sf"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "createdAt": {
          "shape": "Sg"
        },
        "createdBy": {},
        "durationSeconds": {
          "type": "integer"
        },
        "enabled": {
          "type": "boolean"
        },
        "managedPolicyArns": {
          "shape": "S4"
        },
        "name": {},
        "profileArn": {},
        "profileId": {},
        "requireInstanceProperties": {
          "type": "boolean"
        },
        "roleArns": {
          "shape": "S7"
        },
        "sessionPolicy": {},
        "updatedAt": {
          "shape": "Sg"
        }
      }
    },
    "Sg": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "enabled",
          "event"
        ],
        "members": {
          "channel": {},
          "enabled": {
            "type": "boolean"
          },
          "event": {},
          "threshold": {
            "type": "integer"
          }
        }
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "sourceData": {
          "type": "structure",
          "members": {
            "acmPcaArn": {},
            "x509CertificateData": {}
          },
          "union": true
        },
        "sourceType": {}
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "trustAnchor"
      ],
      "members": {
        "trustAnchor": {
          "shape": "Sv"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "createdAt": {
          "shape": "Sg"
        },
        "enabled": {
          "type": "boolean"
        },
        "name": {},
        "notificationSettings": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "enabled",
              "event"
            ],
            "members": {
              "channel": {},
              "configuredBy": {},
              "enabled": {
                "type": "boolean"
              },
              "event": {},
              "threshold": {
                "type": "integer"
              }
            }
          }
        },
        "source": {
          "shape": "Sq"
        },
        "trustAnchorArn": {},
        "trustAnchorId": {},
        "updatedAt": {
          "shape": "Sg"
        }
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "crlId"
      ],
      "members": {
        "crlId": {
          "location": "uri",
          "locationName": "crlId"
        }
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "crl"
      ],
      "members": {
        "crl": {
          "shape": "S12"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "createdAt": {
          "shape": "Sg"
        },
        "crlArn": {},
        "crlData": {
          "type": "blob"
        },
        "crlId": {},
        "enabled": {
          "type": "boolean"
        },
        "name": {},
        "trustAnchorArn": {},
        "updatedAt": {
          "shape": "Sg"
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "profileId"
      ],
      "members": {
        "profileId": {
          "location": "uri",
          "locationName": "profileId"
        }
      }
    },
    "S15": {
      "type": "structure",
      "required": [
        "trustAnchorId"
      ],
      "members": {
        "trustAnchorId": {
          "location": "uri",
          "locationName": "trustAnchorId"
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "nextToken": {
          "location": "querystring",
          "locationName": "nextToken"
        },
        "pageSize": {
          "location": "querystring",
          "locationName": "pageSize",
          "type": "integer"
        }
      }
    }
  }
}