
import AWS from 'aws-sdk';
import { log } from './log-service';

export const secretRetreivalError = 'Could not retrieve secrets from SecretManager';

export const getSecret = async (secretName) => {
    const client = new AWS.SecretsManager();
    const secretResult = await client.getSecretValue({ SecretId: secretName }).promise();
    if (!secretResult || !secretResult.SecretString) {
        log.error(secretRetreivalError);
        throw new Error(secretRetreivalError);
    }
    return JSON.parse(secretResult.SecretString);
};
