{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-09",
    "endpointPrefix": "a4b",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Alexa For Business",
    "serviceId": "Alexa For Business",
    "signatureVersion": "v4",
    "targetPrefix": "AlexaForBusiness",
    "uid": "alexaforbusiness-2017-11-09"
  },
  "operations": {
    "ApproveSkill": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateContactWithAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn",
          "AddressBookArn"
        ],
        "members": {
          "ContactArn": {},
          "AddressBookArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateDeviceWithNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceArn",
          "NetworkProfileArn"
        ],
        "members": {
          "DeviceArn": {},
          "NetworkProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateDeviceWithRoom": {
      "input": {
        "type": "structure",
        "members": {
          "DeviceArn": {},
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateSkillGroupWithRoom": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {},
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateSkillWithSkillGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillGroupArn": {},
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "AssociateSkillWithUsers": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressBookArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateBusinessReportSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "Format",
          "ContentRange"
        ],
        "members": {
          "ScheduleName": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "Format": {},
          "ContentRange": {
            "shape": "Sv"
          },
          "Recurrence": {
            "shape": "Sx"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduleArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateConferenceProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ConferenceProviderName",
          "ConferenceProviderType",
          "MeetingSetting"
        ],
        "members": {
          "ConferenceProviderName": {},
          "ConferenceProviderType": {},
          "IPDialIn": {
            "shape": "S13"
          },
          "PSTNDialIn": {
            "shape": "S16"
          },
          "MeetingSetting": {
            "shape": "S1b"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConferenceProviderArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateContact": {
      "input": {
        "type": "structure",
        "required": [
          "FirstName"
        ],
        "members": {
          "DisplayName": {},
          "FirstName": {},
          "LastName": {},
          "PhoneNumber": {
            "shape": "S1g"
          },
          "PhoneNumbers": {
            "shape": "S1h"
          },
          "SipAddresses": {
            "shape": "S1k"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateGatewayGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ClientRequestToken"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayGroupArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkProfileName",
          "Ssid",
          "SecurityType",
          "ClientRequestToken"
        ],
        "members": {
          "NetworkProfileName": {},
          "Description": {},
          "Ssid": {},
          "SecurityType": {},
          "EapMethod": {},
          "CurrentPassword": {
            "shape": "S1z"
          },
          "NextPassword": {
            "shape": "S20"
          },
          "CertificateAuthorityArn": {},
          "TrustAnchors": {
            "shape": "S21"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkProfileArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateProfile": {
      "input": {
        "type": "structure",
        "required": [
          "ProfileName",
          "Timezone",
          "Address",
          "DistanceUnit",
          "TemperatureUnit",
          "WakeWord"
        ],
        "members": {
          "ProfileName": {},
          "Timezone": {},
          "Address": {},
          "DistanceUnit": {},
          "TemperatureUnit": {},
          "WakeWord": {},
          "Locale": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SetupModeDisabled": {
            "type": "boolean"
          },
          "MaxVolumeLimit": {
            "type": "integer"
          },
          "PSTNEnabled": {
            "type": "boolean"
          },
          "DataRetentionOptIn": {
            "type": "boolean"
          },
          "MeetingRoomConfiguration": {
            "type": "structure",
            "members": {
              "RoomUtilizationMetricsEnabled": {
                "type": "boolean"
              },
              "EndOfMeetingReminder": {
                "type": "structure",
                "required": [
                  "ReminderAtMinutes",
                  "ReminderType",
                  "Enabled"
                ],
                "members": {
                  "ReminderAtMinutes": {
                    "shape": "S2g"
                  },
                  "ReminderType": {},
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "InstantBooking": {
                "type": "structure",
                "required": [
                  "DurationInMinutes",
                  "Enabled"
                ],
                "members": {
                  "DurationInMinutes": {
                    "type": "integer"
                  },
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "RequireCheckIn": {
                "type": "structure",
                "required": [
                  "ReleaseAfterMinutes",
                  "Enabled"
                ],
                "members": {
                  "ReleaseAfterMinutes": {
                    "type": "integer"
                  },
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "ProactiveJoin": {
                "type": "structure",
                "required": [
                  "EnabledByMotion"
                ],
                "members": {
                  "EnabledByMotion": {
                    "type": "boolean"
                  }
                }
              }
            }
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProfileArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateRoom": {
      "input": {
        "type": "structure",
        "required": [
          "RoomName"
        ],
        "members": {
          "RoomName": {},
          "Description": {},
          "ProfileArn": {},
          "ProviderCalendarId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoomArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateSkillGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SkillGroupName"
        ],
        "members": {
          "SkillGroupName": {},
          "Description": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserId"
        ],
        "members": {
          "UserId": {},
          "FirstName": {},
          "LastName": {},
          "Email": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "AddressBookArn"
        ],
        "members": {
          "AddressBookArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteBusinessReportSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduleArn"
        ],
        "members": {
          "ScheduleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteConferenceProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ConferenceProviderArn"
        ],
        "members": {
          "ConferenceProviderArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn"
        ],
        "members": {
          "ContactArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceArn"
        ],
        "members": {
          "DeviceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteDeviceUsageData": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceArn",
          "DeviceUsageType"
        ],
        "members": {
          "DeviceArn": {},
          "DeviceUsageType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteGatewayGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayGroupArn"
        ],
        "members": {
          "GatewayGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkProfileArn"
        ],
        "members": {
          "NetworkProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteProfile": {
      "input": {
        "type": "structure",
        "members": {
          "ProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteRoom": {
      "input": {
        "type": "structure",
        "members": {
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteRoomSkillParameter": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId",
          "ParameterKey"
        ],
        "members": {
          "RoomArn": {},
          "SkillId": {},
          "ParameterKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteSkillAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillId": {},
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteSkillGroup": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "EnrollmentId"
        ],
        "members": {
          "UserArn": {},
          "EnrollmentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DisassociateContactFromAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn",
          "AddressBookArn"
        ],
        "members": {
          "ContactArn": {},
          "AddressBookArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DisassociateDeviceFromRoom": {
      "input": {
        "type": "structure",
        "members": {
          "DeviceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DisassociateSkillFromSkillGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillGroupArn": {},
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DisassociateSkillFromUsers": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "DisassociateSkillGroupFromRoom": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {},
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ForgetSmartHomeAppliances": {
      "input": {
        "type": "structure",
        "required": [
          "RoomArn"
        ],
        "members": {
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "AddressBookArn"
        ],
        "members": {
          "AddressBookArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressBook": {
            "type": "structure",
            "members": {
              "AddressBookArn": {},
              "Name": {},
              "Description": {}
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetConferencePreference": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Preference": {
            "shape": "S4e"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetConferenceProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ConferenceProviderArn"
        ],
        "members": {
          "ConferenceProviderArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConferenceProvider": {
            "shape": "S4h"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn"
        ],
        "members": {
          "ContactArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Contact": {
            "type": "structure",
            "members": {
              "ContactArn": {},
              "DisplayName": {},
              "FirstName": {},
              "LastName": {},
              "PhoneNumber": {
                "shape": "S1g"
              },
              "PhoneNumbers": {
                "shape": "S1h"
              },
              "SipAddresses": {
                "shape": "S1k"
              }
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "members": {
          "DeviceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Device": {
            "type": "structure",
            "members": {
              "DeviceArn": {},
              "DeviceSerialNumber": {},
              "DeviceType": {},
              "DeviceName": {},
              "SoftwareVersion": {},
              "MacAddress": {},
              "RoomArn": {},
              "DeviceStatus": {},
              "DeviceStatusInfo": {
                "shape": "S4u"
              },
              "NetworkProfileInfo": {
                "type": "structure",
                "members": {
                  "NetworkProfileArn": {},
                  "CertificateArn": {},
                  "CertificateExpirationTime": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayArn"
        ],
        "members": {
          "GatewayArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Gateway": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "Description": {},
              "GatewayGroupArn": {},
              "SoftwareVersion": {}
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetGatewayGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayGroupArn"
        ],
        "members": {
          "GatewayGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayGroup": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "Description": {}
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetInvitationConfiguration": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationName": {},
          "ContactEmail": {},
          "PrivateSkillIds": {
            "shape": "S5f"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkProfileArn"
        ],
        "members": {
          "NetworkProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkProfile": {
            "type": "structure",
            "members": {
              "NetworkProfileArn": {},
              "NetworkProfileName": {},
              "Description": {},
              "Ssid": {},
              "SecurityType": {},
              "EapMethod": {},
              "CurrentPassword": {
                "shape": "S1z"
              },
              "NextPassword": {
                "shape": "S20"
              },
              "CertificateAuthorityArn": {},
              "TrustAnchors": {
                "shape": "S21"
              }
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetProfile": {
      "input": {
        "type": "structure",
        "members": {
          "ProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Profile": {
            "type": "structure",
            "members": {
              "ProfileArn": {},
              "ProfileName": {},
              "IsDefault": {
                "type": "boolean"
              },
              "Address": {},
              "Timezone": {},
              "DistanceUnit": {},
              "TemperatureUnit": {},
              "WakeWord": {},
              "Locale": {},
              "SetupModeDisabled": {
                "type": "boolean"
              },
              "MaxVolumeLimit": {
                "type": "integer"
              },
              "PSTNEnabled": {
                "type": "boolean"
              },
              "DataRetentionOptIn": {
                "type": "boolean"
              },
              "AddressBookArn": {},
              "MeetingRoomConfiguration": {
                "type": "structure",
                "members": {
                  "RoomUtilizationMetricsEnabled": {
                    "type": "boolean"
                  },
                  "EndOfMeetingReminder": {
                    "type": "structure",
                    "members": {
                      "ReminderAtMinutes": {
                        "shape": "S2g"
                      },
                      "ReminderType": {},
                      "Enabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "InstantBooking": {
                    "type": "structure",
                    "members": {
                      "DurationInMinutes": {
                        "type": "integer"
                      },
                      "Enabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "RequireCheckIn": {
                    "type": "structure",
                    "members": {
                      "ReleaseAfterMinutes": {
                        "type": "integer"
                      },
                      "Enabled": {
                        "type": "boolean"
                      }
                    }
                  },
                  "ProactiveJoin": {
                    "type": "structure",
                    "members": {
                      "EnabledByMotion": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetRoom": {
      "input": {
        "type": "structure",
        "members": {
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Room": {
            "type": "structure",
            "members": {
              "RoomArn": {},
              "RoomName": {},
              "Description": {},
              "ProviderCalendarId": {},
              "ProfileArn": {}
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetRoomSkillParameter": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId",
          "ParameterKey"
        ],
        "members": {
          "RoomArn": {},
          "SkillId": {},
          "ParameterKey": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoomSkillParameter": {
            "shape": "S5w"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "GetSkillGroup": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SkillGroup": {
            "type": "structure",
            "members": {
              "SkillGroupArn": {},
              "SkillGroupName": {},
              "Description": {}
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListBusinessReportSchedules": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BusinessReportSchedules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ScheduleArn": {},
                "ScheduleName": {},
                "S3BucketName": {},
                "S3KeyPrefix": {},
                "Format": {},
                "ContentRange": {
                  "shape": "Sv"
                },
                "Recurrence": {
                  "shape": "Sx"
                },
                "LastBusinessReport": {
                  "type": "structure",
                  "members": {
                    "Status": {},
                    "FailureCode": {},
                    "S3Location": {
                      "type": "structure",
                      "members": {
                        "Path": {},
                        "BucketName": {}
                      }
                    },
                    "DeliveryTime": {
                      "type": "timestamp"
                    },
                    "DownloadUrl": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListConferenceProviders": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConferenceProviders": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListDeviceEvents": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceArn"
        ],
        "members": {
          "DeviceArn": {},
          "EventType": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeviceEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Value": {},
                "Timestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListGatewayGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListGateways": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayGroupArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Gateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {},
                "Description": {},
                "GatewayGroupArn": {},
                "SoftwareVersion": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListSkills": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {},
          "EnablementType": {},
          "SkillType": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SkillSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SkillId": {},
                "SkillName": {},
                "SupportsLinking": {
                  "type": "boolean"
                },
                "EnablementType": {},
                "SkillType": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListSkillsStoreCategories": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CategoryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CategoryId": {
                  "type": "long"
                },
                "CategoryName": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListSkillsStoreSkillsByCategory": {
      "input": {
        "type": "structure",
        "required": [
          "CategoryId"
        ],
        "members": {
          "CategoryId": {
            "type": "long"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SkillsStoreSkills": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SkillId": {},
                "SkillName": {},
                "ShortDescription": {},
                "IconUrl": {},
                "SampleUtterances": {
                  "type": "list",
                  "member": {}
                },
                "SkillDetails": {
                  "type": "structure",
                  "members": {
                    "ProductDescription": {},
                    "InvocationPhrase": {},
                    "ReleaseDate": {},
                    "EndUserLicenseAgreement": {},
                    "GenericKeywords": {
                      "type": "list",
                      "member": {}
                    },
                    "BulletPoints": {
                      "type": "list",
                      "member": {}
                    },
                    "NewInThisVersionBulletPoints": {
                      "type": "list",
                      "member": {}
                    },
                    "SkillTypes": {
                      "type": "list",
                      "member": {}
                    },
                    "Reviews": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    },
                    "DeveloperInfo": {
                      "type": "structure",
                      "members": {
                        "DeveloperName": {},
                        "PrivacyPolicy": {},
                        "Email": {},
                        "Url": {}
                      }
                    }
                  }
                },
                "SupportsLinking": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListSmartHomeAppliances": {
      "input": {
        "type": "structure",
        "required": [
          "RoomArn"
        ],
        "members": {
          "RoomArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SmartHomeAppliances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FriendlyName": {},
                "Description": {},
                "ManufacturerName": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sl"
          },
          "NextToken": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "PutConferencePreference": {
      "input": {
        "type": "structure",
        "required": [
          "ConferencePreference"
        ],
        "members": {
          "ConferencePreference": {
            "shape": "S4e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "PutInvitationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationName"
        ],
        "members": {
          "OrganizationName": {},
          "ContactEmail": {},
          "PrivateSkillIds": {
            "shape": "S5f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "PutRoomSkillParameter": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId",
          "RoomSkillParameter"
        ],
        "members": {
          "RoomArn": {},
          "SkillId": {},
          "RoomSkillParameter": {
            "shape": "S5w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "PutSkillAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "AuthorizationResult",
          "SkillId"
        ],
        "members": {
          "AuthorizationResult": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "SkillId": {},
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "RegisterAVSDevice": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "UserCode",
          "ProductId",
          "AmazonId"
        ],
        "members": {
          "ClientId": {},
          "UserCode": {},
          "ProductId": {},
          "DeviceSerialNumber": {},
          "AmazonId": {},
          "RoomArn": {},
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeviceArn": {}
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "RejectSkill": {
      "input": {
        "type": "structure",
        "required": [
          "SkillId"
        ],
        "members": {
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "ResolveRoom": {
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "SkillId"
        ],
        "members": {
          "UserId": {},
          "SkillId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoomArn": {},
          "RoomName": {},
          "RoomSkillParameters": {
            "type": "list",
            "member": {
              "shape": "S5w"
            }
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "RevokeInvitation": {
      "input": {
        "type": "structure",
        "members": {
          "UserArn": {},
          "EnrollmentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchAddressBooks": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressBooks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AddressBookArn": {},
                "Name": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchContacts": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Contacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ContactArn": {},
                "DisplayName": {},
                "FirstName": {},
                "LastName": {},
                "PhoneNumber": {
                  "shape": "S1g"
                },
                "PhoneNumbers": {
                  "shape": "S1h"
                },
                "SipAddresses": {
                  "shape": "S1k"
                }
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchDevices": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DeviceArn": {},
                "DeviceSerialNumber": {},
                "DeviceType": {},
                "DeviceName": {},
                "SoftwareVersion": {},
                "MacAddress": {},
                "DeviceStatus": {},
                "NetworkProfileArn": {},
                "NetworkProfileName": {},
                "RoomArn": {},
                "RoomName": {},
                "DeviceStatusInfo": {
                  "shape": "S4u"
                },
                "CreatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchNetworkProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkProfiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NetworkProfileArn": {},
                "NetworkProfileName": {},
                "Description": {},
                "Ssid": {},
                "SecurityType": {},
                "EapMethod": {},
                "CertificateAuthorityArn": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Profiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProfileArn": {},
                "ProfileName": {},
                "IsDefault": {
                  "type": "boolean"
                },
                "Address": {},
                "Timezone": {},
                "DistanceUnit": {},
                "TemperatureUnit": {},
                "WakeWord": {},
                "Locale": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchRooms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rooms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RoomArn": {},
                "RoomName": {},
                "Description": {},
                "ProviderCalendarId": {},
                "ProfileArn": {},
                "ProfileName": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchSkillGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SkillGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SkillGroupArn": {},
                "SkillGroupName": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SearchUsers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S94"
          },
          "SortCriteria": {
            "shape": "S99"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UserArn": {},
                "FirstName": {},
                "LastName": {},
                "Email": {},
                "EnrollmentStatus": {},
                "EnrollmentId": {}
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "integer"
          }
        }
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "SendAnnouncement": {
      "input": {
        "type": "structure",
        "required": [
          "RoomFilters",
          "Content",
          "ClientRequestToken"
        ],
        "members": {
          "RoomFilters": {
            "shape": "S94"
          },
          "Content": {
            "type": "structure",
            "members": {
              "TextList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Locale",
                    "Value"
                  ],
                  "members": {
                    "Locale": {},
                    "Value": {}
                  }
                }
              },
              "SsmlList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Locale",
                    "Value"
                  ],
                  "members": {
                    "Locale": {},
                    "Value": {}
                  }
                }
              },
              "AudioList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Locale",
                    "Location"
                  ],
                  "members": {
                    "Locale": {},
                    "Location": {}
                  }
                }
              }
            }
          },
          "TimeToLiveInSeconds": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnnouncementArn": {}
        }
      }
    },
    "SendInvitation": {
      "input": {
        "type": "structure",
        "members": {
          "UserArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "StartDeviceSync": {
      "input": {
        "type": "structure",
        "required": [
          "Features"
        ],
        "members": {
          "RoomArn": {},
          "DeviceArn": {},
          "Features": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "StartSmartHomeApplianceDiscovery": {
      "input": {
        "type": "structure",
        "required": [
          "RoomArn"
        ],
        "members": {
          "RoomArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Tags"
        ],
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "TagKeys"
        ],
        "members": {
          "Arn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateAddressBook": {
      "input": {
        "type": "structure",
        "required": [
          "AddressBookArn"
        ],
        "members": {
          "AddressBookArn": {},
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateBusinessReportSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduleArn"
        ],
        "members": {
          "ScheduleArn": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "Format": {},
          "ScheduleName": {},
          "Recurrence": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateConferenceProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ConferenceProviderArn",
          "ConferenceProviderType",
          "MeetingSetting"
        ],
        "members": {
          "ConferenceProviderArn": {},
          "ConferenceProviderType": {},
          "IPDialIn": {
            "shape": "S13"
          },
          "PSTNDialIn": {
            "shape": "S16"
          },
          "MeetingSetting": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateContact": {
      "input": {
        "type": "structure",
        "required": [
          "ContactArn"
        ],
        "members": {
          "ContactArn": {},
          "DisplayName": {},
          "FirstName": {},
          "LastName": {},
          "PhoneNumber": {
            "shape": "S1g"
          },
          "PhoneNumbers": {
            "shape": "S1h"
          },
          "SipAddresses": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateDevice": {
      "input": {
        "type": "structure",
        "members": {
          "DeviceArn": {},
          "DeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayArn"
        ],
        "members": {
          "GatewayArn": {},
          "Name": {},
          "Description": {},
          "SoftwareVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateGatewayGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayGroupArn"
        ],
        "members": {
          "GatewayGroupArn": {},
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateNetworkProfile": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkProfileArn"
        ],
        "members": {
          "NetworkProfileArn": {},
          "NetworkProfileName": {},
          "Description": {},
          "CurrentPassword": {
            "shape": "S1z"
          },
          "NextPassword": {
            "shape": "S20"
          },
          "CertificateAuthorityArn": {},
          "TrustAnchors": {
            "shape": "S21"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateProfile": {
      "input": {
        "type": "structure",
        "members": {
          "ProfileArn": {},
          "ProfileName": {},
          "IsDefault": {
            "type": "boolean"
          },
          "Timezone": {},
          "Address": {},
          "DistanceUnit": {},
          "TemperatureUnit": {},
          "WakeWord": {},
          "Locale": {},
          "SetupModeDisabled": {
            "type": "boolean"
          },
          "MaxVolumeLimit": {
            "type": "integer"
          },
          "PSTNEnabled": {
            "type": "boolean"
          },
          "DataRetentionOptIn": {
            "type": "boolean"
          },
          "MeetingRoomConfiguration": {
            "type": "structure",
            "members": {
              "RoomUtilizationMetricsEnabled": {
                "type": "boolean"
              },
              "EndOfMeetingReminder": {
                "type": "structure",
                "members": {
                  "ReminderAtMinutes": {
                    "shape": "S2g"
                  },
                  "ReminderType": {},
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "InstantBooking": {
                "type": "structure",
                "members": {
                  "DurationInMinutes": {
                    "type": "integer"
                  },
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "RequireCheckIn": {
                "type": "structure",
                "members": {
                  "ReleaseAfterMinutes": {
                    "type": "integer"
                  },
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "ProactiveJoin": {
                "type": "structure",
                "required": [
                  "EnabledByMotion"
                ],
                "members": {
                  "EnabledByMotion": {
                    "type": "boolean"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateRoom": {
      "input": {
        "type": "structure",
        "members": {
          "RoomArn": {},
          "RoomName": {},
          "Description": {},
          "ProviderCalendarId": {},
          "ProfileArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    },
    "UpdateSkillGroup": {
      "input": {
        "type": "structure",
        "members": {
          "SkillGroupArn": {},
          "SkillGroupName": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "deprecated": true,
      "deprecatedMessage": "Alexa For Business is no longer supported"
    }
  },
  "shapes": {
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "Interval"
      ],
      "members": {
        "Interval": {}
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "StartDate": {}
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "Endpoint",
        "CommsProtocol"
      ],
      "members": {
        "Endpoint": {},
        "CommsProtocol": {}
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "CountryCode",
        "PhoneNumber",
        "OneClickIdDelay",
        "OneClickPinDelay"
      ],
      "members": {
        "CountryCode": {},
        "PhoneNumber": {},
        "OneClickIdDelay": {},
        "OneClickPinDelay": {}
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "RequirePin"
      ],
      "members": {
        "RequirePin": {}
      }
    },
    "S1g": {
      "type": "string",
      "sensitive": true
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Number",
          "Type"
        ],
        "members": {
          "Number": {
            "shape": "S1g"
          },
          "Type": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Uri",
          "Type"
        ],
        "members": {
          "Uri": {
            "type": "string",
            "sensitive": true
          },
          "Type": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S1z": {
      "type": "string",
      "sensitive": true
    },
    "S20": {
      "type": "string",
      "sensitive": true
    },
    "S21": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "S4e": {
      "type": "structure",
      "members": {
        "DefaultConferenceProviderArn": {}
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Name": {},
        "Type": {},
        "IPDialIn": {
          "shape": "S13"
        },
        "PSTNDialIn": {
          "shape": "S16"
        },
        "MeetingSetting": {
          "shape": "S1b"
        }
      }
    },
    "S4u": {
      "type": "structure",
      "members": {
        "DeviceStatusDetails": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Feature": {},
              "Code": {}
            }
          }
        },
        "ConnectionStatus": {},
        "ConnectionStatusUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S5f": {
      "type": "list",
      "member": {}
    },
    "S5w": {
      "type": "structure",
      "required": [
        "ParameterKey",
        "ParameterValue"
      ],
      "members": {
        "ParameterKey": {},
        "ParameterValue": {}
      }
    },
    "S94": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Values"
        ],
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S99": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  },
  "deprecated": true,
  "deprecatedMessage": "Alexa For Business is no longer supported"
}