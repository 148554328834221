{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-04-21",
    "endpointPrefix": "redshift-serverless",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Redshift Serverless",
    "serviceId": "Redshift Serverless",
    "signatureVersion": "v4",
    "signingName": "redshift-serverless",
    "targetPrefix": "RedshiftServerless",
    "uid": "redshift-serverless-2021-04-21"
  },
  "operations": {
    "ConvertRecoveryPointToSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "recoveryPointId",
          "snapshotName"
        ],
        "members": {
          "recoveryPointId": {},
          "retentionPeriod": {
            "type": "integer"
          },
          "snapshotName": {},
          "tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "snapshot": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateEndpointAccess": {
      "input": {
        "type": "structure",
        "required": [
          "endpointName",
          "subnetIds",
          "workgroupName"
        ],
        "members": {
          "endpointName": {},
          "subnetIds": {
            "shape": "Sh"
          },
          "vpcSecurityGroupIds": {
            "shape": "Sj"
          },
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {
            "shape": "Sm"
          }
        }
      },
      "idempotent": true
    },
    "CreateNamespace": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName"
        ],
        "members": {
          "adminUserPassword": {
            "shape": "St"
          },
          "adminUsername": {
            "shape": "Su"
          },
          "dbName": {},
          "defaultIamRoleArn": {},
          "iamRoles": {
            "shape": "Sv"
          },
          "kmsKeyId": {},
          "logExports": {
            "shape": "Sx"
          },
          "namespaceName": {},
          "tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "namespace": {
            "shape": "S11"
          }
        }
      },
      "idempotent": true
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName",
          "snapshotName"
        ],
        "members": {
          "namespaceName": {},
          "retentionPeriod": {
            "type": "integer"
          },
          "snapshotName": {},
          "tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "snapshot": {
            "shape": "S9"
          }
        }
      },
      "idempotent": true
    },
    "CreateUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "amount",
          "resourceArn",
          "usageType"
        ],
        "members": {
          "amount": {
            "type": "long"
          },
          "breachAction": {},
          "period": {},
          "resourceArn": {},
          "usageType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "usageLimit": {
            "shape": "S1a"
          }
        }
      },
      "idempotent": true
    },
    "CreateWorkgroup": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName",
          "workgroupName"
        ],
        "members": {
          "baseCapacity": {
            "type": "integer"
          },
          "configParameters": {
            "shape": "S1c"
          },
          "enhancedVpcRouting": {
            "type": "boolean"
          },
          "namespaceName": {},
          "port": {
            "type": "integer"
          },
          "publiclyAccessible": {
            "type": "boolean"
          },
          "securityGroupIds": {
            "shape": "S1h"
          },
          "subnetIds": {
            "shape": "Sh"
          },
          "tags": {
            "shape": "S4"
          },
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "workgroup": {
            "shape": "S1l"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEndpointAccess": {
      "input": {
        "type": "structure",
        "required": [
          "endpointName"
        ],
        "members": {
          "endpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {
            "shape": "Sm"
          }
        }
      },
      "idempotent": true
    },
    "DeleteNamespace": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName"
        ],
        "members": {
          "finalSnapshotName": {},
          "finalSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "namespaceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "namespace"
        ],
        "members": {
          "namespace": {
            "shape": "S11"
          }
        }
      },
      "idempotent": true
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "snapshotName"
        ],
        "members": {
          "snapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "snapshot": {
            "shape": "S9"
          }
        }
      },
      "idempotent": true
    },
    "DeleteUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "usageLimitId"
        ],
        "members": {
          "usageLimitId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "usageLimit": {
            "shape": "S1a"
          }
        }
      },
      "idempotent": true
    },
    "DeleteWorkgroup": {
      "input": {
        "type": "structure",
        "required": [
          "workgroupName"
        ],
        "members": {
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workgroup"
        ],
        "members": {
          "workgroup": {
            "shape": "S1l"
          }
        }
      },
      "idempotent": true
    },
    "GetCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "workgroupName"
        ],
        "members": {
          "dbName": {},
          "durationSeconds": {
            "type": "integer"
          },
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dbPassword": {
            "shape": "St"
          },
          "dbUser": {
            "shape": "Su"
          },
          "expiration": {
            "type": "timestamp"
          },
          "nextRefreshTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetEndpointAccess": {
      "input": {
        "type": "structure",
        "required": [
          "endpointName"
        ],
        "members": {
          "endpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {
            "shape": "Sm"
          }
        }
      }
    },
    "GetNamespace": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName"
        ],
        "members": {
          "namespaceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "namespace"
        ],
        "members": {
          "namespace": {
            "shape": "S11"
          }
        }
      }
    },
    "GetRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "recoveryPointId"
        ],
        "members": {
          "recoveryPointId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recoveryPoint": {
            "shape": "S2b"
          }
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourcePolicy": {
            "shape": "S2e"
          }
        }
      }
    },
    "GetSnapshot": {
      "input": {
        "type": "structure",
        "members": {
          "ownerAccount": {},
          "snapshotArn": {},
          "snapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "snapshot": {
            "shape": "S9"
          }
        }
      }
    },
    "GetTableRestoreStatus": {
      "input": {
        "type": "structure",
        "required": [
          "tableRestoreRequestId"
        ],
        "members": {
          "tableRestoreRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tableRestoreStatus": {
            "shape": "S2j"
          }
        }
      }
    },
    "GetUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "usageLimitId"
        ],
        "members": {
          "usageLimitId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "usageLimit": {
            "shape": "S1a"
          }
        }
      }
    },
    "GetWorkgroup": {
      "input": {
        "type": "structure",
        "required": [
          "workgroupName"
        ],
        "members": {
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workgroup"
        ],
        "members": {
          "workgroup": {
            "shape": "S1l"
          }
        }
      }
    },
    "ListEndpointAccess": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "vpcId": {},
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "endpoints"
        ],
        "members": {
          "endpoints": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListNamespaces": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "namespaces"
        ],
        "members": {
          "namespaces": {
            "type": "list",
            "member": {
              "shape": "S11"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRecoveryPoints": {
      "input": {
        "type": "structure",
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "maxResults": {
            "type": "integer"
          },
          "namespaceArn": {},
          "namespaceName": {},
          "nextToken": {},
          "startTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "recoveryPoints": {
            "type": "list",
            "member": {
              "shape": "S2b"
            }
          }
        }
      }
    },
    "ListSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "maxResults": {
            "type": "integer"
          },
          "namespaceArn": {},
          "namespaceName": {},
          "nextToken": {},
          "ownerAccount": {},
          "startTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "snapshots": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          }
        }
      }
    },
    "ListTableRestoreStatus": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "namespaceName": {},
          "nextToken": {},
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "tableRestoreStatuses": {
            "type": "list",
            "member": {
              "shape": "S2j"
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S4"
          }
        }
      }
    },
    "ListUsageLimits": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "resourceArn": {},
          "usageType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "usageLimits": {
            "type": "list",
            "member": {
              "shape": "S1a"
            }
          }
        }
      }
    },
    "ListWorkgroups": {
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workgroups"
        ],
        "members": {
          "nextToken": {},
          "workgroups": {
            "type": "list",
            "member": {
              "shape": "S1l"
            }
          }
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policy",
          "resourceArn"
        ],
        "members": {
          "policy": {},
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourcePolicy": {
            "shape": "S2e"
          }
        }
      }
    },
    "RestoreFromRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName",
          "recoveryPointId",
          "workgroupName"
        ],
        "members": {
          "namespaceName": {},
          "recoveryPointId": {},
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "namespace": {
            "shape": "S11"
          },
          "recoveryPointId": {}
        }
      }
    },
    "RestoreFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName",
          "workgroupName"
        ],
        "members": {
          "namespaceName": {},
          "ownerAccount": {},
          "snapshotArn": {},
          "snapshotName": {},
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "namespace": {
            "shape": "S11"
          },
          "ownerAccount": {},
          "snapshotName": {}
        }
      },
      "idempotent": true
    },
    "RestoreTableFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName",
          "newTableName",
          "snapshotName",
          "sourceDatabaseName",
          "sourceTableName",
          "workgroupName"
        ],
        "members": {
          "activateCaseSensitiveIdentifier": {
            "type": "boolean"
          },
          "namespaceName": {},
          "newTableName": {},
          "snapshotName": {},
          "sourceDatabaseName": {},
          "sourceSchemaName": {},
          "sourceTableName": {},
          "targetDatabaseName": {},
          "targetSchemaName": {},
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tableRestoreStatus": {
            "shape": "S2j"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEndpointAccess": {
      "input": {
        "type": "structure",
        "required": [
          "endpointName"
        ],
        "members": {
          "endpointName": {},
          "vpcSecurityGroupIds": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {
            "shape": "Sm"
          }
        }
      }
    },
    "UpdateNamespace": {
      "input": {
        "type": "structure",
        "required": [
          "namespaceName"
        ],
        "members": {
          "adminUserPassword": {
            "shape": "St"
          },
          "adminUsername": {
            "shape": "Su"
          },
          "defaultIamRoleArn": {},
          "iamRoles": {
            "shape": "Sv"
          },
          "kmsKeyId": {},
          "logExports": {
            "shape": "Sx"
          },
          "namespaceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "namespace"
        ],
        "members": {
          "namespace": {
            "shape": "S11"
          }
        }
      }
    },
    "UpdateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "snapshotName"
        ],
        "members": {
          "retentionPeriod": {
            "type": "integer"
          },
          "snapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "snapshot": {
            "shape": "S9"
          }
        }
      }
    },
    "UpdateUsageLimit": {
      "input": {
        "type": "structure",
        "required": [
          "usageLimitId"
        ],
        "members": {
          "amount": {
            "type": "long"
          },
          "breachAction": {},
          "usageLimitId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "usageLimit": {
            "shape": "S1a"
          }
        }
      }
    },
    "UpdateWorkgroup": {
      "input": {
        "type": "structure",
        "required": [
          "workgroupName"
        ],
        "members": {
          "baseCapacity": {
            "type": "integer"
          },
          "configParameters": {
            "shape": "S1c"
          },
          "enhancedVpcRouting": {
            "type": "boolean"
          },
          "port": {
            "type": "integer"
          },
          "publiclyAccessible": {
            "type": "boolean"
          },
          "securityGroupIds": {
            "shape": "S1h"
          },
          "subnetIds": {
            "shape": "Sh"
          },
          "workgroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workgroup"
        ],
        "members": {
          "workgroup": {
            "shape": "S1l"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "accountsWithProvisionedRestoreAccess": {
          "shape": "Sa"
        },
        "accountsWithRestoreAccess": {
          "shape": "Sa"
        },
        "actualIncrementalBackupSizeInMegaBytes": {
          "type": "double"
        },
        "adminUsername": {},
        "backupProgressInMegaBytes": {
          "type": "double"
        },
        "currentBackupRateInMegaBytesPerSecond": {
          "type": "double"
        },
        "elapsedTimeInSeconds": {
          "type": "long"
        },
        "estimatedSecondsToCompletion": {
          "type": "long"
        },
        "kmsKeyId": {},
        "namespaceArn": {},
        "namespaceName": {},
        "ownerAccount": {},
        "snapshotArn": {},
        "snapshotCreateTime": {
          "shape": "Se"
        },
        "snapshotName": {},
        "snapshotRemainingDays": {
          "type": "integer"
        },
        "snapshotRetentionPeriod": {
          "type": "integer"
        },
        "snapshotRetentionStartTime": {
          "shape": "Se"
        },
        "status": {},
        "totalBackupSizeInMegaBytes": {
          "type": "double"
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Sh": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "structure",
      "members": {
        "address": {},
        "endpointArn": {},
        "endpointCreateTime": {
          "shape": "Se"
        },
        "endpointName": {},
        "endpointStatus": {},
        "port": {
          "type": "integer"
        },
        "subnetIds": {
          "shape": "Sh"
        },
        "vpcEndpoint": {
          "shape": "Sn"
        },
        "vpcSecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "status": {},
              "vpcSecurityGroupId": {}
            }
          }
        },
        "workgroupName": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "networkInterfaces": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "availabilityZone": {},
              "networkInterfaceId": {},
              "privateIpAddress": {},
              "subnetId": {}
            }
          }
        },
        "vpcEndpointId": {},
        "vpcId": {}
      }
    },
    "St": {
      "type": "string",
      "sensitive": true
    },
    "Su": {
      "type": "string",
      "sensitive": true
    },
    "Sv": {
      "type": "list",
      "member": {}
    },
    "Sx": {
      "type": "list",
      "member": {}
    },
    "S11": {
      "type": "structure",
      "members": {
        "adminUsername": {
          "shape": "Su"
        },
        "creationDate": {
          "shape": "Se"
        },
        "dbName": {},
        "defaultIamRoleArn": {},
        "iamRoles": {
          "shape": "Sv"
        },
        "kmsKeyId": {},
        "logExports": {
          "shape": "Sx"
        },
        "namespaceArn": {},
        "namespaceId": {},
        "namespaceName": {},
        "status": {}
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "amount": {
          "type": "long"
        },
        "breachAction": {},
        "period": {},
        "resourceArn": {},
        "usageLimitArn": {},
        "usageLimitId": {},
        "usageType": {}
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "parameterKey": {},
          "parameterValue": {}
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1l": {
      "type": "structure",
      "members": {
        "baseCapacity": {
          "type": "integer"
        },
        "configParameters": {
          "shape": "S1c"
        },
        "creationDate": {
          "shape": "Se"
        },
        "endpoint": {
          "type": "structure",
          "members": {
            "address": {},
            "port": {
              "type": "integer"
            },
            "vpcEndpoints": {
              "type": "list",
              "member": {
                "shape": "Sn"
              }
            }
          }
        },
        "enhancedVpcRouting": {
          "type": "boolean"
        },
        "namespaceName": {},
        "port": {
          "type": "integer"
        },
        "publiclyAccessible": {
          "type": "boolean"
        },
        "securityGroupIds": {
          "shape": "S1h"
        },
        "status": {},
        "subnetIds": {
          "shape": "Sh"
        },
        "workgroupArn": {},
        "workgroupId": {},
        "workgroupName": {}
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "namespaceArn": {},
        "namespaceName": {},
        "recoveryPointCreateTime": {
          "shape": "Se"
        },
        "recoveryPointId": {},
        "totalSizeInMegaBytes": {
          "type": "double"
        },
        "workgroupName": {}
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "policy": {},
        "resourceArn": {}
      }
    },
    "S2j": {
      "type": "structure",
      "members": {
        "message": {},
        "namespaceName": {},
        "newTableName": {},
        "progressInMegaBytes": {
          "type": "long"
        },
        "requestTime": {
          "type": "timestamp"
        },
        "snapshotName": {},
        "sourceDatabaseName": {},
        "sourceSchemaName": {},
        "sourceTableName": {},
        "status": {},
        "tableRestoreRequestId": {},
        "targetDatabaseName": {},
        "targetSchemaName": {},
        "totalDataInMegaBytes": {
          "type": "long"
        },
        "workgroupName": {}
      }
    }
  }
}