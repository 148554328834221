const API_SCOPE = 'api://life-calculator/operation.all';

// eslint-disable-next-line import/prefer-default-export
export const buildParams = (secrets) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', secrets.key);
    params.append('grant_type', secrets.secret);
    params.append('scope', API_SCOPE);

    return params.toString();
};
