{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-09-17",
    "endpointPrefix": "catalog.marketplace",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWS Marketplace Catalog",
    "serviceFullName": "AWS Marketplace Catalog Service",
    "serviceId": "Marketplace Catalog",
    "signatureVersion": "v4",
    "signingName": "aws-marketplace",
    "uid": "marketplace-catalog-2018-09-17"
  },
  "operations": {
    "CancelChangeSet": {
      "http": {
        "method": "PATCH",
        "requestUri": "/CancelChangeSet"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog",
          "ChangeSetId"
        ],
        "members": {
          "Catalog": {
            "location": "querystring",
            "locationName": "catalog"
          },
          "ChangeSetId": {
            "location": "querystring",
            "locationName": "changeSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeSetId": {},
          "ChangeSetArn": {}
        }
      }
    },
    "DeleteResourcePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteResourcePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeChangeSet": {
      "http": {
        "method": "GET",
        "requestUri": "/DescribeChangeSet"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog",
          "ChangeSetId"
        ],
        "members": {
          "Catalog": {
            "location": "querystring",
            "locationName": "catalog"
          },
          "ChangeSetId": {
            "location": "querystring",
            "locationName": "changeSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeSetId": {},
          "ChangeSetArn": {},
          "ChangeSetName": {},
          "StartTime": {},
          "EndTime": {},
          "Status": {},
          "FailureCode": {},
          "FailureDescription": {},
          "ChangeSet": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ChangeType": {},
                "Entity": {
                  "shape": "Sj"
                },
                "Details": {},
                "ErrorDetailList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ErrorCode": {},
                      "ErrorMessage": {}
                    }
                  }
                },
                "ChangeName": {}
              }
            }
          }
        }
      }
    },
    "DescribeEntity": {
      "http": {
        "method": "GET",
        "requestUri": "/DescribeEntity"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog",
          "EntityId"
        ],
        "members": {
          "Catalog": {
            "location": "querystring",
            "locationName": "catalog"
          },
          "EntityId": {
            "location": "querystring",
            "locationName": "entityId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityType": {},
          "EntityIdentifier": {},
          "EntityArn": {},
          "LastModifiedDate": {},
          "Details": {}
        }
      }
    },
    "GetResourcePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/GetResourcePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "ListChangeSets": {
      "http": {
        "requestUri": "/ListChangeSets"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog"
        ],
        "members": {
          "Catalog": {},
          "FilterList": {
            "shape": "Sx"
          },
          "Sort": {
            "shape": "S12"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeSetSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ChangeSetId": {},
                "ChangeSetArn": {},
                "ChangeSetName": {},
                "StartTime": {},
                "EndTime": {},
                "Status": {},
                "EntityIdList": {
                  "type": "list",
                  "member": {}
                },
                "FailureCode": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntities": {
      "http": {
        "requestUri": "/ListEntities"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog",
          "EntityType"
        ],
        "members": {
          "Catalog": {},
          "EntityType": {},
          "FilterList": {
            "shape": "Sx"
          },
          "Sort": {
            "shape": "S12"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "OwnershipType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitySummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "EntityType": {},
                "EntityId": {},
                "EntityArn": {},
                "LastModifiedDate": {},
                "Visibility": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "requestUri": "/ListTagsForResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1l"
          }
        }
      }
    },
    "PutResourcePolicy": {
      "http": {
        "requestUri": "/PutResourcePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Policy"
        ],
        "members": {
          "ResourceArn": {},
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartChangeSet": {
      "http": {
        "requestUri": "/StartChangeSet"
      },
      "input": {
        "type": "structure",
        "required": [
          "Catalog",
          "ChangeSet"
        ],
        "members": {
          "Catalog": {},
          "ChangeSet": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ChangeType",
                "Entity",
                "Details"
              ],
              "members": {
                "ChangeType": {},
                "Entity": {
                  "shape": "Sj"
                },
                "EntityTags": {
                  "shape": "S1l"
                },
                "Details": {},
                "ChangeName": {}
              }
            }
          },
          "ChangeSetName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ChangeSetTags": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeSetId": {},
          "ChangeSetArn": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/TagResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/UntagResource"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sj": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {},
        "Identifier": {}
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "ValueList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "SortBy": {},
        "SortOrder": {}
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}