{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-04-23",
    "endpointPrefix": "api.mediatailor",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaTailor",
    "serviceFullName": "AWS MediaTailor",
    "serviceId": "MediaTailor",
    "signatureVersion": "v4",
    "signingName": "mediatailor",
    "uid": "mediatailor-2018-04-23"
  },
  "operations": {
    "ConfigureLogsForChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/configureLogs/channel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "LogTypes"
        ],
        "members": {
          "ChannelName": {},
          "LogTypes": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelName": {},
          "LogTypes": {
            "shape": "S3"
          }
        }
      }
    },
    "ConfigureLogsForPlaybackConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/configureLogs/playbackConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PercentEnabled",
          "PlaybackConfigurationName"
        ],
        "members": {
          "PercentEnabled": {
            "type": "integer"
          },
          "PlaybackConfigurationName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "PercentEnabled"
        ],
        "members": {
          "PercentEnabled": {
            "type": "integer"
          },
          "PlaybackConfigurationName": {}
        }
      },
      "idempotent": true
    },
    "CreateChannel": {
      "http": {
        "requestUri": "/channel/{ChannelName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "Outputs",
          "PlaybackMode"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "FillerSlate": {
            "shape": "Sa"
          },
          "Outputs": {
            "shape": "Sb"
          },
          "PlaybackMode": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "Tier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "FillerSlate": {
            "shape": "Sa"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "Outputs": {
            "shape": "Sn"
          },
          "PlaybackMode": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "Tier": {}
        }
      },
      "idempotent": true
    },
    "CreateLiveSource": {
      "http": {
        "requestUri": "/sourceLocation/{SourceLocationName}/liveSource/{LiveSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpPackageConfigurations",
          "LiveSourceName",
          "SourceLocationName"
        ],
        "members": {
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LiveSourceName": {
            "location": "uri",
            "locationName": "LiveSourceName"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "LiveSourceName": {},
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "idempotent": true
    },
    "CreatePrefetchSchedule": {
      "http": {
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Consumption",
          "Name",
          "PlaybackConfigurationName",
          "Retrieval"
        ],
        "members": {
          "Consumption": {
            "shape": "Sv"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          },
          "Retrieval": {
            "shape": "Sz"
          },
          "StreamId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Consumption": {
            "shape": "Sv"
          },
          "Name": {},
          "PlaybackConfigurationName": {},
          "Retrieval": {
            "shape": "Sz"
          },
          "StreamId": {}
        }
      },
      "idempotent": true
    },
    "CreateProgram": {
      "http": {
        "requestUri": "/channel/{ChannelName}/program/{ProgramName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "ProgramName",
          "ScheduleConfiguration",
          "SourceLocationName"
        ],
        "members": {
          "AdBreaks": {
            "shape": "S12"
          },
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "LiveSourceName": {},
          "ProgramName": {
            "location": "uri",
            "locationName": "ProgramName"
          },
          "ScheduleConfiguration": {
            "type": "structure",
            "required": [
              "Transition"
            ],
            "members": {
              "ClipRange": {
                "shape": "S1f"
              },
              "Transition": {
                "type": "structure",
                "required": [
                  "RelativePosition",
                  "Type"
                ],
                "members": {
                  "DurationMillis": {
                    "type": "long"
                  },
                  "RelativePosition": {},
                  "RelativeProgram": {},
                  "ScheduledStartTimeMillis": {
                    "type": "long"
                  },
                  "Type": {}
                }
              }
            }
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdBreaks": {
            "shape": "S12"
          },
          "Arn": {},
          "ChannelName": {},
          "ClipRange": {
            "shape": "S1f"
          },
          "CreationTime": {
            "shape": "Sm"
          },
          "DurationMillis": {
            "type": "long"
          },
          "LiveSourceName": {},
          "ProgramName": {},
          "ScheduledStartTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        }
      },
      "idempotent": true
    },
    "CreateSourceLocation": {
      "http": {
        "requestUri": "/sourceLocation/{SourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpConfiguration",
          "SourceLocationName"
        ],
        "members": {
          "AccessConfiguration": {
            "shape": "S1k"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S1n"
          },
          "HttpConfiguration": {
            "shape": "S1o"
          },
          "SegmentDeliveryConfigurations": {
            "shape": "S1p"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessConfiguration": {
            "shape": "S1k"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S1n"
          },
          "HttpConfiguration": {
            "shape": "S1o"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SegmentDeliveryConfigurations": {
            "shape": "S1p"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "idempotent": true
    },
    "CreateVodSource": {
      "http": {
        "requestUri": "/sourceLocation/{SourceLocationName}/vodSource/{VodSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpPackageConfigurations",
          "SourceLocationName",
          "VodSourceName"
        ],
        "members": {
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "VodSourceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "VodSourceName": {}
        }
      },
      "idempotent": true
    },
    "DeleteChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{ChannelName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteChannelPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{ChannelName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteLiveSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sourceLocation/{SourceLocationName}/liveSource/{LiveSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LiveSourceName",
          "SourceLocationName"
        ],
        "members": {
          "LiveSourceName": {
            "location": "uri",
            "locationName": "LiveSourceName"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePlaybackConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePrefetchSchedule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PlaybackConfigurationName"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteProgram": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channel/{ChannelName}/program/{ProgramName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "ProgramName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "ProgramName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteSourceLocation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sourceLocation/{SourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName"
        ],
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteVodSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sourceLocation/{SourceLocationName}/vodSource/{VodSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName",
          "VodSourceName"
        ],
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "VodSourceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{ChannelName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LogConfiguration"
        ],
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "FillerSlate": {
            "shape": "Sa"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "LogConfiguration": {
            "shape": "S2c"
          },
          "Outputs": {
            "shape": "Sn"
          },
          "PlaybackMode": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "Tier": {}
        }
      }
    },
    "DescribeLiveSource": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{SourceLocationName}/liveSource/{LiveSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LiveSourceName",
          "SourceLocationName"
        ],
        "members": {
          "LiveSourceName": {
            "location": "uri",
            "locationName": "LiveSourceName"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "LiveSourceName": {},
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      }
    },
    "DescribeProgram": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{ChannelName}/program/{ProgramName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "ProgramName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "ProgramName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdBreaks": {
            "shape": "S12"
          },
          "Arn": {},
          "ChannelName": {},
          "ClipRange": {
            "shape": "S1f"
          },
          "CreationTime": {
            "shape": "Sm"
          },
          "DurationMillis": {
            "type": "long"
          },
          "LiveSourceName": {},
          "ProgramName": {},
          "ScheduledStartTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        }
      }
    },
    "DescribeSourceLocation": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{SourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName"
        ],
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessConfiguration": {
            "shape": "S1k"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S1n"
          },
          "HttpConfiguration": {
            "shape": "S1o"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SegmentDeliveryConfigurations": {
            "shape": "S1p"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      }
    },
    "DescribeVodSource": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{SourceLocationName}/vodSource/{VodSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName",
          "VodSourceName"
        ],
        "members": {
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "VodSourceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "VodSourceName": {}
        }
      }
    },
    "GetChannelPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{ChannelName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "GetChannelSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/channel/{ChannelName}/schedule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "DurationMinutes": {
            "location": "querystring",
            "locationName": "durationMinutes"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "ChannelName",
                "ProgramName",
                "SourceLocationName"
              ],
              "members": {
                "ApproximateDurationSeconds": {
                  "type": "long"
                },
                "ApproximateStartTime": {
                  "shape": "Sm"
                },
                "Arn": {},
                "ChannelName": {},
                "LiveSourceName": {},
                "ProgramName": {},
                "ScheduleAdBreaks": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ApproximateDurationSeconds": {
                        "type": "long"
                      },
                      "ApproximateStartTime": {
                        "shape": "Sm"
                      },
                      "SourceLocationName": {},
                      "VodSourceName": {}
                    }
                  }
                },
                "ScheduleEntryType": {},
                "SourceLocationName": {},
                "VodSourceName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetPlaybackConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S2y"
          },
          "Bumper": {
            "shape": "S31"
          },
          "CdnConfiguration": {
            "shape": "S32"
          },
          "ConfigurationAliases": {
            "shape": "S33"
          },
          "DashConfiguration": {
            "shape": "S34"
          },
          "HlsConfiguration": {
            "shape": "S36"
          },
          "LivePreRollConfiguration": {
            "shape": "S37"
          },
          "LogConfiguration": {
            "shape": "S38"
          },
          "ManifestProcessingRules": {
            "shape": "S39"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        }
      }
    },
    "GetPrefetchSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PlaybackConfigurationName"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Consumption": {
            "shape": "Sv"
          },
          "Name": {},
          "PlaybackConfigurationName": {},
          "Retrieval": {
            "shape": "Sz"
          },
          "StreamId": {}
        }
      }
    },
    "ListAlerts": {
      "http": {
        "method": "GET",
        "requestUri": "/alerts",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ResourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AlertCode",
                "AlertMessage",
                "LastModifiedTime",
                "RelatedResourceArns",
                "ResourceArn"
              ],
              "members": {
                "AlertCode": {},
                "AlertMessage": {},
                "Category": {},
                "LastModifiedTime": {
                  "shape": "Sm"
                },
                "RelatedResourceArns": {
                  "shape": "S3k"
                },
                "ResourceArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "ChannelName",
                "ChannelState",
                "LogConfiguration",
                "Outputs",
                "PlaybackMode",
                "Tier"
              ],
              "members": {
                "Arn": {},
                "ChannelName": {},
                "ChannelState": {},
                "CreationTime": {
                  "shape": "Sm"
                },
                "FillerSlate": {
                  "shape": "Sa"
                },
                "LastModifiedTime": {
                  "shape": "Sm"
                },
                "LogConfiguration": {
                  "shape": "S2c"
                },
                "Outputs": {
                  "shape": "Sn"
                },
                "PlaybackMode": {},
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                },
                "Tier": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLiveSources": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{SourceLocationName}/liveSources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName"
        ],
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "HttpPackageConfigurations",
                "LiveSourceName",
                "SourceLocationName"
              ],
              "members": {
                "Arn": {},
                "CreationTime": {
                  "shape": "Sm"
                },
                "HttpPackageConfigurations": {
                  "shape": "Sq"
                },
                "LastModifiedTime": {
                  "shape": "Sm"
                },
                "LiveSourceName": {},
                "SourceLocationName": {},
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPlaybackConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfigurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AdDecisionServerUrl": {},
                "AvailSuppression": {
                  "shape": "S2y"
                },
                "Bumper": {
                  "shape": "S31"
                },
                "CdnConfiguration": {
                  "shape": "S32"
                },
                "ConfigurationAliases": {
                  "shape": "S33"
                },
                "DashConfiguration": {
                  "shape": "S34"
                },
                "HlsConfiguration": {
                  "shape": "S36"
                },
                "LivePreRollConfiguration": {
                  "shape": "S37"
                },
                "LogConfiguration": {
                  "shape": "S38"
                },
                "ManifestProcessingRules": {
                  "shape": "S39"
                },
                "Name": {},
                "PersonalizationThresholdSeconds": {
                  "type": "integer"
                },
                "PlaybackConfigurationArn": {},
                "PlaybackEndpointPrefix": {},
                "SessionInitializationEndpointPrefix": {},
                "SlateAdUrl": {},
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                },
                "TranscodeProfileName": {},
                "VideoContentSourceUrl": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPrefetchSchedules": {
      "http": {
        "requestUri": "/prefetchSchedule/{PlaybackConfigurationName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PlaybackConfigurationName"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PlaybackConfigurationName": {
            "location": "uri",
            "locationName": "PlaybackConfigurationName"
          },
          "StreamId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "Consumption",
                "Name",
                "PlaybackConfigurationName",
                "Retrieval"
              ],
              "members": {
                "Arn": {},
                "Consumption": {
                  "shape": "Sv"
                },
                "Name": {},
                "PlaybackConfigurationName": {},
                "Retrieval": {
                  "shape": "Sz"
                },
                "StreamId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSourceLocations": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "HttpConfiguration",
                "SourceLocationName"
              ],
              "members": {
                "AccessConfiguration": {
                  "shape": "S1k"
                },
                "Arn": {},
                "CreationTime": {
                  "shape": "Sm"
                },
                "DefaultSegmentDeliveryConfiguration": {
                  "shape": "S1n"
                },
                "HttpConfiguration": {
                  "shape": "S1o"
                },
                "LastModifiedTime": {
                  "shape": "Sm"
                },
                "SegmentDeliveryConfigurations": {
                  "shape": "S1p"
                },
                "SourceLocationName": {},
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      }
    },
    "ListVodSources": {
      "http": {
        "method": "GET",
        "requestUri": "/sourceLocation/{SourceLocationName}/vodSources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceLocationName"
        ],
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "HttpPackageConfigurations",
                "SourceLocationName",
                "VodSourceName"
              ],
              "members": {
                "Arn": {},
                "CreationTime": {
                  "shape": "Sm"
                },
                "HttpPackageConfigurations": {
                  "shape": "Sq"
                },
                "LastModifiedTime": {
                  "shape": "Sm"
                },
                "SourceLocationName": {},
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                },
                "VodSourceName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutChannelPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{ChannelName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "Policy"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "PutPlaybackConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/playbackConfiguration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S2y"
          },
          "Bumper": {
            "shape": "S31"
          },
          "CdnConfiguration": {
            "shape": "S32"
          },
          "ConfigurationAliases": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "Si"
            }
          },
          "DashConfiguration": {
            "type": "structure",
            "members": {
              "MpdLocation": {},
              "OriginManifestType": {}
            }
          },
          "LivePreRollConfiguration": {
            "shape": "S37"
          },
          "ManifestProcessingRules": {
            "shape": "S39"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "SlateAdUrl": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdDecisionServerUrl": {},
          "AvailSuppression": {
            "shape": "S2y"
          },
          "Bumper": {
            "shape": "S31"
          },
          "CdnConfiguration": {
            "shape": "S32"
          },
          "ConfigurationAliases": {
            "shape": "S33"
          },
          "DashConfiguration": {
            "shape": "S34"
          },
          "HlsConfiguration": {
            "shape": "S36"
          },
          "LivePreRollConfiguration": {
            "shape": "S37"
          },
          "LogConfiguration": {
            "shape": "S38"
          },
          "ManifestProcessingRules": {
            "shape": "S39"
          },
          "Name": {},
          "PersonalizationThresholdSeconds": {
            "type": "integer"
          },
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        }
      },
      "idempotent": true
    },
    "StartChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{ChannelName}/start",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StopChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{ChannelName}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "shape": "S3k",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        }
      },
      "idempotent": true
    },
    "UpdateChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{ChannelName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "Outputs"
        ],
        "members": {
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "FillerSlate": {
            "shape": "Sa"
          },
          "Outputs": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "ChannelName": {},
          "ChannelState": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "FillerSlate": {
            "shape": "Sa"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "Outputs": {
            "shape": "Sn"
          },
          "PlaybackMode": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "Tier": {}
        }
      },
      "idempotent": true
    },
    "UpdateLiveSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/sourceLocation/{SourceLocationName}/liveSource/{LiveSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpPackageConfigurations",
          "LiveSourceName",
          "SourceLocationName"
        ],
        "members": {
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LiveSourceName": {
            "location": "uri",
            "locationName": "LiveSourceName"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "LiveSourceName": {},
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "idempotent": true
    },
    "UpdateProgram": {
      "http": {
        "method": "PUT",
        "requestUri": "/channel/{ChannelName}/program/{ProgramName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ChannelName",
          "ProgramName",
          "ScheduleConfiguration"
        ],
        "members": {
          "AdBreaks": {
            "shape": "S12"
          },
          "ChannelName": {
            "location": "uri",
            "locationName": "ChannelName"
          },
          "ProgramName": {
            "location": "uri",
            "locationName": "ProgramName"
          },
          "ScheduleConfiguration": {
            "type": "structure",
            "members": {
              "ClipRange": {
                "shape": "S1f"
              },
              "Transition": {
                "type": "structure",
                "members": {
                  "DurationMillis": {
                    "type": "long"
                  },
                  "ScheduledStartTimeMillis": {
                    "type": "long"
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdBreaks": {
            "shape": "S12"
          },
          "Arn": {},
          "ChannelName": {},
          "ClipRange": {
            "shape": "S1f"
          },
          "CreationTime": {
            "shape": "Sm"
          },
          "DurationMillis": {
            "type": "long"
          },
          "LiveSourceName": {},
          "ProgramName": {},
          "ScheduledStartTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "VodSourceName": {}
        }
      },
      "idempotent": true
    },
    "UpdateSourceLocation": {
      "http": {
        "method": "PUT",
        "requestUri": "/sourceLocation/{SourceLocationName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpConfiguration",
          "SourceLocationName"
        ],
        "members": {
          "AccessConfiguration": {
            "shape": "S1k"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S1n"
          },
          "HttpConfiguration": {
            "shape": "S1o"
          },
          "SegmentDeliveryConfigurations": {
            "shape": "S1p"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessConfiguration": {
            "shape": "S1k"
          },
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "DefaultSegmentDeliveryConfiguration": {
            "shape": "S1n"
          },
          "HttpConfiguration": {
            "shape": "S1o"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SegmentDeliveryConfigurations": {
            "shape": "S1p"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          }
        }
      },
      "idempotent": true
    },
    "UpdateVodSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/sourceLocation/{SourceLocationName}/vodSource/{VodSourceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "HttpPackageConfigurations",
          "SourceLocationName",
          "VodSourceName"
        ],
        "members": {
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "SourceLocationName": {
            "location": "uri",
            "locationName": "SourceLocationName"
          },
          "VodSourceName": {
            "location": "uri",
            "locationName": "VodSourceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "shape": "Sm"
          },
          "HttpPackageConfigurations": {
            "shape": "Sq"
          },
          "LastModifiedTime": {
            "shape": "Sm"
          },
          "SourceLocationName": {},
          "Tags": {
            "shape": "Si",
            "locationName": "tags"
          },
          "VodSourceName": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "members": {
        "SourceLocationName": {},
        "VodSourceName": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ManifestName",
          "SourceGroup"
        ],
        "members": {
          "DashPlaylistSettings": {
            "shape": "Sd"
          },
          "HlsPlaylistSettings": {
            "shape": "Se"
          },
          "ManifestName": {},
          "SourceGroup": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "ManifestWindowSeconds": {
          "type": "integer"
        },
        "MinBufferTimeSeconds": {
          "type": "integer"
        },
        "MinUpdatePeriodSeconds": {
          "type": "integer"
        },
        "SuggestedPresentationDelaySeconds": {
          "type": "integer"
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "AdMarkupType": {
          "type": "list",
          "member": {}
        },
        "ManifestWindowSeconds": {
          "type": "integer"
        }
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sm": {
      "type": "timestamp",
      "timestampFormat": "unixTimestamp"
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ManifestName",
          "PlaybackUrl",
          "SourceGroup"
        ],
        "members": {
          "DashPlaylistSettings": {
            "shape": "Sd"
          },
          "HlsPlaylistSettings": {
            "shape": "Se"
          },
          "ManifestName": {},
          "PlaybackUrl": {},
          "SourceGroup": {}
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Path",
          "SourceGroup",
          "Type"
        ],
        "members": {
          "Path": {},
          "SourceGroup": {},
          "Type": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "EndTime"
      ],
      "members": {
        "AvailMatchingCriteria": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DynamicVariable",
              "Operator"
            ],
            "members": {
              "DynamicVariable": {},
              "Operator": {}
            }
          }
        },
        "EndTime": {
          "shape": "Sm"
        },
        "StartTime": {
          "shape": "Sm"
        }
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "EndTime"
      ],
      "members": {
        "DynamicVariables": {
          "shape": "Si"
        },
        "EndTime": {
          "shape": "Sm"
        },
        "StartTime": {
          "shape": "Sm"
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AdBreakMetadata": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Value"
              ],
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "MessageType": {},
          "OffsetMillis": {
            "type": "long"
          },
          "Slate": {
            "shape": "Sa"
          },
          "SpliceInsertMessage": {
            "type": "structure",
            "members": {
              "AvailNum": {
                "type": "integer"
              },
              "AvailsExpected": {
                "type": "integer"
              },
              "SpliceEventId": {
                "type": "integer"
              },
              "UniqueProgramId": {
                "type": "integer"
              }
            }
          },
          "TimeSignalMessage": {
            "type": "structure",
            "members": {
              "SegmentationDescriptors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "SegmentNum": {
                      "type": "integer"
                    },
                    "SegmentationEventId": {
                      "type": "integer"
                    },
                    "SegmentationTypeId": {
                      "type": "integer"
                    },
                    "SegmentationUpid": {},
                    "SegmentationUpidType": {
                      "type": "integer"
                    },
                    "SegmentsExpected": {
                      "type": "integer"
                    },
                    "SubSegmentNum": {
                      "type": "integer"
                    },
                    "SubSegmentsExpected": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "EndOffsetMillis"
      ],
      "members": {
        "EndOffsetMillis": {
          "type": "long"
        }
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "AccessType": {},
        "SecretsManagerAccessTokenConfiguration": {
          "type": "structure",
          "members": {
            "HeaderName": {},
            "SecretArn": {},
            "SecretStringKey": {}
          }
        }
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "BaseUrl": {}
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "BaseUrl"
      ],
      "members": {
        "BaseUrl": {}
      }
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BaseUrl": {},
          "Name": {}
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "LogTypes": {
          "shape": "S3"
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "FillPolicy": {},
        "Mode": {},
        "Value": {}
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "EndUrl": {},
        "StartUrl": {}
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "AdSegmentUrlPrefix": {},
        "ContentSegmentUrlPrefix": {}
      }
    },
    "S33": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Si"
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "ManifestEndpointPrefix": {},
        "MpdLocation": {},
        "OriginManifestType": {}
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "ManifestEndpointPrefix": {}
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "AdDecisionServerUrl": {},
        "MaxDurationSeconds": {
          "type": "integer"
        }
      }
    },
    "S38": {
      "type": "structure",
      "required": [
        "PercentEnabled"
      ],
      "members": {
        "PercentEnabled": {
          "type": "integer"
        }
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "AdMarkerPassthrough": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S3k": {
      "type": "list",
      "member": {}
    }
  }
}