{
  "pagination": {
    "ListEndpointAccess": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "endpoints"
    },
    "ListNamespaces": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "namespaces"
    },
    "ListRecoveryPoints": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "recoveryPoints"
    },
    "ListSnapshots": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "snapshots"
    },
    "ListTableRestoreStatus": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "tableRestoreStatuses"
    },
    "ListUsageLimits": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "usageLimits"
    },
    "ListWorkgroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "workgroups"
    }
  }
}
