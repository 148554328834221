import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';

import CalcOptionsForm from '../calc-options-form';
import CalcInfoForms from '../calc-info-forms';
import CalcResults from '../calc-results';
import CalcUnsure from '../calc-unsure';

import DollarInput from '../dollar-input';
import NumberInput from '../number-input';
import Slider from '../slider';

import calculator from '../../calculator';

import styles from '../../css/calculator.module.css';

import {
    CALC_OPTIONS_PAGE,
    CALC_RESULTS_PAGE,
    CALC_UNSURE_PAGE,
    INFO_PAGES,
    INFO_PAGES_COMPLETE,

    REPLACE_INCOME_AMOUNT,
    REPLACE_INCOME_MULTIPLIER,
    REPLACE_INCOME_YEARS,
    REPLACE_INCOME_GROWTH_RATE,
    COVER_MORTGAGE_KEY,
    COVER_STUDENT_LOAN_KEY,
    COVER_OTHER_KEY,
    FUND_NUMBER_KEY,
    FUND_TYPE_KEY,
    FUND_TYPE_COST_KEY,
    FUND_TYPE_YEARS,
    FINAL_EXPENSES_BURIAL,
    FINAL_EXPENSES_FUNERAL,
    FUND_TYPE_COSTS
} from '../../constants';
import { getToken } from '../../token-retriever';

let oauthToken;
const setHelper = (state, values, key, setter) => ({
    ...state,
    [key]: setter(state[key], values[key])

});

const loadSavedCalcResults = (state, values) => {
    state[INFO_PAGES_COMPLETE] = true;

    state[CALC_OPTIONS_PAGE].choices = values[`${CALC_OPTIONS_PAGE}:choices`];

    setHelper(state, values, REPLACE_INCOME_AMOUNT, DollarInput.State.set);
    setHelper(state, values, REPLACE_INCOME_YEARS, NumberInput.State.set);
    setHelper(state, values, REPLACE_INCOME_GROWTH_RATE, NumberInput.State.set);
    state[REPLACE_INCOME_MULTIPLIER] = values[REPLACE_INCOME_MULTIPLIER];

    setHelper(state, values, COVER_MORTGAGE_KEY, DollarInput.State.set);
    setHelper(state, values, COVER_OTHER_KEY, DollarInput.State.set);
    setHelper(state, values, COVER_STUDENT_LOAN_KEY, DollarInput.State.set);

    setHelper(state, values, FUND_NUMBER_KEY, NumberInput.State.set);
    setHelper(state, values, FUND_TYPE_YEARS, NumberInput.State.set);
    state[FUND_TYPE_KEY] = values[FUND_TYPE_KEY];

    const { average, max, increments } = FUND_TYPE_COSTS[values[FUND_TYPE_KEY]];

    state[FUND_TYPE_COST_KEY] = (
        Slider.State.setPointer(
            Slider.State.setPositionNearestValue(
                Slider.State.setBounds(
                    state[FUND_TYPE_COST_KEY],
                    0,
                    max,
                    increments
                ),
                values[FUND_TYPE_COST_KEY]
            ),
            { label: 'National average*', value: average }
        )
    );

    setHelper(state, values, FINAL_EXPENSES_FUNERAL, DollarInput.State.set);
    setHelper(state, values, FINAL_EXPENSES_BURIAL, DollarInput.State.set);

    state = calculator.updateCalcResultsSlices(state);

    return state;
};

const resultsFetcher = (update, resultsId, history, page) => {
    if (!oauthToken) {
        oauthToken = getToken();
    }
    return fetch(
        `/life-calculator/api/v1/calc/${resultsId}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${oauthToken}`
            }
        }
    )
        .then(res => res.json())
        .then(data => {
            if (!data.success) {
                // TODO: Redirect to error page
                console.debug('Error loading values', resultsId);
                throw new Error(`Failed to load results for id: ${resultsId}`);
            }

            update(state => loadSavedCalcResults(state, data.data));

            history.push(page);

            return data.id;
        });
};


const CalculatorState = ({
    Initial: () => ({
        [INFO_PAGES_COMPLETE]: false,
        ...DollarInput.State.Initial(REPLACE_INCOME_AMOUNT, {
            min: 0,
            max: 9999999,
            title: 'Income'
        }),
        [REPLACE_INCOME_MULTIPLIER]: 1,
        ...NumberInput.State.Initial(REPLACE_INCOME_YEARS, {
            min: 1,
            max: 30,
            value: 5,
            title: 'Years'
        }),
        ...DollarInput.State.Initial(COVER_MORTGAGE_KEY, {
            min: 0,
            max: 9999999,
            title: 'Mortgage'
        }),
        ...DollarInput.State.Initial(COVER_STUDENT_LOAN_KEY, {
            min: 0,
            max: 9999999,
            title: 'Student loans'
        }),
        ...DollarInput.State.Initial(COVER_OTHER_KEY, {
            min: 0,
            max: 9999999,
            title: 'Other debt'
        }),
        ...NumberInput.State.Initial(FUND_NUMBER_KEY, {
            value: 0,
            min: 0,
            max: 20
        }),
        [FUND_TYPE_KEY]: 'public-in-state',
        [FUND_TYPE_COST_KEY]: Slider.State.setPositionNearestValue(
            Slider.State.Initial({
                min: 0,
                max: FUND_TYPE_COSTS['public-in-state'].max,
                increments: FUND_TYPE_COSTS['public-in-state'].increments,
                position: 0,
                pointer: {
                    label: 'National average*',
                    value: FUND_TYPE_COSTS['public-in-state'].average
                }
            }),
            FUND_TYPE_COSTS['public-in-state'].average
        ),
        ...NumberInput.State.Initial(FUND_TYPE_YEARS, {
            min: 0,
            max: 10,
            value: FUND_TYPE_COSTS['public-in-state'].years
        }),
        ...DollarInput.State.Initial(FINAL_EXPENSES_BURIAL, {
            min: 0,
            max: 100000,
            value: 5000,
            title: 'Burial or cremation'
        }),
        ...DollarInput.State.Initial(FINAL_EXPENSES_FUNERAL, {
            min: 0,
            max: 100000,
            value: 10000,
            title: 'Funeral'
        }),
        ...CalcOptionsForm.State.Initial(CALC_OPTIONS_PAGE),
        ...CalcInfoForms.State.Initial(),
        ...CalcResults.State.Initial()
    }),
    Actions: (update) => (
        {
            loadMyCalcResults: (resultsId, history, page) => resultsFetcher(
                update, resultsId, history, page
            ),
            ...CalcOptionsForm.State.Actions(update),
            ...CalcInfoForms.State.Actions(update),
            ...CalcResults.State.Actions(update)
        }
    )
});


const LoadCalcResults = ({ actions, page }) => {
    const { resultsId } = useParams();
    const history = useHistory();
    useEffect(() => { actions.loadMyCalcResults(resultsId, history, page); }, []);

    return (
        <div>
            <div className={styles.loadingMessage}>
                Please wait while we load your saved needs calculator results.
            </div>
            <div className={styles.loading} />
        </div>
    );
};

LoadCalcResults.propTypes = {
    actions: PropTypes.shape(
        { loadMyCalcResults: PropTypes.func.isRequired }
    ).isRequired,
    page: PropTypes.string.isRequired
};


const CalculatorComponent = ({ state, actions }) => (
    <Switch>
        <Route path="/life-calculator/calculator/info">
            <CalcInfoForms.Component id={INFO_PAGES}
                state={state}
                actions={actions}
            />
        </Route>
        <Route path="/life-calculator/calculator/results">
            <CalcResults.Component id={CALC_RESULTS_PAGE}
                state={state}
                actions={actions}
            />
        </Route>
        <Route path="/life-calculator/calculator/contact">
            <CalcUnsure.Component id={CALC_UNSURE_PAGE} state={state} actions={actions} />
        </Route>
        <Route path="/life-calculator/calculator/contact/:resultsId">
            <LoadCalcResults actions={actions} page="/life-calculator/calculator/contact" />
        </Route>
        <Route path="/life-calculator/calculator/get-quote/:resultsId">
            <LoadCalcResults actions={actions} page="/life-calculator/quote" />
        </Route>
        <Route path="/life-calculator/calculator/get-help">
            <CalcUnsure.Component id={CALC_UNSURE_PAGE} state={state} actions={actions} />
        </Route>
        <Route path="/life-calculator/calculator/my/results/:resultsId">
            <LoadCalcResults actions={actions} page="/life-calculator/calculator/results" />
        </Route>
        <Route path="/life-calculator/calculator">
            <CalcOptionsForm.Component id={CALC_OPTIONS_PAGE}
                state={state}
                actions={actions}
            />
        </Route>
    </Switch>
);

CalculatorComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};


export default {
    State: CalculatorState,
    Component: CalculatorComponent
};
