{
  "pagination": {
    "ListScheduleGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ScheduleGroups"
    },
    "ListSchedules": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Schedules"
    }
  }
}
