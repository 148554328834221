{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-11-29",
    "endpointPrefix": "iottwinmaker",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT TwinMaker",
    "serviceId": "IoTTwinMaker",
    "signatureVersion": "v4",
    "signingName": "iottwinmaker",
    "uid": "iottwinmaker-2021-11-29"
  },
  "operations": {
    "BatchPutPropertyValues": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/entity-properties",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "entries"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entries": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "errorEntries"
        ],
        "members": {
          "errorEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "errors"
              ],
              "members": {
                "errors": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "errorCode",
                      "errorMessage",
                      "entry"
                    ],
                    "members": {
                      "errorCode": {},
                      "errorMessage": {},
                      "entry": {
                        "shape": "S4"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "CreateComponentType": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/component-types/{componentTypeId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "isSingleton": {
            "type": "boolean"
          },
          "componentTypeId": {
            "location": "uri",
            "locationName": "componentTypeId"
          },
          "description": {},
          "propertyDefinitions": {
            "shape": "Sv"
          },
          "extendsFrom": {
            "shape": "S13"
          },
          "functions": {
            "shape": "S14"
          },
          "tags": {
            "shape": "S1b"
          },
          "propertyGroups": {
            "shape": "S1e"
          },
          "componentTypeName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "creationDateTime",
          "state"
        ],
        "members": {
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateEntity": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/entities",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "entityName"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entityId": {},
          "entityName": {},
          "description": {},
          "components": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "description": {},
                "componentTypeId": {},
                "properties": {
                  "shape": "S1q"
                },
                "propertyGroups": {
                  "shape": "S1t"
                }
              }
            }
          },
          "parentEntityId": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entityId",
          "arn",
          "creationDateTime",
          "state"
        ],
        "members": {
          "entityId": {},
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateScene": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/scenes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "sceneId",
          "contentLocation"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "sceneId": {},
          "contentLocation": {},
          "description": {},
          "capabilities": {
            "shape": "S20"
          },
          "tags": {
            "shape": "S1b"
          },
          "sceneMetadata": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "creationDateTime"
        ],
        "members": {
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateSyncJob": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/sync-jobs/{syncSource}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "syncSource",
          "syncRole"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "syncSource": {
            "location": "uri",
            "locationName": "syncSource"
          },
          "syncRole": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "creationDateTime",
          "state"
        ],
        "members": {
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "CreateWorkspace": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "s3Location",
          "role"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "description": {},
          "s3Location": {},
          "role": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "creationDateTime"
        ],
        "members": {
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteComponentType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/component-types/{componentTypeId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "componentTypeId": {
            "location": "uri",
            "locationName": "componentTypeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "state"
        ],
        "members": {
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteEntity": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/entities/{entityId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "entityId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entityId": {
            "location": "uri",
            "locationName": "entityId"
          },
          "isRecursive": {
            "location": "querystring",
            "locationName": "isRecursive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "state"
        ],
        "members": {
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteScene": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/scenes/{sceneId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "sceneId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "sceneId": {
            "location": "uri",
            "locationName": "sceneId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteSyncJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}/sync-jobs/{syncSource}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "syncSource"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "syncSource": {
            "location": "uri",
            "locationName": "syncSource"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "state"
        ],
        "members": {
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "DeleteWorkspace": {
      "http": {
        "method": "DELETE",
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ExecuteQuery": {
      "http": {
        "requestUri": "/queries/execution",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "queryStatement"
        ],
        "members": {
          "workspaceId": {},
          "queryStatement": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "columnDescriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "type": {}
              }
            }
          },
          "rows": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "rowData": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {},
                    "document": true
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetComponentType": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/component-types/{componentTypeId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "componentTypeId": {
            "location": "uri",
            "locationName": "componentTypeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeId",
          "creationDateTime",
          "updateDateTime",
          "arn"
        ],
        "members": {
          "workspaceId": {},
          "isSingleton": {
            "type": "boolean"
          },
          "componentTypeId": {},
          "description": {},
          "propertyDefinitions": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S33"
            }
          },
          "extendsFrom": {
            "shape": "S13"
          },
          "functions": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "requiredProperties": {
                  "shape": "S16"
                },
                "scope": {},
                "implementedBy": {
                  "shape": "S18"
                },
                "isInherited": {
                  "type": "boolean"
                }
              }
            }
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "updateDateTime": {
            "type": "timestamp"
          },
          "arn": {},
          "isAbstract": {
            "type": "boolean"
          },
          "isSchemaInitialized": {
            "type": "boolean"
          },
          "status": {
            "shape": "S36"
          },
          "propertyGroups": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "groupType",
                "propertyNames",
                "isInherited"
              ],
              "members": {
                "groupType": {},
                "propertyNames": {
                  "shape": "S1h"
                },
                "isInherited": {
                  "type": "boolean"
                }
              }
            }
          },
          "syncSource": {},
          "componentTypeName": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetEntity": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/entities/{entityId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "entityId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entityId": {
            "location": "uri",
            "locationName": "entityId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "entityId",
          "entityName",
          "arn",
          "status",
          "workspaceId",
          "parentEntityId",
          "hasChildEntities",
          "creationDateTime",
          "updateDateTime"
        ],
        "members": {
          "entityId": {},
          "entityName": {},
          "arn": {},
          "status": {
            "shape": "S36"
          },
          "workspaceId": {},
          "description": {},
          "components": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "componentName": {},
                "description": {},
                "componentTypeId": {},
                "status": {
                  "shape": "S36"
                },
                "definedIn": {},
                "properties": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "structure",
                    "members": {
                      "definition": {
                        "shape": "S33"
                      },
                      "value": {
                        "shape": "Sd"
                      }
                    }
                  }
                },
                "propertyGroups": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "structure",
                    "required": [
                      "groupType",
                      "propertyNames",
                      "isInherited"
                    ],
                    "members": {
                      "groupType": {},
                      "propertyNames": {
                        "shape": "S1h"
                      },
                      "isInherited": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "syncSource": {}
              }
            }
          },
          "parentEntityId": {},
          "hasChildEntities": {
            "type": "boolean"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "updateDateTime": {
            "type": "timestamp"
          },
          "syncSource": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetPricingPlan": {
      "http": {
        "method": "GET",
        "requestUri": "/pricingplan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "currentPricingPlan"
        ],
        "members": {
          "currentPricingPlan": {
            "shape": "S3m"
          },
          "pendingPricingPlan": {
            "shape": "S3m"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetPropertyValue": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/entity-properties/value",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "selectedProperties",
          "workspaceId"
        ],
        "members": {
          "componentName": {},
          "componentTypeId": {},
          "entityId": {},
          "selectedProperties": {
            "shape": "S3u"
          },
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "propertyGroupName": {},
          "tabularConditions": {
            "type": "structure",
            "members": {
              "orderBy": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "propertyName"
                  ],
                  "members": {
                    "order": {},
                    "propertyName": {}
                  }
                }
              },
              "propertyFilters": {
                "shape": "S40"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "propertyValues": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "propertyReference"
              ],
              "members": {
                "propertyReference": {
                  "shape": "S5"
                },
                "propertyValue": {
                  "shape": "Sd"
                }
              }
            }
          },
          "nextToken": {},
          "tabularPropertyValues": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "Sd"
                }
              }
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "GetPropertyValueHistory": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/entity-properties/history",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "selectedProperties"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entityId": {},
          "componentName": {},
          "componentTypeId": {},
          "selectedProperties": {
            "shape": "S3u"
          },
          "propertyFilters": {
            "shape": "S40"
          },
          "startDateTime": {
            "deprecated": true,
            "deprecatedMessage": "This field is deprecated and will throw an error in the future. Use startTime instead.",
            "type": "timestamp"
          },
          "endDateTime": {
            "deprecated": true,
            "deprecatedMessage": "This field is deprecated and will throw an error in the future. Use endTime instead.",
            "type": "timestamp"
          },
          "interpolation": {
            "type": "structure",
            "members": {
              "interpolationType": {},
              "intervalInSeconds": {
                "type": "long"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "orderByTime": {},
          "startTime": {},
          "endTime": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "propertyValues"
        ],
        "members": {
          "propertyValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "entityPropertyReference"
              ],
              "members": {
                "entityPropertyReference": {
                  "shape": "S5"
                },
                "values": {
                  "type": "list",
                  "member": {
                    "shape": "Sb"
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "data."
      }
    },
    "GetScene": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}/scenes/{sceneId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "sceneId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "sceneId": {
            "location": "uri",
            "locationName": "sceneId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaceId",
          "sceneId",
          "contentLocation",
          "arn",
          "creationDateTime",
          "updateDateTime"
        ],
        "members": {
          "workspaceId": {},
          "sceneId": {},
          "contentLocation": {},
          "arn": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "updateDateTime": {
            "type": "timestamp"
          },
          "description": {},
          "capabilities": {
            "shape": "S20"
          },
          "sceneMetadata": {
            "shape": "S22"
          },
          "generatedSceneMetadata": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "error": {
            "type": "structure",
            "members": {
              "code": {},
              "message": {}
            }
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetSyncJob": {
      "http": {
        "method": "GET",
        "requestUri": "/sync-jobs/{syncSource}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "syncSource"
        ],
        "members": {
          "syncSource": {
            "location": "uri",
            "locationName": "syncSource"
          },
          "workspaceId": {
            "location": "querystring",
            "locationName": "workspace"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "arn",
          "workspaceId",
          "syncSource",
          "syncRole",
          "status",
          "creationDateTime",
          "updateDateTime"
        ],
        "members": {
          "arn": {},
          "workspaceId": {},
          "syncSource": {},
          "syncRole": {},
          "status": {
            "shape": "S4o"
          },
          "creationDateTime": {
            "type": "timestamp"
          },
          "updateDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "GetWorkspace": {
      "http": {
        "method": "GET",
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaceId",
          "arn",
          "s3Location",
          "role",
          "creationDateTime",
          "updateDateTime"
        ],
        "members": {
          "workspaceId": {},
          "arn": {},
          "description": {},
          "s3Location": {},
          "role": {},
          "creationDateTime": {
            "type": "timestamp"
          },
          "updateDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListComponentTypes": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/component-types-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "extendsFrom": {},
                "namespace": {},
                "isAbstract": {
                  "type": "boolean"
                }
              },
              "union": true
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeSummaries"
        ],
        "members": {
          "workspaceId": {},
          "componentTypeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "componentTypeId",
                "creationDateTime",
                "updateDateTime"
              ],
              "members": {
                "arn": {},
                "componentTypeId": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "updateDateTime": {
                  "type": "timestamp"
                },
                "description": {},
                "status": {
                  "shape": "S36"
                },
                "componentTypeName": {}
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListEntities": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/entities-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "parentEntityId": {},
                "componentTypeId": {},
                "externalId": {}
              },
              "union": true
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "entitySummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "entityId",
                "entityName",
                "arn",
                "status",
                "creationDateTime",
                "updateDateTime"
              ],
              "members": {
                "entityId": {},
                "entityName": {},
                "arn": {},
                "parentEntityId": {},
                "status": {
                  "shape": "S36"
                },
                "description": {},
                "hasChildEntities": {
                  "type": "boolean"
                },
                "creationDateTime": {
                  "type": "timestamp"
                },
                "updateDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListScenes": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/scenes-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sceneSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sceneId",
                "contentLocation",
                "arn",
                "creationDateTime",
                "updateDateTime"
              ],
              "members": {
                "sceneId": {},
                "contentLocation": {},
                "arn": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "updateDateTime": {
                  "type": "timestamp"
                },
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListSyncJobs": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/sync-jobs-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "syncJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "workspaceId": {},
                "syncSource": {},
                "status": {
                  "shape": "S4o"
                },
                "creationDateTime": {
                  "type": "timestamp"
                },
                "updateDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListSyncResources": {
      "http": {
        "requestUri": "/workspaces/{workspaceId}/sync-jobs/{syncSource}/resources-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "syncSource"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "syncSource": {
            "location": "uri",
            "locationName": "syncSource"
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "state": {},
                "resourceType": {},
                "resourceId": {},
                "externalId": {}
              },
              "union": true
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "syncResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "externalId": {},
                "resourceId": {},
                "status": {
                  "type": "structure",
                  "members": {
                    "state": {},
                    "error": {
                      "shape": "S37"
                    }
                  }
                },
                "updateDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListTagsForResource": {
      "http": {
        "requestUri": "/tags-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN"
        ],
        "members": {
          "resourceARN": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1b"
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "ListWorkspaces": {
      "http": {
        "requestUri": "/workspaces-list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "workspaceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "workspaceId",
                "arn",
                "creationDateTime",
                "updateDateTime"
              ],
              "members": {
                "workspaceId": {},
                "arn": {},
                "description": {},
                "creationDateTime": {
                  "type": "timestamp"
                },
                "updateDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tags"
        ],
        "members": {
          "resourceARN": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tagKeys"
        ],
        "members": {
          "resourceARN": {
            "location": "querystring",
            "locationName": "resourceARN"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateComponentType": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/component-types/{componentTypeId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "componentTypeId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "isSingleton": {
            "type": "boolean"
          },
          "componentTypeId": {
            "location": "uri",
            "locationName": "componentTypeId"
          },
          "description": {},
          "propertyDefinitions": {
            "shape": "Sv"
          },
          "extendsFrom": {
            "shape": "S13"
          },
          "functions": {
            "shape": "S14"
          },
          "propertyGroups": {
            "shape": "S1e"
          },
          "componentTypeName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "workspaceId",
          "arn",
          "componentTypeId",
          "state"
        ],
        "members": {
          "workspaceId": {},
          "arn": {},
          "componentTypeId": {},
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateEntity": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/entities/{entityId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "entityId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "entityId": {
            "location": "uri",
            "locationName": "entityId"
          },
          "entityName": {},
          "description": {},
          "componentUpdates": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "updateType": {},
                "description": {},
                "componentTypeId": {},
                "propertyUpdates": {
                  "shape": "S1q"
                },
                "propertyGroupUpdates": {
                  "shape": "S1t"
                }
              }
            }
          },
          "parentEntityUpdate": {
            "type": "structure",
            "required": [
              "updateType"
            ],
            "members": {
              "updateType": {},
              "parentEntityId": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "updateDateTime",
          "state"
        ],
        "members": {
          "updateDateTime": {
            "type": "timestamp"
          },
          "state": {}
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdatePricingPlan": {
      "http": {
        "requestUri": "/pricingplan",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "pricingMode"
        ],
        "members": {
          "pricingMode": {},
          "bundleNames": {
            "shape": "S3o"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "currentPricingPlan"
        ],
        "members": {
          "currentPricingPlan": {
            "shape": "S3m"
          },
          "pendingPricingPlan": {
            "shape": "S3m"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateScene": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}/scenes/{sceneId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId",
          "sceneId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "sceneId": {
            "location": "uri",
            "locationName": "sceneId"
          },
          "contentLocation": {},
          "description": {},
          "capabilities": {
            "shape": "S20"
          },
          "sceneMetadata": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "updateDateTime"
        ],
        "members": {
          "updateDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    },
    "UpdateWorkspace": {
      "http": {
        "method": "PUT",
        "requestUri": "/workspaces/{workspaceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workspaceId"
        ],
        "members": {
          "workspaceId": {
            "location": "uri",
            "locationName": "workspaceId"
          },
          "description": {},
          "role": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "updateDateTime"
        ],
        "members": {
          "updateDateTime": {
            "type": "timestamp"
          }
        }
      },
      "endpoint": {
        "hostPrefix": "api."
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "entityPropertyReference"
      ],
      "members": {
        "entityPropertyReference": {
          "shape": "S5"
        },
        "propertyValues": {
          "type": "list",
          "member": {
            "shape": "Sb"
          }
        }
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "propertyName"
      ],
      "members": {
        "componentName": {},
        "externalIdProperty": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "entityId": {},
        "propertyName": {}
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "value"
      ],
      "members": {
        "timestamp": {
          "deprecated": true,
          "deprecatedMessage": "This field is deprecated and will throw an error in the future. Use time instead.",
          "type": "timestamp"
        },
        "value": {
          "shape": "Sd"
        },
        "time": {}
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "booleanValue": {
          "type": "boolean"
        },
        "doubleValue": {
          "type": "double"
        },
        "integerValue": {
          "type": "integer"
        },
        "longValue": {
          "type": "long"
        },
        "stringValue": {},
        "listValue": {
          "shape": "Si"
        },
        "mapValue": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "Sd"
          }
        },
        "relationshipValue": {
          "type": "structure",
          "members": {
            "targetEntityId": {},
            "targetComponentName": {}
          }
        },
        "expression": {}
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "shape": "Sd"
      }
    },
    "Sv": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Sw"
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "dataType": {
          "shape": "Sx"
        },
        "isRequiredInEntity": {
          "type": "boolean"
        },
        "isExternalId": {
          "type": "boolean"
        },
        "isStoredExternally": {
          "type": "boolean"
        },
        "isTimeSeries": {
          "type": "boolean"
        },
        "defaultValue": {
          "shape": "Sd"
        },
        "configuration": {
          "shape": "S10"
        },
        "displayName": {}
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "nestedType": {
          "shape": "Sx"
        },
        "allowedValues": {
          "shape": "Si"
        },
        "unitOfMeasure": {},
        "relationship": {
          "type": "structure",
          "members": {
            "targetComponentTypeId": {},
            "relationshipType": {}
          }
        }
      }
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S13": {
      "type": "list",
      "member": {}
    },
    "S14": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "requiredProperties": {
            "shape": "S16"
          },
          "scope": {},
          "implementedBy": {
            "shape": "S18"
          }
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {}
    },
    "S18": {
      "type": "structure",
      "members": {
        "lambda": {
          "type": "structure",
          "required": [
            "arn"
          ],
          "members": {
            "arn": {}
          }
        },
        "isNative": {
          "type": "boolean"
        }
      }
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1e": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "groupType": {},
          "propertyNames": {
            "shape": "S1h"
          }
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "definition": {
            "shape": "Sw"
          },
          "value": {
            "shape": "Sd"
          },
          "updateType": {}
        }
      }
    },
    "S1t": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "groupType": {},
          "propertyNames": {
            "shape": "S1h"
          },
          "updateType": {}
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {}
    },
    "S22": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S33": {
      "type": "structure",
      "required": [
        "dataType",
        "isTimeSeries",
        "isRequiredInEntity",
        "isExternalId",
        "isStoredExternally",
        "isImported",
        "isFinal",
        "isInherited"
      ],
      "members": {
        "dataType": {
          "shape": "Sx"
        },
        "isTimeSeries": {
          "type": "boolean"
        },
        "isRequiredInEntity": {
          "type": "boolean"
        },
        "isExternalId": {
          "type": "boolean"
        },
        "isStoredExternally": {
          "type": "boolean"
        },
        "isImported": {
          "type": "boolean"
        },
        "isFinal": {
          "type": "boolean"
        },
        "isInherited": {
          "type": "boolean"
        },
        "defaultValue": {
          "shape": "Sd"
        },
        "configuration": {
          "shape": "S10"
        },
        "displayName": {}
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "state": {},
        "error": {
          "shape": "S37"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "code": {},
        "message": {}
      }
    },
    "S3m": {
      "type": "structure",
      "required": [
        "effectiveDateTime",
        "pricingMode",
        "updateDateTime",
        "updateReason"
      ],
      "members": {
        "billableEntityCount": {
          "type": "long"
        },
        "bundleInformation": {
          "type": "structure",
          "required": [
            "bundleNames"
          ],
          "members": {
            "bundleNames": {
              "shape": "S3o"
            },
            "pricingTier": {}
          }
        },
        "effectiveDateTime": {
          "type": "timestamp"
        },
        "pricingMode": {},
        "updateDateTime": {
          "type": "timestamp"
        },
        "updateReason": {}
      }
    },
    "S3o": {
      "type": "list",
      "member": {}
    },
    "S3u": {
      "type": "list",
      "member": {}
    },
    "S40": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "propertyName": {},
          "operator": {},
          "value": {
            "shape": "Sd"
          }
        }
      }
    },
    "S4o": {
      "type": "structure",
      "members": {
        "state": {},
        "error": {
          "shape": "S37"
        }
      }
    }
  }
}