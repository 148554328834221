{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2022-02-17",
    "endpointPrefix": "cleanrooms",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Clean Rooms Service",
    "serviceId": "CleanRooms",
    "signatureVersion": "v4",
    "signingName": "cleanrooms",
    "uid": "cleanrooms-2022-02-17"
  },
  "operations": {
    "BatchGetCollaborationAnalysisTemplate": {
      "http": {
        "requestUri": "/collaborations/{collaborationIdentifier}/batch-analysistemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "analysisTemplateArns"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "analysisTemplateArns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaborationAnalysisTemplates",
          "errors"
        ],
        "members": {
          "collaborationAnalysisTemplates": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "code",
                "message"
              ],
              "members": {
                "arn": {},
                "code": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "BatchGetSchema": {
      "http": {
        "requestUri": "/collaborations/{collaborationIdentifier}/batch-schema",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "names"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "names": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "schemas",
          "errors"
        ],
        "members": {
          "schemas": {
            "type": "list",
            "member": {
              "shape": "Sx"
            }
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "code",
                "message"
              ],
              "members": {
                "name": {},
                "code": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "CreateAnalysisTemplate": {
      "http": {
        "requestUri": "/memberships/{membershipIdentifier}/analysistemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "name",
          "format",
          "source"
        ],
        "members": {
          "description": {},
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "name": {},
          "format": {},
          "source": {
            "shape": "Sj"
          },
          "tags": {
            "shape": "S1b"
          },
          "analysisParameters": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisTemplate"
        ],
        "members": {
          "analysisTemplate": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateCollaboration": {
      "http": {
        "requestUri": "/collaborations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "members",
          "name",
          "description",
          "creatorMemberAbilities",
          "creatorDisplayName",
          "queryLogStatus"
        ],
        "members": {
          "members": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "memberAbilities",
                "displayName"
              ],
              "members": {
                "accountId": {},
                "memberAbilities": {
                  "shape": "S1k"
                },
                "displayName": {}
              }
            }
          },
          "name": {},
          "description": {},
          "creatorMemberAbilities": {
            "shape": "S1k"
          },
          "creatorDisplayName": {},
          "dataEncryptionMetadata": {
            "shape": "S1p"
          },
          "queryLogStatus": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaboration"
        ],
        "members": {
          "collaboration": {
            "shape": "S1t"
          }
        }
      }
    },
    "CreateConfiguredTable": {
      "http": {
        "requestUri": "/configuredTables",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "tableReference",
          "allowedColumns",
          "analysisMethod"
        ],
        "members": {
          "name": {},
          "description": {},
          "tableReference": {
            "shape": "S1w"
          },
          "allowedColumns": {
            "shape": "S20"
          },
          "analysisMethod": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTable"
        ],
        "members": {
          "configuredTable": {
            "shape": "S22"
          }
        }
      },
      "idempotent": true
    },
    "CreateConfiguredTableAnalysisRule": {
      "http": {
        "requestUri": "/configuredTables/{configuredTableIdentifier}/analysisRule",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier",
          "analysisRuleType",
          "analysisRulePolicy"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          },
          "analysisRuleType": {},
          "analysisRulePolicy": {
            "shape": "S28"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisRule"
        ],
        "members": {
          "analysisRule": {
            "shape": "S2x"
          }
        }
      },
      "idempotent": true
    },
    "CreateConfiguredTableAssociation": {
      "http": {
        "requestUri": "/memberships/{membershipIdentifier}/configuredTableAssociations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "membershipIdentifier",
          "configuredTableIdentifier",
          "roleArn"
        ],
        "members": {
          "name": {},
          "description": {},
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "configuredTableIdentifier": {},
          "roleArn": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTableAssociation"
        ],
        "members": {
          "configuredTableAssociation": {
            "shape": "S31"
          }
        }
      }
    },
    "CreateMembership": {
      "http": {
        "requestUri": "/memberships",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "queryLogStatus"
        ],
        "members": {
          "collaborationIdentifier": {},
          "queryLogStatus": {},
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "membership"
        ],
        "members": {
          "membership": {
            "shape": "S36"
          }
        }
      }
    },
    "DeleteAnalysisTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/memberships/{membershipIdentifier}/analysistemplates/{analysisTemplateIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "analysisTemplateIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "analysisTemplateIdentifier": {
            "location": "uri",
            "locationName": "analysisTemplateIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteCollaboration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/collaborations/{collaborationIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteConfiguredTable": {
      "http": {
        "method": "DELETE",
        "requestUri": "/configuredTables/{configuredTableIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteConfiguredTableAnalysisRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/configuredTables/{configuredTableIdentifier}/analysisRule/{analysisRuleType}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier",
          "analysisRuleType"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          },
          "analysisRuleType": {
            "location": "uri",
            "locationName": "analysisRuleType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteConfiguredTableAssociation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/memberships/{membershipIdentifier}/configuredTableAssociations/{configuredTableAssociationIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableAssociationIdentifier",
          "membershipIdentifier"
        ],
        "members": {
          "configuredTableAssociationIdentifier": {
            "location": "uri",
            "locationName": "configuredTableAssociationIdentifier"
          },
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/collaborations/{collaborationIdentifier}/member/{accountId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "accountId"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "accountId": {
            "location": "uri",
            "locationName": "accountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/memberships/{membershipIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetAnalysisTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/analysistemplates/{analysisTemplateIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "analysisTemplateIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "analysisTemplateIdentifier": {
            "location": "uri",
            "locationName": "analysisTemplateIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisTemplate"
        ],
        "members": {
          "analysisTemplate": {
            "shape": "S1f"
          }
        }
      }
    },
    "GetCollaboration": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaboration"
        ],
        "members": {
          "collaboration": {
            "shape": "S1t"
          }
        }
      }
    },
    "GetCollaborationAnalysisTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/analysistemplates/{analysisTemplateArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "analysisTemplateArn"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "analysisTemplateArn": {
            "location": "uri",
            "locationName": "analysisTemplateArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaborationAnalysisTemplate"
        ],
        "members": {
          "collaborationAnalysisTemplate": {
            "shape": "S7"
          }
        }
      }
    },
    "GetConfiguredTable": {
      "http": {
        "method": "GET",
        "requestUri": "/configuredTables/{configuredTableIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTable"
        ],
        "members": {
          "configuredTable": {
            "shape": "S22"
          }
        }
      }
    },
    "GetConfiguredTableAnalysisRule": {
      "http": {
        "method": "GET",
        "requestUri": "/configuredTables/{configuredTableIdentifier}/analysisRule/{analysisRuleType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier",
          "analysisRuleType"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          },
          "analysisRuleType": {
            "location": "uri",
            "locationName": "analysisRuleType"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisRule"
        ],
        "members": {
          "analysisRule": {
            "shape": "S2x"
          }
        }
      }
    },
    "GetConfiguredTableAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/configuredTableAssociations/{configuredTableAssociationIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableAssociationIdentifier",
          "membershipIdentifier"
        ],
        "members": {
          "configuredTableAssociationIdentifier": {
            "location": "uri",
            "locationName": "configuredTableAssociationIdentifier"
          },
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTableAssociation"
        ],
        "members": {
          "configuredTableAssociation": {
            "shape": "S31"
          }
        }
      }
    },
    "GetMembership": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "membership"
        ],
        "members": {
          "membership": {
            "shape": "S36"
          }
        }
      }
    },
    "GetProtectedQuery": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/protectedQueries/{protectedQueryIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "protectedQueryIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "protectedQueryIdentifier": {
            "location": "uri",
            "locationName": "protectedQueryIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "protectedQuery"
        ],
        "members": {
          "protectedQuery": {
            "shape": "S44"
          }
        }
      }
    },
    "GetSchema": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/schemas/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "name"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "schema"
        ],
        "members": {
          "schema": {
            "shape": "Sx"
          }
        }
      }
    },
    "GetSchemaAnalysisRule": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/schemas/{name}/analysisRule/{type}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier",
          "name",
          "type"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "type": {
            "location": "uri",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisRule"
        ],
        "members": {
          "analysisRule": {
            "type": "structure",
            "required": [
              "collaborationId",
              "type",
              "name",
              "createTime",
              "updateTime",
              "policy"
            ],
            "members": {
              "collaborationId": {},
              "type": {},
              "name": {},
              "createTime": {
                "type": "timestamp"
              },
              "updateTime": {
                "type": "timestamp"
              },
              "policy": {
                "type": "structure",
                "members": {
                  "v1": {
                    "type": "structure",
                    "members": {
                      "list": {
                        "shape": "S2a"
                      },
                      "aggregation": {
                        "shape": "S2g"
                      },
                      "custom": {
                        "shape": "S2s"
                      }
                    },
                    "union": true
                  }
                },
                "union": true
              }
            }
          }
        }
      }
    },
    "ListAnalysisTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/analysistemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisTemplateSummaries"
        ],
        "members": {
          "nextToken": {},
          "analysisTemplateSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createTime",
                "id",
                "name",
                "updateTime",
                "membershipArn",
                "membershipId",
                "collaborationArn",
                "collaborationId"
              ],
              "members": {
                "arn": {},
                "createTime": {
                  "type": "timestamp"
                },
                "id": {},
                "name": {},
                "updateTime": {
                  "type": "timestamp"
                },
                "membershipArn": {},
                "membershipId": {},
                "collaborationArn": {},
                "collaborationId": {},
                "description": {}
              }
            }
          }
        }
      }
    },
    "ListCollaborationAnalysisTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/analysistemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaborationAnalysisTemplateSummaries"
        ],
        "members": {
          "nextToken": {},
          "collaborationAnalysisTemplateSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createTime",
                "id",
                "name",
                "updateTime",
                "collaborationArn",
                "collaborationId",
                "creatorAccountId"
              ],
              "members": {
                "arn": {},
                "createTime": {
                  "type": "timestamp"
                },
                "id": {},
                "name": {},
                "updateTime": {
                  "type": "timestamp"
                },
                "collaborationArn": {},
                "collaborationId": {},
                "creatorAccountId": {},
                "description": {}
              }
            }
          }
        }
      }
    },
    "ListCollaborations": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "memberStatus": {
            "location": "querystring",
            "locationName": "memberStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaborationList"
        ],
        "members": {
          "nextToken": {},
          "collaborationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "name",
                "creatorAccountId",
                "creatorDisplayName",
                "createTime",
                "updateTime",
                "memberStatus"
              ],
              "members": {
                "id": {},
                "arn": {},
                "name": {},
                "creatorAccountId": {},
                "creatorDisplayName": {},
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "memberStatus": {},
                "membershipId": {},
                "membershipArn": {}
              }
            }
          }
        }
      }
    },
    "ListConfiguredTableAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/configuredTableAssociations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTableAssociationSummaries"
        ],
        "members": {
          "configuredTableAssociationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "configuredTableId",
                "membershipId",
                "membershipArn",
                "name",
                "createTime",
                "updateTime",
                "id",
                "arn"
              ],
              "members": {
                "configuredTableId": {},
                "membershipId": {},
                "membershipArn": {},
                "name": {},
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "id": {},
                "arn": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListConfiguredTables": {
      "http": {
        "method": "GET",
        "requestUri": "/configuredTables",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTableSummaries"
        ],
        "members": {
          "configuredTableSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "name",
                "createTime",
                "updateTime",
                "analysisRuleTypes",
                "analysisMethod"
              ],
              "members": {
                "id": {},
                "arn": {},
                "name": {},
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "analysisRuleTypes": {
                  "shape": "S24"
                },
                "analysisMethod": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/members",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "memberSummaries"
        ],
        "members": {
          "nextToken": {},
          "memberSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "accountId",
                "status",
                "displayName",
                "abilities",
                "createTime",
                "updateTime"
              ],
              "members": {
                "accountId": {},
                "status": {},
                "displayName": {},
                "abilities": {
                  "shape": "S1k"
                },
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "membershipId": {},
                "membershipArn": {}
              }
            }
          }
        }
      }
    },
    "ListMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "membershipSummaries"
        ],
        "members": {
          "nextToken": {},
          "membershipSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "arn",
                "collaborationArn",
                "collaborationId",
                "collaborationCreatorAccountId",
                "collaborationCreatorDisplayName",
                "collaborationName",
                "createTime",
                "updateTime",
                "status",
                "memberAbilities"
              ],
              "members": {
                "id": {},
                "arn": {},
                "collaborationArn": {},
                "collaborationId": {},
                "collaborationCreatorAccountId": {},
                "collaborationCreatorDisplayName": {},
                "collaborationName": {},
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "status": {},
                "memberAbilities": {
                  "shape": "S1k"
                }
              }
            }
          }
        }
      }
    },
    "ListProtectedQueries": {
      "http": {
        "method": "GET",
        "requestUri": "/memberships/{membershipIdentifier}/protectedQueries",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "protectedQueries"
        ],
        "members": {
          "nextToken": {},
          "protectedQueries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "membershipId",
                "membershipArn",
                "createTime",
                "status"
              ],
              "members": {
                "id": {},
                "membershipId": {},
                "membershipArn": {},
                "createTime": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          }
        }
      }
    },
    "ListSchemas": {
      "http": {
        "method": "GET",
        "requestUri": "/collaborations/{collaborationIdentifier}/schemas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "schemaType": {
            "location": "querystring",
            "locationName": "schemaType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "schemaSummaries"
        ],
        "members": {
          "schemaSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "type",
                "creatorAccountId",
                "createTime",
                "updateTime",
                "collaborationId",
                "collaborationArn",
                "analysisRuleTypes"
              ],
              "members": {
                "name": {},
                "type": {},
                "creatorAccountId": {},
                "createTime": {
                  "type": "timestamp"
                },
                "updateTime": {
                  "type": "timestamp"
                },
                "collaborationId": {},
                "collaborationArn": {},
                "analysisRuleTypes": {
                  "shape": "S12"
                },
                "analysisMethod": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "S1b"
          }
        }
      }
    },
    "StartProtectedQuery": {
      "http": {
        "requestUri": "/memberships/{membershipIdentifier}/protectedQueries",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "type",
          "membershipIdentifier",
          "sqlParameters",
          "resultConfiguration"
        ],
        "members": {
          "type": {},
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "sqlParameters": {
            "shape": "S45"
          },
          "resultConfiguration": {
            "shape": "S49"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "protectedQuery"
        ],
        "members": {
          "protectedQuery": {
            "shape": "S44"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAnalysisTemplate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/memberships/{membershipIdentifier}/analysistemplates/{analysisTemplateIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "analysisTemplateIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "analysisTemplateIdentifier": {
            "location": "uri",
            "locationName": "analysisTemplateIdentifier"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisTemplate"
        ],
        "members": {
          "analysisTemplate": {
            "shape": "S1f"
          }
        }
      }
    },
    "UpdateCollaboration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/collaborations/{collaborationIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "collaborationIdentifier"
        ],
        "members": {
          "collaborationIdentifier": {
            "location": "uri",
            "locationName": "collaborationIdentifier"
          },
          "name": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "collaboration"
        ],
        "members": {
          "collaboration": {
            "shape": "S1t"
          }
        }
      }
    },
    "UpdateConfiguredTable": {
      "http": {
        "method": "PATCH",
        "requestUri": "/configuredTables/{configuredTableIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          },
          "name": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTable"
        ],
        "members": {
          "configuredTable": {
            "shape": "S22"
          }
        }
      }
    },
    "UpdateConfiguredTableAnalysisRule": {
      "http": {
        "method": "PATCH",
        "requestUri": "/configuredTables/{configuredTableIdentifier}/analysisRule/{analysisRuleType}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableIdentifier",
          "analysisRuleType",
          "analysisRulePolicy"
        ],
        "members": {
          "configuredTableIdentifier": {
            "location": "uri",
            "locationName": "configuredTableIdentifier"
          },
          "analysisRuleType": {
            "location": "uri",
            "locationName": "analysisRuleType"
          },
          "analysisRulePolicy": {
            "shape": "S28"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "analysisRule"
        ],
        "members": {
          "analysisRule": {
            "shape": "S2x"
          }
        }
      }
    },
    "UpdateConfiguredTableAssociation": {
      "http": {
        "method": "PATCH",
        "requestUri": "/memberships/{membershipIdentifier}/configuredTableAssociations/{configuredTableAssociationIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredTableAssociationIdentifier",
          "membershipIdentifier"
        ],
        "members": {
          "configuredTableAssociationIdentifier": {
            "location": "uri",
            "locationName": "configuredTableAssociationIdentifier"
          },
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "description": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredTableAssociation"
        ],
        "members": {
          "configuredTableAssociation": {
            "shape": "S31"
          }
        }
      }
    },
    "UpdateMembership": {
      "http": {
        "method": "PATCH",
        "requestUri": "/memberships/{membershipIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "queryLogStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "membership"
        ],
        "members": {
          "membership": {
            "shape": "S36"
          }
        }
      }
    },
    "UpdateProtectedQuery": {
      "http": {
        "method": "PATCH",
        "requestUri": "/memberships/{membershipIdentifier}/protectedQueries/{protectedQueryIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "membershipIdentifier",
          "protectedQueryIdentifier",
          "targetStatus"
        ],
        "members": {
          "membershipIdentifier": {
            "location": "uri",
            "locationName": "membershipIdentifier"
          },
          "protectedQueryIdentifier": {
            "location": "uri",
            "locationName": "protectedQueryIdentifier"
          },
          "targetStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "protectedQuery"
        ],
        "members": {
          "protectedQuery": {
            "shape": "S44"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "id",
        "arn",
        "collaborationId",
        "collaborationArn",
        "creatorAccountId",
        "name",
        "createTime",
        "updateTime",
        "schema",
        "format",
        "source"
      ],
      "members": {
        "id": {},
        "arn": {},
        "collaborationId": {},
        "collaborationArn": {},
        "description": {},
        "creatorAccountId": {},
        "name": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "schema": {
          "shape": "Sf"
        },
        "format": {},
        "source": {
          "shape": "Sj"
        },
        "analysisParameters": {
          "shape": "Sl"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "referencedTables": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "text": {}
      },
      "sensitive": true,
      "union": true
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "type"
        ],
        "members": {
          "name": {},
          "type": {},
          "defaultValue": {}
        },
        "sensitive": true
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "columns",
        "partitionKeys",
        "analysisRuleTypes",
        "creatorAccountId",
        "name",
        "collaborationId",
        "collaborationArn",
        "description",
        "createTime",
        "updateTime",
        "type"
      ],
      "members": {
        "columns": {
          "shape": "Sy"
        },
        "partitionKeys": {
          "shape": "Sy"
        },
        "analysisRuleTypes": {
          "shape": "S12"
        },
        "analysisMethod": {},
        "creatorAccountId": {},
        "name": {},
        "collaborationId": {},
        "collaborationArn": {},
        "description": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "type": {}
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "type"
        ],
        "members": {
          "name": {},
          "type": {}
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1f": {
      "type": "structure",
      "required": [
        "id",
        "arn",
        "collaborationId",
        "collaborationArn",
        "membershipId",
        "membershipArn",
        "name",
        "createTime",
        "updateTime",
        "schema",
        "format",
        "source"
      ],
      "members": {
        "id": {},
        "arn": {},
        "collaborationId": {},
        "collaborationArn": {},
        "membershipId": {},
        "membershipArn": {},
        "description": {},
        "name": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "schema": {
          "shape": "Sf"
        },
        "format": {},
        "source": {
          "shape": "Sj"
        },
        "analysisParameters": {
          "shape": "Sl"
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "structure",
      "required": [
        "allowCleartext",
        "allowDuplicates",
        "allowJoinsOnColumnsWithDifferentNames",
        "preserveNulls"
      ],
      "members": {
        "allowCleartext": {
          "type": "boolean"
        },
        "allowDuplicates": {
          "type": "boolean"
        },
        "allowJoinsOnColumnsWithDifferentNames": {
          "type": "boolean"
        },
        "preserveNulls": {
          "type": "boolean"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "id",
        "arn",
        "name",
        "creatorAccountId",
        "creatorDisplayName",
        "createTime",
        "updateTime",
        "memberStatus",
        "queryLogStatus"
      ],
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "creatorAccountId": {},
        "creatorDisplayName": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "memberStatus": {},
        "membershipId": {},
        "membershipArn": {},
        "dataEncryptionMetadata": {
          "shape": "S1p"
        },
        "queryLogStatus": {}
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "glue": {
          "type": "structure",
          "required": [
            "tableName",
            "databaseName"
          ],
          "members": {
            "tableName": {},
            "databaseName": {}
          }
        }
      },
      "union": true
    },
    "S20": {
      "type": "list",
      "member": {}
    },
    "S22": {
      "type": "structure",
      "required": [
        "id",
        "arn",
        "name",
        "tableReference",
        "createTime",
        "updateTime",
        "analysisRuleTypes",
        "analysisMethod",
        "allowedColumns"
      ],
      "members": {
        "id": {},
        "arn": {},
        "name": {},
        "description": {},
        "tableReference": {
          "shape": "S1w"
        },
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "analysisRuleTypes": {
          "shape": "S24"
        },
        "analysisMethod": {},
        "allowedColumns": {
          "shape": "S20"
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {}
    },
    "S28": {
      "type": "structure",
      "members": {
        "v1": {
          "type": "structure",
          "members": {
            "list": {
              "shape": "S2a"
            },
            "aggregation": {
              "shape": "S2g"
            },
            "custom": {
              "shape": "S2s"
            }
          },
          "union": true
        }
      },
      "union": true
    },
    "S2a": {
      "type": "structure",
      "required": [
        "joinColumns",
        "listColumns"
      ],
      "members": {
        "joinColumns": {
          "type": "list",
          "member": {}
        },
        "allowedJoinOperators": {
          "shape": "S2d"
        },
        "listColumns": {
          "shape": "S2f"
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "structure",
      "required": [
        "aggregateColumns",
        "joinColumns",
        "dimensionColumns",
        "scalarFunctions",
        "outputConstraints"
      ],
      "members": {
        "aggregateColumns": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "columnNames",
              "function"
            ],
            "members": {
              "columnNames": {
                "type": "list",
                "member": {}
              },
              "function": {}
            }
          }
        },
        "joinColumns": {
          "shape": "S2f"
        },
        "joinRequired": {},
        "allowedJoinOperators": {
          "shape": "S2d"
        },
        "dimensionColumns": {
          "shape": "S2f"
        },
        "scalarFunctions": {
          "type": "list",
          "member": {}
        },
        "outputConstraints": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "columnName",
              "minimum",
              "type"
            ],
            "members": {
              "columnName": {},
              "minimum": {
                "type": "integer"
              },
              "type": {}
            }
          }
        }
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "allowedAnalyses"
      ],
      "members": {
        "allowedAnalyses": {
          "type": "list",
          "member": {}
        },
        "allowedAnalysisProviders": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2x": {
      "type": "structure",
      "required": [
        "configuredTableId",
        "configuredTableArn",
        "policy",
        "type",
        "createTime",
        "updateTime"
      ],
      "members": {
        "configuredTableId": {},
        "configuredTableArn": {},
        "policy": {
          "shape": "S28"
        },
        "type": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        }
      }
    },
    "S31": {
      "type": "structure",
      "required": [
        "arn",
        "id",
        "configuredTableId",
        "configuredTableArn",
        "membershipId",
        "membershipArn",
        "roleArn",
        "name",
        "createTime",
        "updateTime"
      ],
      "members": {
        "arn": {},
        "id": {},
        "configuredTableId": {},
        "configuredTableArn": {},
        "membershipId": {},
        "membershipArn": {},
        "roleArn": {},
        "name": {},
        "description": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        }
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "id",
        "arn",
        "collaborationArn",
        "collaborationId",
        "collaborationCreatorAccountId",
        "collaborationCreatorDisplayName",
        "collaborationName",
        "createTime",
        "updateTime",
        "status",
        "memberAbilities",
        "queryLogStatus"
      ],
      "members": {
        "id": {},
        "arn": {},
        "collaborationArn": {},
        "collaborationId": {},
        "collaborationCreatorAccountId": {},
        "collaborationCreatorDisplayName": {},
        "collaborationName": {},
        "createTime": {
          "type": "timestamp"
        },
        "updateTime": {
          "type": "timestamp"
        },
        "status": {},
        "memberAbilities": {
          "shape": "S1k"
        },
        "queryLogStatus": {}
      }
    },
    "S44": {
      "type": "structure",
      "required": [
        "id",
        "membershipId",
        "membershipArn",
        "createTime",
        "sqlParameters",
        "status",
        "resultConfiguration"
      ],
      "members": {
        "id": {},
        "membershipId": {},
        "membershipArn": {},
        "createTime": {
          "type": "timestamp"
        },
        "sqlParameters": {
          "shape": "S45"
        },
        "status": {},
        "resultConfiguration": {
          "shape": "S49"
        },
        "statistics": {
          "type": "structure",
          "members": {
            "totalDurationInMillis": {
              "type": "long"
            }
          }
        },
        "result": {
          "type": "structure",
          "required": [
            "output"
          ],
          "members": {
            "output": {
              "type": "structure",
              "members": {
                "s3": {
                  "type": "structure",
                  "required": [
                    "location"
                  ],
                  "members": {
                    "location": {}
                  }
                }
              },
              "union": true
            }
          }
        },
        "error": {
          "type": "structure",
          "required": [
            "message",
            "code"
          ],
          "members": {
            "message": {},
            "code": {}
          }
        }
      }
    },
    "S45": {
      "type": "structure",
      "members": {
        "queryString": {},
        "analysisTemplateArn": {},
        "parameters": {
          "type": "map",
          "key": {},
          "value": {}
        }
      },
      "sensitive": true
    },
    "S49": {
      "type": "structure",
      "required": [
        "outputConfiguration"
      ],
      "members": {
        "outputConfiguration": {
          "type": "structure",
          "members": {
            "s3": {
              "type": "structure",
              "required": [
                "resultFormat",
                "bucket"
              ],
              "members": {
                "resultFormat": {},
                "bucket": {},
                "keyPrefix": {}
              }
            }
          },
          "union": true
        }
      }
    }
  }
}