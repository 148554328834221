{
  "pagination": {
    "ListPipes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "Limit",
      "result_key": "Pipes"
    }
  }
}
