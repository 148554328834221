{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2020-07-14",
    "endpointPrefix": "ivsrealtime",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "ivsrealtime",
    "serviceFullName": "Amazon Interactive Video Service RealTime",
    "serviceId": "IVS RealTime",
    "signatureVersion": "v4",
    "signingName": "ivs",
    "uid": "ivs-realtime-2020-07-14"
  },
  "operations": {
    "CreateParticipantToken": {
      "http": {
        "requestUri": "/CreateParticipantToken",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "stageArn"
        ],
        "members": {
          "attributes": {
            "shape": "S2"
          },
          "capabilities": {
            "shape": "S4"
          },
          "duration": {
            "type": "integer"
          },
          "stageArn": {},
          "userId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "participantToken": {
            "shape": "Sa"
          }
        }
      }
    },
    "CreateStage": {
      "http": {
        "requestUri": "/CreateStage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "name": {},
          "participantTokenConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "attributes": {
                  "shape": "S2"
                },
                "capabilities": {
                  "shape": "S4"
                },
                "duration": {
                  "type": "integer"
                },
                "userId": {}
              }
            }
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "participantTokens": {
            "type": "list",
            "member": {
              "shape": "Sa"
            }
          },
          "stage": {
            "shape": "Sn"
          }
        }
      }
    },
    "DeleteStage": {
      "http": {
        "requestUri": "/DeleteStage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisconnectParticipant": {
      "http": {
        "requestUri": "/DisconnectParticipant",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "participantId",
          "stageArn"
        ],
        "members": {
          "participantId": {},
          "reason": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetParticipant": {
      "http": {
        "requestUri": "/GetParticipant",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "participantId",
          "sessionId",
          "stageArn"
        ],
        "members": {
          "participantId": {},
          "sessionId": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "participant": {
            "type": "structure",
            "members": {
              "attributes": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "firstJoinTime": {
                "shape": "Sz"
              },
              "participantId": {},
              "published": {
                "type": "boolean"
              },
              "state": {},
              "userId": {}
            }
          }
        }
      }
    },
    "GetStage": {
      "http": {
        "requestUri": "/GetStage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stage": {
            "shape": "Sn"
          }
        }
      }
    },
    "GetStageSession": {
      "http": {
        "requestUri": "/GetStageSession",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "stageArn"
        ],
        "members": {
          "sessionId": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stageSession": {
            "type": "structure",
            "members": {
              "endTime": {
                "shape": "Sz"
              },
              "sessionId": {},
              "startTime": {
                "shape": "Sz"
              }
            }
          }
        }
      }
    },
    "ListParticipantEvents": {
      "http": {
        "requestUri": "/ListParticipantEvents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "participantId",
          "sessionId",
          "stageArn"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "participantId": {},
          "sessionId": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "events"
        ],
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "errorCode": {},
                "eventTime": {
                  "shape": "Sz"
                },
                "name": {},
                "participantId": {},
                "remoteParticipantId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListParticipants": {
      "http": {
        "requestUri": "/ListParticipants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "sessionId",
          "stageArn"
        ],
        "members": {
          "filterByPublished": {
            "type": "boolean"
          },
          "filterByState": {},
          "filterByUserId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "sessionId": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "participants"
        ],
        "members": {
          "nextToken": {},
          "participants": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "firstJoinTime": {
                  "shape": "Sz"
                },
                "participantId": {},
                "published": {
                  "type": "boolean"
                },
                "state": {},
                "userId": {}
              }
            }
          }
        }
      }
    },
    "ListStageSessions": {
      "http": {
        "requestUri": "/ListStageSessions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "stageArn"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "stageArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "stageSessions"
        ],
        "members": {
          "nextToken": {},
          "stageSessions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "endTime": {
                  "shape": "Sz"
                },
                "sessionId": {},
                "startTime": {
                  "shape": "Sz"
                }
              }
            }
          }
        }
      }
    },
    "ListStages": {
      "http": {
        "requestUri": "/ListStages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "stages"
        ],
        "members": {
          "nextToken": {},
          "stages": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn"
              ],
              "members": {
                "activeSessionId": {},
                "arn": {},
                "name": {},
                "tags": {
                  "shape": "Si"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "Si"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateStage": {
      "http": {
        "requestUri": "/UpdateStage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "stage": {
            "shape": "Sn"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "members": {
        "attributes": {
          "shape": "S2"
        },
        "capabilities": {
          "shape": "S4"
        },
        "duration": {
          "type": "integer"
        },
        "expirationTime": {
          "type": "timestamp",
          "timestampFormat": "iso8601"
        },
        "participantId": {},
        "token": {
          "type": "string",
          "sensitive": true
        },
        "userId": {}
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sn": {
      "type": "structure",
      "required": [
        "arn"
      ],
      "members": {
        "activeSessionId": {},
        "arn": {},
        "name": {},
        "tags": {
          "shape": "Si"
        }
      }
    },
    "Sz": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}