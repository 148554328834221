{
  "pagination": {
    "DescribeApplicableIndividualAssessments": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeCertificates": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeConnections": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeDataProviders": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpointSettings": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpointTypes": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEndpoints": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEngineVersions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeExtensionPackAssociations": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeFleetAdvisorCollectors": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeFleetAdvisorDatabases": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeFleetAdvisorLsaAnalysis": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeFleetAdvisorSchemaObjectSummary": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeFleetAdvisorSchemas": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeInstanceProfiles": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMetadataModelAssessments": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMetadataModelConversions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMetadataModelExportsAsScript": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMetadataModelExportsToTarget": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMetadataModelImports": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeMigrationProjects": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeOrderableReplicationInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribePendingMaintenanceActions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeRecommendationLimitations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeRecommendations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationConfigs": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationInstanceTaskLogs": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationSubnetGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTableStatistics": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskAssessmentResults": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskAssessmentRuns": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTaskIndividualAssessments": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplicationTasks": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeReplications": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeSchemas": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    },
    "DescribeTableStatistics": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords"
    }
  }
}
