{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "kinesisvideo",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Kinesis Video WebRTC Storage",
    "serviceId": "Kinesis Video WebRTC Storage",
    "signatureVersion": "v4",
    "signingName": "kinesisvideo",
    "uid": "kinesis-video-webrtc-storage-2018-05-10"
  },
  "operations": {
    "JoinStorageSession": {
      "http": {
        "requestUri": "/joinStorageSession",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "channelArn"
        ],
        "members": {
          "channelArn": {}
        }
      }
    }
  },
  "shapes": {}
}