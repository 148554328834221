{
  "pagination": {
    "DescribeClusterDbRevisions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterDbRevisions"
    },
    "DescribeClusterParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ParameterGroups"
    },
    "DescribeClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeClusterSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterSecurityGroups"
    },
    "DescribeClusterSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Snapshots"
    },
    "DescribeClusterSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterSubnetGroups"
    },
    "DescribeClusterTracks": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "MaintenanceTracks"
    },
    "DescribeClusterVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ClusterVersions"
    },
    "DescribeClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Clusters"
    },
    "DescribeCustomDomainAssociations": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Associations"
    },
    "DescribeDataShares": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DataShares"
    },
    "DescribeDataSharesForConsumer": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DataShares"
    },
    "DescribeDataSharesForProducer": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DataShares"
    },
    "DescribeDefaultClusterParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "DefaultClusterParameters.Marker",
      "result_key": "DefaultClusterParameters.Parameters"
    },
    "DescribeEndpointAccess": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EndpointAccessList"
    },
    "DescribeEndpointAuthorization": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EndpointAuthorizationList"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeHsmClientCertificates": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "HsmClientCertificates"
    },
    "DescribeHsmConfigurations": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "HsmConfigurations"
    },
    "DescribeNodeConfigurationOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "NodeConfigurationOptionList"
    },
    "DescribeOrderableClusterOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableClusterOptions"
    },
    "DescribeReservedNodeExchangeStatus": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodeExchangeStatusDetails"
    },
    "DescribeReservedNodeOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodeOfferings"
    },
    "DescribeReservedNodes": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodes"
    },
    "DescribeScheduledActions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ScheduledActions"
    },
    "DescribeSnapshotCopyGrants": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "SnapshotCopyGrants"
    },
    "DescribeSnapshotSchedules": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "SnapshotSchedules"
    },
    "DescribeTableRestoreStatus": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "TableRestoreStatusDetails"
    },
    "DescribeTags": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "TaggedResources"
    },
    "DescribeUsageLimits": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "UsageLimits"
    },
    "GetReservedNodeExchangeConfigurationOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodeConfigurationOptionList"
    },
    "GetReservedNodeExchangeOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedNodeOfferings"
    }
  }
}