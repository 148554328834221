{
  "pagination": {
    "ListDomains": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "DomainSummaries"
    },
    "ListFraudsterRegistrationJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobSummaries"
    },
    "ListFraudsters": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "FraudsterSummaries"
    },
    "ListSpeakerEnrollmentJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "JobSummaries"
    },
    "ListSpeakers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SpeakerSummaries"
    },
    "ListWatchlists": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "WatchlistSummaries"
    }
  }
}
