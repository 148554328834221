{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2022-11-28",
    "endpointPrefix": "docdb-elastic",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "DocDB Elastic",
    "serviceFullName": "Amazon DocumentDB Elastic Clusters",
    "serviceId": "DocDB Elastic",
    "signatureVersion": "v4",
    "signingName": "docdb-elastic",
    "uid": "docdb-elastic-2022-11-28"
  },
  "operations": {
    "CreateCluster": {
      "http": {
        "requestUri": "/cluster",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "adminUserName",
          "adminUserPassword",
          "authType",
          "clusterName",
          "shardCapacity",
          "shardCount"
        ],
        "members": {
          "adminUserName": {},
          "adminUserPassword": {
            "shape": "S3"
          },
          "authType": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "clusterName": {},
          "kmsKeyId": {},
          "preferredMaintenanceWindow": {},
          "shardCapacity": {
            "type": "integer"
          },
          "shardCount": {
            "type": "integer"
          },
          "subnetIds": {
            "shape": "S6"
          },
          "tags": {
            "shape": "S7"
          },
          "vpcSecurityGroupIds": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "CreateClusterSnapshot": {
      "http": {
        "requestUri": "/cluster-snapshot",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterArn",
          "snapshotName"
        ],
        "members": {
          "clusterArn": {},
          "snapshotName": {},
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "snapshot"
        ],
        "members": {
          "snapshot": {
            "shape": "Sg"
          }
        }
      },
      "idempotent": true
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cluster/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterArn"
        ],
        "members": {
          "clusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "DeleteClusterSnapshot": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cluster-snapshot/{snapshotArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "snapshotArn"
        ],
        "members": {
          "snapshotArn": {
            "location": "uri",
            "locationName": "snapshotArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "snapshot"
        ],
        "members": {
          "snapshot": {
            "shape": "Sg"
          }
        }
      },
      "idempotent": true
    },
    "GetCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/cluster/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterArn"
        ],
        "members": {
          "clusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetClusterSnapshot": {
      "http": {
        "method": "GET",
        "requestUri": "/cluster-snapshot/{snapshotArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "snapshotArn"
        ],
        "members": {
          "snapshotArn": {
            "location": "uri",
            "locationName": "snapshotArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "snapshot"
        ],
        "members": {
          "snapshot": {
            "shape": "Sg"
          }
        }
      }
    },
    "ListClusterSnapshots": {
      "http": {
        "method": "GET",
        "requestUri": "/cluster-snapshots",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "clusterArn": {
            "location": "querystring",
            "locationName": "clusterArn"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "snapshots": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "clusterArn",
                "snapshotArn",
                "snapshotCreationTime",
                "snapshotName",
                "status"
              ],
              "members": {
                "clusterArn": {},
                "snapshotArn": {},
                "snapshotCreationTime": {},
                "snapshotName": {},
                "status": {}
              }
            }
          }
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "clusterArn",
                "clusterName",
                "status"
              ],
              "members": {
                "clusterArn": {},
                "clusterName": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S7"
          }
        }
      }
    },
    "RestoreClusterFromSnapshot": {
      "http": {
        "requestUri": "/cluster-snapshot/{snapshotArn}/restore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "snapshotArn"
        ],
        "members": {
          "clusterName": {},
          "kmsKeyId": {},
          "snapshotArn": {
            "location": "uri",
            "locationName": "snapshotArn"
          },
          "subnetIds": {
            "shape": "S6"
          },
          "tags": {
            "shape": "S7"
          },
          "vpcSecurityGroupIds": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateCluster": {
      "http": {
        "method": "PUT",
        "requestUri": "/cluster/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterArn"
        ],
        "members": {
          "adminUserPassword": {
            "shape": "S3"
          },
          "authType": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "clusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "preferredMaintenanceWindow": {},
          "shardCapacity": {
            "type": "integer"
          },
          "shardCount": {
            "type": "integer"
          },
          "subnetIds": {
            "shape": "S6"
          },
          "vpcSecurityGroupIds": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {
            "shape": "Sb"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sb": {
      "type": "structure",
      "required": [
        "adminUserName",
        "authType",
        "clusterArn",
        "clusterEndpoint",
        "clusterName",
        "createTime",
        "kmsKeyId",
        "preferredMaintenanceWindow",
        "shardCapacity",
        "shardCount",
        "status",
        "subnetIds",
        "vpcSecurityGroupIds"
      ],
      "members": {
        "adminUserName": {},
        "authType": {},
        "clusterArn": {},
        "clusterEndpoint": {},
        "clusterName": {},
        "createTime": {},
        "kmsKeyId": {},
        "preferredMaintenanceWindow": {},
        "shardCapacity": {
          "type": "integer"
        },
        "shardCount": {
          "type": "integer"
        },
        "status": {},
        "subnetIds": {
          "shape": "S6"
        },
        "vpcSecurityGroupIds": {
          "shape": "S6"
        }
      }
    },
    "Sg": {
      "type": "structure",
      "required": [
        "adminUserName",
        "clusterArn",
        "clusterCreationTime",
        "kmsKeyId",
        "snapshotArn",
        "snapshotCreationTime",
        "snapshotName",
        "status",
        "subnetIds",
        "vpcSecurityGroupIds"
      ],
      "members": {
        "adminUserName": {},
        "clusterArn": {},
        "clusterCreationTime": {},
        "kmsKeyId": {},
        "snapshotArn": {},
        "snapshotCreationTime": {},
        "snapshotName": {},
        "status": {},
        "subnetIds": {
          "shape": "S6"
        },
        "vpcSecurityGroupIds": {
          "shape": "S6"
        }
      }
    }
  }
}