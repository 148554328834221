{
  "pagination": {
    "GetFindings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findings"
    },
    "ListFindingsMetrics": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findingsMetrics"
    },
    "ListScans": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "summaries"
    }
  }
}
