{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2022-06-10",
    "endpointPrefix": "oam",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "CloudWatch Observability Access Manager",
    "serviceId": "OAM",
    "signatureVersion": "v4",
    "signingName": "oam",
    "uid": "oam-2022-06-10"
  },
  "operations": {
    "CreateLink": {
      "http": {
        "requestUri": "/CreateLink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LabelTemplate",
          "ResourceTypes",
          "SinkIdentifier"
        ],
        "members": {
          "LabelTemplate": {},
          "ResourceTypes": {
            "shape": "S3"
          },
          "SinkIdentifier": {},
          "Tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Label": {},
          "LabelTemplate": {},
          "ResourceTypes": {
            "shape": "Sb"
          },
          "SinkArn": {},
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "CreateSink": {
      "http": {
        "requestUri": "/CreateSink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Name": {},
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "DeleteLink": {
      "http": {
        "requestUri": "/DeleteLink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSink": {
      "http": {
        "requestUri": "/DeleteSink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetLink": {
      "http": {
        "requestUri": "/GetLink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Label": {},
          "LabelTemplate": {},
          "ResourceTypes": {
            "shape": "Sb"
          },
          "SinkArn": {},
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetSink": {
      "http": {
        "requestUri": "/GetSink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier"
        ],
        "members": {
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Name": {},
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetSinkPolicy": {
      "http": {
        "requestUri": "/GetSinkPolicy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SinkIdentifier"
        ],
        "members": {
          "SinkIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SinkArn": {},
          "SinkId": {},
          "Policy": {}
        }
      }
    },
    "ListAttachedLinks": {
      "http": {
        "requestUri": "/ListAttachedLinks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SinkIdentifier"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SinkIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Items"
        ],
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Label": {},
                "LinkArn": {},
                "ResourceTypes": {
                  "shape": "Sb"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLinks": {
      "http": {
        "requestUri": "/ListLinks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Items"
        ],
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Id": {},
                "Label": {},
                "ResourceTypes": {
                  "shape": "Sb"
                },
                "SinkArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSinks": {
      "http": {
        "requestUri": "/ListSinks",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Items"
        ],
        "members": {
          "Items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Id": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "PutSinkPolicy": {
      "http": {
        "requestUri": "/PutSinkPolicy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "SinkIdentifier",
          "Policy"
        ],
        "members": {
          "SinkIdentifier": {},
          "Policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SinkArn": {},
          "SinkId": {},
          "Policy": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLink": {
      "http": {
        "requestUri": "/UpdateLink",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Identifier",
          "ResourceTypes"
        ],
        "members": {
          "Identifier": {},
          "ResourceTypes": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Id": {},
          "Label": {},
          "LabelTemplate": {},
          "ResourceTypes": {
            "shape": "Sb"
          },
          "SinkArn": {},
          "Tags": {
            "shape": "Sc"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sc": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}