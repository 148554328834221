{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-10-07",
    "endpointPrefix": "pipes",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon EventBridge Pipes",
    "serviceId": "Pipes",
    "signatureVersion": "v4",
    "signingName": "pipes",
    "uid": "pipes-2015-10-07"
  },
  "operations": {
    "CreatePipe": {
      "http": {
        "requestUri": "/v1/pipes/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoleArn",
          "Source",
          "Target"
        ],
        "members": {
          "Description": {
            "shape": "S2"
          },
          "DesiredState": {},
          "Enrichment": {},
          "EnrichmentParameters": {
            "shape": "S5"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "RoleArn": {},
          "Source": {},
          "SourceParameters": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "S1m"
          },
          "Target": {},
          "TargetParameters": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "DesiredState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {}
        }
      },
      "idempotent": true
    },
    "DeletePipe": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/pipes/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "DesiredState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {}
        }
      },
      "idempotent": true
    },
    "DescribePipe": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/pipes/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "Description": {
            "shape": "S2"
          },
          "DesiredState": {},
          "Enrichment": {},
          "EnrichmentParameters": {
            "shape": "S5"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {},
          "RoleArn": {},
          "Source": {},
          "SourceParameters": {
            "shape": "Sj"
          },
          "StateReason": {},
          "Tags": {
            "shape": "S1m"
          },
          "Target": {},
          "TargetParameters": {
            "shape": "S1p"
          }
        }
      }
    },
    "ListPipes": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/pipes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "CurrentState": {
            "location": "querystring",
            "locationName": "CurrentState"
          },
          "DesiredState": {
            "location": "querystring",
            "locationName": "DesiredState"
          },
          "Limit": {
            "location": "querystring",
            "locationName": "Limit",
            "type": "integer"
          },
          "NamePrefix": {
            "location": "querystring",
            "locationName": "NamePrefix"
          },
          "NextToken": {
            "shape": "S4k",
            "location": "querystring",
            "locationName": "NextToken"
          },
          "SourcePrefix": {
            "location": "querystring",
            "locationName": "SourcePrefix"
          },
          "TargetPrefix": {
            "location": "querystring",
            "locationName": "TargetPrefix"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "shape": "S4k"
          },
          "Pipes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CurrentState": {},
                "DesiredState": {},
                "Enrichment": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "Name": {},
                "Source": {},
                "StateReason": {},
                "Target": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1m"
          }
        }
      }
    },
    "StartPipe": {
      "http": {
        "requestUri": "/v1/pipes/{Name}/start",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "DesiredState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {}
        }
      }
    },
    "StopPipe": {
      "http": {
        "requestUri": "/v1/pipes/{Name}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "DesiredState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdatePipe": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/pipes/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoleArn"
        ],
        "members": {
          "Description": {
            "shape": "S2"
          },
          "DesiredState": {},
          "Enrichment": {},
          "EnrichmentParameters": {
            "shape": "S5"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "RoleArn": {},
          "SourceParameters": {
            "type": "structure",
            "members": {
              "ActiveMQBrokerParameters": {
                "type": "structure",
                "required": [
                  "Credentials"
                ],
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "Credentials": {
                    "shape": "Sm"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  }
                }
              },
              "DynamoDBStreamParameters": {
                "type": "structure",
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "DeadLetterConfig": {
                    "shape": "Sr"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  },
                  "MaximumRecordAgeInSeconds": {
                    "type": "integer"
                  },
                  "MaximumRetryAttempts": {
                    "type": "integer"
                  },
                  "OnPartialBatchItemFailure": {},
                  "ParallelizationFactor": {
                    "type": "integer"
                  }
                }
              },
              "FilterCriteria": {
                "shape": "Sy"
              },
              "KinesisStreamParameters": {
                "type": "structure",
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "DeadLetterConfig": {
                    "shape": "Sr"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  },
                  "MaximumRecordAgeInSeconds": {
                    "type": "integer"
                  },
                  "MaximumRetryAttempts": {
                    "type": "integer"
                  },
                  "OnPartialBatchItemFailure": {},
                  "ParallelizationFactor": {
                    "type": "integer"
                  }
                }
              },
              "ManagedStreamingKafkaParameters": {
                "type": "structure",
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "Credentials": {
                    "shape": "S17"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  }
                }
              },
              "RabbitMQBrokerParameters": {
                "type": "structure",
                "required": [
                  "Credentials"
                ],
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "Credentials": {
                    "shape": "Sm"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  }
                }
              },
              "SelfManagedKafkaParameters": {
                "type": "structure",
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "Credentials": {
                    "shape": "S1e"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  },
                  "ServerRootCaCertificate": {},
                  "Vpc": {
                    "shape": "S1g"
                  }
                }
              },
              "SqsQueueParameters": {
                "type": "structure",
                "members": {
                  "BatchSize": {
                    "type": "integer"
                  },
                  "MaximumBatchingWindowInSeconds": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "Target": {},
          "TargetParameters": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CurrentState": {},
          "DesiredState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Name": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S2": {
      "type": "string",
      "sensitive": true
    },
    "S5": {
      "type": "structure",
      "members": {
        "HttpParameters": {
          "type": "structure",
          "members": {
            "HeaderParameters": {
              "shape": "S7"
            },
            "PathParameterValues": {
              "shape": "Sa"
            },
            "QueryStringParameters": {
              "shape": "Sc"
            }
          }
        },
        "InputTemplate": {
          "shape": "Sf"
        }
      }
    },
    "S7": {
      "type": "map",
      "key": {},
      "value": {
        "type": "string",
        "sensitive": true
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "string",
        "sensitive": true
      }
    },
    "Sc": {
      "type": "map",
      "key": {},
      "value": {
        "type": "string",
        "sensitive": true
      }
    },
    "Sf": {
      "type": "string",
      "sensitive": true
    },
    "Sj": {
      "type": "structure",
      "members": {
        "ActiveMQBrokerParameters": {
          "type": "structure",
          "required": [
            "Credentials",
            "QueueName"
          ],
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "Credentials": {
              "shape": "Sm"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "QueueName": {
              "shape": "Sp"
            }
          }
        },
        "DynamoDBStreamParameters": {
          "type": "structure",
          "required": [
            "StartingPosition"
          ],
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "DeadLetterConfig": {
              "shape": "Sr"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "MaximumRecordAgeInSeconds": {
              "type": "integer"
            },
            "MaximumRetryAttempts": {
              "type": "integer"
            },
            "OnPartialBatchItemFailure": {},
            "ParallelizationFactor": {
              "type": "integer"
            },
            "StartingPosition": {}
          }
        },
        "FilterCriteria": {
          "shape": "Sy"
        },
        "KinesisStreamParameters": {
          "type": "structure",
          "required": [
            "StartingPosition"
          ],
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "DeadLetterConfig": {
              "shape": "Sr"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "MaximumRecordAgeInSeconds": {
              "type": "integer"
            },
            "MaximumRetryAttempts": {
              "type": "integer"
            },
            "OnPartialBatchItemFailure": {},
            "ParallelizationFactor": {
              "type": "integer"
            },
            "StartingPosition": {},
            "StartingPositionTimestamp": {
              "type": "timestamp"
            }
          }
        },
        "ManagedStreamingKafkaParameters": {
          "type": "structure",
          "required": [
            "TopicName"
          ],
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "ConsumerGroupID": {
              "shape": "S16"
            },
            "Credentials": {
              "shape": "S17"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "StartingPosition": {},
            "TopicName": {
              "shape": "S19"
            }
          }
        },
        "RabbitMQBrokerParameters": {
          "type": "structure",
          "required": [
            "Credentials",
            "QueueName"
          ],
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "Credentials": {
              "shape": "Sm"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "QueueName": {
              "shape": "Sp"
            },
            "VirtualHost": {
              "shape": "S16"
            }
          }
        },
        "SelfManagedKafkaParameters": {
          "type": "structure",
          "required": [
            "TopicName"
          ],
          "members": {
            "AdditionalBootstrapServers": {
              "type": "list",
              "member": {
                "type": "string",
                "sensitive": true
              }
            },
            "BatchSize": {
              "type": "integer"
            },
            "ConsumerGroupID": {
              "shape": "S16"
            },
            "Credentials": {
              "shape": "S1e"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            },
            "ServerRootCaCertificate": {},
            "StartingPosition": {},
            "TopicName": {
              "shape": "S19"
            },
            "Vpc": {
              "shape": "S1g"
            }
          }
        },
        "SqsQueueParameters": {
          "type": "structure",
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "MaximumBatchingWindowInSeconds": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "BasicAuth": {}
      },
      "union": true
    },
    "Sp": {
      "type": "string",
      "sensitive": true
    },
    "Sr": {
      "type": "structure",
      "members": {
        "Arn": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "Filters": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Pattern": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "S16": {
      "type": "string",
      "sensitive": true
    },
    "S17": {
      "type": "structure",
      "members": {
        "ClientCertificateTlsAuth": {},
        "SaslScram512Auth": {}
      },
      "union": true
    },
    "S19": {
      "type": "string",
      "sensitive": true
    },
    "S1e": {
      "type": "structure",
      "members": {
        "BasicAuth": {},
        "ClientCertificateTlsAuth": {},
        "SaslScram256Auth": {},
        "SaslScram512Auth": {}
      },
      "union": true
    },
    "S1g": {
      "type": "structure",
      "members": {
        "SecurityGroup": {
          "type": "list",
          "member": {
            "type": "string",
            "sensitive": true
          }
        },
        "Subnets": {
          "type": "list",
          "member": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "S1m": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S1o"
      }
    },
    "S1o": {
      "type": "string",
      "sensitive": true
    },
    "S1p": {
      "type": "structure",
      "members": {
        "BatchJobParameters": {
          "type": "structure",
          "required": [
            "JobDefinition",
            "JobName"
          ],
          "members": {
            "ArrayProperties": {
              "type": "structure",
              "members": {
                "Size": {
                  "type": "integer"
                }
              }
            },
            "ContainerOverrides": {
              "type": "structure",
              "members": {
                "Command": {
                  "shape": "S1u"
                },
                "Environment": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Value": {}
                    }
                  }
                },
                "InstanceType": {},
                "ResourceRequirements": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Type",
                      "Value"
                    ],
                    "members": {
                      "Type": {},
                      "Value": {}
                    }
                  }
                }
              }
            },
            "DependsOn": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "JobId": {},
                  "Type": {}
                }
              }
            },
            "JobDefinition": {},
            "JobName": {},
            "Parameters": {
              "type": "map",
              "key": {},
              "value": {}
            },
            "RetryStrategy": {
              "type": "structure",
              "members": {
                "Attempts": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "CloudWatchLogsParameters": {
          "type": "structure",
          "members": {
            "LogStreamName": {},
            "Timestamp": {}
          }
        },
        "EcsTaskParameters": {
          "type": "structure",
          "required": [
            "TaskDefinitionArn"
          ],
          "members": {
            "CapacityProviderStrategy": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "capacityProvider"
                ],
                "members": {
                  "base": {
                    "type": "integer"
                  },
                  "capacityProvider": {
                    "type": "string",
                    "sensitive": true
                  },
                  "weight": {
                    "type": "integer"
                  }
                }
              }
            },
            "EnableECSManagedTags": {
              "type": "boolean"
            },
            "EnableExecuteCommand": {
              "type": "boolean"
            },
            "Group": {},
            "LaunchType": {},
            "NetworkConfiguration": {
              "type": "structure",
              "members": {
                "awsvpcConfiguration": {
                  "type": "structure",
                  "required": [
                    "Subnets"
                  ],
                  "members": {
                    "AssignPublicIp": {},
                    "SecurityGroups": {
                      "type": "list",
                      "member": {
                        "type": "string",
                        "sensitive": true
                      }
                    },
                    "Subnets": {
                      "type": "list",
                      "member": {
                        "type": "string",
                        "sensitive": true
                      }
                    }
                  }
                }
              }
            },
            "Overrides": {
              "type": "structure",
              "members": {
                "ContainerOverrides": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Command": {
                        "shape": "S1u"
                      },
                      "Cpu": {
                        "type": "integer"
                      },
                      "Environment": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "name": {},
                            "value": {}
                          }
                        }
                      },
                      "EnvironmentFiles": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "required": [
                            "type",
                            "value"
                          ],
                          "members": {
                            "type": {},
                            "value": {}
                          }
                        }
                      },
                      "Memory": {
                        "type": "integer"
                      },
                      "MemoryReservation": {
                        "type": "integer"
                      },
                      "Name": {},
                      "ResourceRequirements": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "required": [
                            "type",
                            "value"
                          ],
                          "members": {
                            "type": {},
                            "value": {}
                          }
                        }
                      }
                    }
                  }
                },
                "Cpu": {},
                "EphemeralStorage": {
                  "type": "structure",
                  "required": [
                    "sizeInGiB"
                  ],
                  "members": {
                    "sizeInGiB": {
                      "type": "integer"
                    }
                  }
                },
                "ExecutionRoleArn": {},
                "InferenceAcceleratorOverrides": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "deviceName": {},
                      "deviceType": {}
                    }
                  }
                },
                "Memory": {},
                "TaskRoleArn": {}
              }
            },
            "PlacementConstraints": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "expression": {
                    "type": "string",
                    "sensitive": true
                  },
                  "type": {}
                }
              }
            },
            "PlacementStrategy": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "field": {
                    "type": "string",
                    "sensitive": true
                  },
                  "type": {}
                }
              }
            },
            "PlatformVersion": {},
            "PropagateTags": {},
            "ReferenceId": {
              "type": "string",
              "sensitive": true
            },
            "Tags": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Key",
                  "Value"
                ],
                "members": {
                  "Key": {},
                  "Value": {
                    "shape": "S1o"
                  }
                }
              }
            },
            "TaskCount": {
              "type": "integer"
            },
            "TaskDefinitionArn": {}
          }
        },
        "EventBridgeEventBusParameters": {
          "type": "structure",
          "members": {
            "DetailType": {
              "type": "string",
              "sensitive": true
            },
            "EndpointId": {
              "type": "string",
              "sensitive": true
            },
            "Resources": {
              "type": "list",
              "member": {}
            },
            "Source": {
              "type": "string",
              "sensitive": true
            },
            "Time": {}
          }
        },
        "HttpParameters": {
          "type": "structure",
          "members": {
            "HeaderParameters": {
              "shape": "S7"
            },
            "PathParameterValues": {
              "shape": "Sa"
            },
            "QueryStringParameters": {
              "shape": "Sc"
            }
          }
        },
        "InputTemplate": {
          "shape": "Sf"
        },
        "KinesisStreamParameters": {
          "type": "structure",
          "required": [
            "PartitionKey"
          ],
          "members": {
            "PartitionKey": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "LambdaFunctionParameters": {
          "type": "structure",
          "members": {
            "InvocationType": {}
          }
        },
        "RedshiftDataParameters": {
          "type": "structure",
          "required": [
            "Database",
            "Sqls"
          ],
          "members": {
            "Database": {
              "type": "string",
              "sensitive": true
            },
            "DbUser": {
              "type": "string",
              "sensitive": true
            },
            "SecretManagerArn": {},
            "Sqls": {
              "type": "list",
              "member": {
                "type": "string",
                "sensitive": true
              }
            },
            "StatementName": {
              "type": "string",
              "sensitive": true
            },
            "WithEvent": {
              "type": "boolean"
            }
          }
        },
        "SageMakerPipelineParameters": {
          "type": "structure",
          "members": {
            "PipelineParameterList": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {
                    "type": "string",
                    "sensitive": true
                  },
                  "Value": {
                    "type": "string",
                    "sensitive": true
                  }
                }
              }
            }
          }
        },
        "SqsQueueParameters": {
          "type": "structure",
          "members": {
            "MessageDeduplicationId": {
              "type": "string",
              "sensitive": true
            },
            "MessageGroupId": {
              "type": "string",
              "sensitive": true
            }
          }
        },
        "StepFunctionStateMachineParameters": {
          "type": "structure",
          "members": {
            "InvocationType": {}
          }
        }
      }
    },
    "S1u": {
      "type": "list",
      "member": {}
    },
    "S4k": {
      "type": "string",
      "sensitive": true
    }
  },
  "xmlNamespace": "http://events.amazonaws.com/doc/2015-10-07"
}