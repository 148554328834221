{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-12-02",
    "endpointPrefix": "imagebuilder",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "imagebuilder",
    "serviceFullName": "EC2 Image Builder",
    "serviceId": "imagebuilder",
    "signatureVersion": "v4",
    "signingName": "imagebuilder",
    "uid": "imagebuilder-2019-12-02"
  },
  "operations": {
    "CancelImageCreation": {
      "http": {
        "method": "PUT",
        "requestUri": "/CancelImageCreation"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageBuildVersionArn",
          "clientToken"
        ],
        "members": {
          "imageBuildVersionArn": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imageBuildVersionArn": {}
        }
      }
    },
    "CreateComponent": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateComponent"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "semanticVersion",
          "platform",
          "clientToken"
        ],
        "members": {
          "name": {},
          "semanticVersion": {},
          "description": {},
          "changeDescription": {},
          "platform": {},
          "supportedOsVersions": {
            "shape": "Sa"
          },
          "data": {},
          "uri": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "componentBuildVersionArn": {}
        }
      }
    },
    "CreateContainerRecipe": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateContainerRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "containerType",
          "name",
          "semanticVersion",
          "components",
          "parentImage",
          "targetRepository",
          "clientToken"
        ],
        "members": {
          "containerType": {},
          "name": {},
          "description": {},
          "semanticVersion": {},
          "components": {
            "shape": "Sl"
          },
          "instanceConfiguration": {
            "shape": "St"
          },
          "dockerfileTemplateData": {},
          "dockerfileTemplateUri": {},
          "platformOverride": {},
          "imageOsVersionOverride": {},
          "parentImage": {},
          "tags": {
            "shape": "Se"
          },
          "workingDirectory": {},
          "targetRepository": {
            "shape": "S14"
          },
          "kmsKeyId": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "containerRecipeArn": {}
        }
      }
    },
    "CreateDistributionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateDistributionConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "distributions",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "distributions": {
            "shape": "S19"
          },
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "distributionConfigurationArn": {}
        }
      }
    },
    "CreateImage": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateImage"
      },
      "input": {
        "type": "structure",
        "required": [
          "infrastructureConfigurationArn",
          "clientToken"
        ],
        "members": {
          "imageRecipeArn": {},
          "containerRecipeArn": {},
          "distributionConfigurationArn": {},
          "infrastructureConfigurationArn": {},
          "imageTestsConfiguration": {
            "shape": "S25"
          },
          "enhancedImageMetadataEnabled": {
            "type": "boolean"
          },
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "imageScanningConfiguration": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imageBuildVersionArn": {}
        }
      }
    },
    "CreateImagePipeline": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateImagePipeline"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "infrastructureConfigurationArn",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "imageRecipeArn": {},
          "containerRecipeArn": {},
          "infrastructureConfigurationArn": {},
          "distributionConfigurationArn": {},
          "imageTestsConfiguration": {
            "shape": "S25"
          },
          "enhancedImageMetadataEnabled": {
            "type": "boolean"
          },
          "schedule": {
            "shape": "S2b"
          },
          "status": {},
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "imageScanningConfiguration": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imagePipelineArn": {}
        }
      }
    },
    "CreateImageRecipe": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateImageRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "semanticVersion",
          "components",
          "parentImage",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "semanticVersion": {},
          "components": {
            "shape": "Sl"
          },
          "parentImage": {},
          "blockDeviceMappings": {
            "shape": "Su"
          },
          "tags": {
            "shape": "Se"
          },
          "workingDirectory": {},
          "additionalInstanceConfiguration": {
            "shape": "S2i"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imageRecipeArn": {}
        }
      }
    },
    "CreateInfrastructureConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/CreateInfrastructureConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "instanceProfileName",
          "clientToken"
        ],
        "members": {
          "name": {},
          "description": {},
          "instanceTypes": {
            "shape": "S2n"
          },
          "instanceProfileName": {},
          "securityGroupIds": {
            "shape": "S2q"
          },
          "subnetId": {},
          "logging": {
            "shape": "S2r"
          },
          "keyPair": {},
          "terminateInstanceOnFailure": {
            "type": "boolean"
          },
          "snsTopicArn": {},
          "resourceTags": {
            "shape": "S2u"
          },
          "instanceMetadataOptions": {
            "shape": "S2v"
          },
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "infrastructureConfigurationArn": {}
        }
      }
    },
    "DeleteComponent": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteComponent"
      },
      "input": {
        "type": "structure",
        "required": [
          "componentBuildVersionArn"
        ],
        "members": {
          "componentBuildVersionArn": {
            "location": "querystring",
            "locationName": "componentBuildVersionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "componentBuildVersionArn": {}
        }
      }
    },
    "DeleteContainerRecipe": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteContainerRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "containerRecipeArn"
        ],
        "members": {
          "containerRecipeArn": {
            "location": "querystring",
            "locationName": "containerRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "containerRecipeArn": {}
        }
      }
    },
    "DeleteDistributionConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteDistributionConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "distributionConfigurationArn"
        ],
        "members": {
          "distributionConfigurationArn": {
            "location": "querystring",
            "locationName": "distributionConfigurationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "distributionConfigurationArn": {}
        }
      }
    },
    "DeleteImage": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteImage"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageBuildVersionArn"
        ],
        "members": {
          "imageBuildVersionArn": {
            "location": "querystring",
            "locationName": "imageBuildVersionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageBuildVersionArn": {}
        }
      }
    },
    "DeleteImagePipeline": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteImagePipeline"
      },
      "input": {
        "type": "structure",
        "required": [
          "imagePipelineArn"
        ],
        "members": {
          "imagePipelineArn": {
            "location": "querystring",
            "locationName": "imagePipelineArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imagePipelineArn": {}
        }
      }
    },
    "DeleteImageRecipe": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteImageRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageRecipeArn"
        ],
        "members": {
          "imageRecipeArn": {
            "location": "querystring",
            "locationName": "imageRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageRecipeArn": {}
        }
      }
    },
    "DeleteInfrastructureConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/DeleteInfrastructureConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "infrastructureConfigurationArn"
        ],
        "members": {
          "infrastructureConfigurationArn": {
            "location": "querystring",
            "locationName": "infrastructureConfigurationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "infrastructureConfigurationArn": {}
        }
      }
    },
    "GetComponent": {
      "http": {
        "method": "GET",
        "requestUri": "/GetComponent"
      },
      "input": {
        "type": "structure",
        "required": [
          "componentBuildVersionArn"
        ],
        "members": {
          "componentBuildVersionArn": {
            "location": "querystring",
            "locationName": "componentBuildVersionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "component": {
            "type": "structure",
            "members": {
              "arn": {},
              "name": {},
              "version": {},
              "description": {},
              "changeDescription": {},
              "type": {},
              "platform": {},
              "supportedOsVersions": {
                "shape": "Sa"
              },
              "state": {
                "shape": "S3i"
              },
              "parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "name",
                    "type"
                  ],
                  "members": {
                    "name": {},
                    "type": {},
                    "defaultValue": {
                      "shape": "Sr"
                    },
                    "description": {}
                  }
                }
              },
              "owner": {},
              "data": {},
              "kmsKeyId": {},
              "encrypted": {
                "type": "boolean"
              },
              "dateCreated": {},
              "tags": {
                "shape": "Se"
              },
              "publisher": {},
              "obfuscate": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "GetComponentPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/GetComponentPolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "componentArn"
        ],
        "members": {
          "componentArn": {
            "location": "querystring",
            "locationName": "componentArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "policy": {}
        }
      }
    },
    "GetContainerRecipe": {
      "http": {
        "method": "GET",
        "requestUri": "/GetContainerRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "containerRecipeArn"
        ],
        "members": {
          "containerRecipeArn": {
            "location": "querystring",
            "locationName": "containerRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "containerRecipe": {
            "shape": "S3v"
          }
        }
      }
    },
    "GetContainerRecipePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/GetContainerRecipePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "containerRecipeArn"
        ],
        "members": {
          "containerRecipeArn": {
            "location": "querystring",
            "locationName": "containerRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "policy": {}
        }
      }
    },
    "GetDistributionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/GetDistributionConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "distributionConfigurationArn"
        ],
        "members": {
          "distributionConfigurationArn": {
            "location": "querystring",
            "locationName": "distributionConfigurationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "distributionConfiguration": {
            "shape": "S41"
          }
        }
      }
    },
    "GetImage": {
      "http": {
        "method": "GET",
        "requestUri": "/GetImage"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageBuildVersionArn"
        ],
        "members": {
          "imageBuildVersionArn": {
            "location": "querystring",
            "locationName": "imageBuildVersionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "image": {
            "type": "structure",
            "members": {
              "arn": {},
              "type": {},
              "name": {},
              "version": {},
              "platform": {},
              "enhancedImageMetadataEnabled": {
                "type": "boolean"
              },
              "osVersion": {},
              "state": {
                "shape": "S48"
              },
              "imageRecipe": {
                "shape": "S4a"
              },
              "containerRecipe": {
                "shape": "S3v"
              },
              "sourcePipelineName": {},
              "sourcePipelineArn": {},
              "infrastructureConfiguration": {
                "shape": "S4c"
              },
              "distributionConfiguration": {
                "shape": "S41"
              },
              "imageTestsConfiguration": {
                "shape": "S25"
              },
              "dateCreated": {},
              "outputResources": {
                "shape": "S4d"
              },
              "tags": {
                "shape": "Se"
              },
              "buildType": {},
              "imageSource": {},
              "scanState": {
                "type": "structure",
                "members": {
                  "status": {},
                  "reason": {}
                }
              },
              "imageScanningConfiguration": {
                "shape": "S27"
              }
            }
          }
        }
      }
    },
    "GetImagePipeline": {
      "http": {
        "method": "GET",
        "requestUri": "/GetImagePipeline"
      },
      "input": {
        "type": "structure",
        "required": [
          "imagePipelineArn"
        ],
        "members": {
          "imagePipelineArn": {
            "location": "querystring",
            "locationName": "imagePipelineArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imagePipeline": {
            "shape": "S4o"
          }
        }
      }
    },
    "GetImagePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/GetImagePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageArn"
        ],
        "members": {
          "imageArn": {
            "location": "querystring",
            "locationName": "imageArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "policy": {}
        }
      }
    },
    "GetImageRecipe": {
      "http": {
        "method": "GET",
        "requestUri": "/GetImageRecipe"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageRecipeArn"
        ],
        "members": {
          "imageRecipeArn": {
            "location": "querystring",
            "locationName": "imageRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageRecipe": {
            "shape": "S4a"
          }
        }
      }
    },
    "GetImageRecipePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/GetImageRecipePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageRecipeArn"
        ],
        "members": {
          "imageRecipeArn": {
            "location": "querystring",
            "locationName": "imageRecipeArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "policy": {}
        }
      }
    },
    "GetInfrastructureConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/GetInfrastructureConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "infrastructureConfigurationArn"
        ],
        "members": {
          "infrastructureConfigurationArn": {
            "location": "querystring",
            "locationName": "infrastructureConfigurationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "infrastructureConfiguration": {
            "shape": "S4c"
          }
        }
      }
    },
    "GetWorkflowExecution": {
      "http": {
        "method": "GET",
        "requestUri": "/GetWorkflowExecution"
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowExecutionId"
        ],
        "members": {
          "workflowExecutionId": {
            "location": "querystring",
            "locationName": "workflowExecutionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "workflowBuildVersionArn": {},
          "workflowExecutionId": {},
          "imageBuildVersionArn": {},
          "type": {},
          "status": {},
          "message": {},
          "totalStepCount": {
            "type": "integer"
          },
          "totalStepsSucceeded": {
            "type": "integer"
          },
          "totalStepsFailed": {
            "type": "integer"
          },
          "totalStepsSkipped": {
            "type": "integer"
          },
          "startTime": {},
          "endTime": {}
        }
      }
    },
    "GetWorkflowStepExecution": {
      "http": {
        "method": "GET",
        "requestUri": "/GetWorkflowStepExecution"
      },
      "input": {
        "type": "structure",
        "required": [
          "stepExecutionId"
        ],
        "members": {
          "stepExecutionId": {
            "location": "querystring",
            "locationName": "stepExecutionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "stepExecutionId": {},
          "workflowBuildVersionArn": {},
          "workflowExecutionId": {},
          "imageBuildVersionArn": {},
          "name": {},
          "description": {},
          "action": {},
          "status": {},
          "rollbackStatus": {},
          "message": {},
          "inputs": {},
          "outputs": {},
          "startTime": {},
          "endTime": {},
          "onFailure": {},
          "timeoutSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "ImportComponent": {
      "http": {
        "method": "PUT",
        "requestUri": "/ImportComponent"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "semanticVersion",
          "type",
          "format",
          "platform",
          "clientToken"
        ],
        "members": {
          "name": {},
          "semanticVersion": {},
          "description": {},
          "changeDescription": {},
          "type": {},
          "format": {},
          "platform": {},
          "data": {},
          "uri": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "componentBuildVersionArn": {}
        }
      }
    },
    "ImportVmImage": {
      "http": {
        "method": "PUT",
        "requestUri": "/ImportVmImage"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "semanticVersion",
          "platform",
          "vmImportTaskId",
          "clientToken"
        ],
        "members": {
          "name": {},
          "semanticVersion": {},
          "description": {},
          "platform": {},
          "osVersion": {},
          "vmImportTaskId": {},
          "tags": {
            "shape": "Se"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageArn": {},
          "clientToken": {}
        }
      }
    },
    "ListComponentBuildVersions": {
      "http": {
        "requestUri": "/ListComponentBuildVersions"
      },
      "input": {
        "type": "structure",
        "required": [
          "componentVersionArn"
        ],
        "members": {
          "componentVersionArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "componentSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "version": {},
                "platform": {},
                "supportedOsVersions": {
                  "shape": "Sa"
                },
                "state": {
                  "shape": "S3i"
                },
                "type": {},
                "owner": {},
                "description": {},
                "changeDescription": {},
                "dateCreated": {},
                "tags": {
                  "shape": "Se"
                },
                "publisher": {},
                "obfuscate": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListComponents": {
      "http": {
        "requestUri": "/ListComponents"
      },
      "input": {
        "type": "structure",
        "members": {
          "owner": {},
          "filters": {
            "shape": "S5v"
          },
          "byName": {
            "type": "boolean"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "componentVersionList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "version": {},
                "description": {},
                "platform": {},
                "supportedOsVersions": {
                  "shape": "Sa"
                },
                "type": {},
                "owner": {},
                "dateCreated": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListContainerRecipes": {
      "http": {
        "requestUri": "/ListContainerRecipes"
      },
      "input": {
        "type": "structure",
        "members": {
          "owner": {},
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "containerRecipeSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "containerType": {},
                "name": {},
                "platform": {},
                "owner": {},
                "parentImage": {},
                "dateCreated": {},
                "tags": {
                  "shape": "Se"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDistributionConfigurations": {
      "http": {
        "requestUri": "/ListDistributionConfigurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "distributionConfigurationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "description": {},
                "dateCreated": {},
                "dateUpdated": {},
                "tags": {
                  "shape": "Se"
                },
                "regions": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImageBuildVersions": {
      "http": {
        "requestUri": "/ListImageBuildVersions"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageVersionArn"
        ],
        "members": {
          "imageVersionArn": {},
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageSummaryList": {
            "shape": "S6f"
          },
          "nextToken": {}
        }
      }
    },
    "ListImagePackages": {
      "http": {
        "requestUri": "/ListImagePackages"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageBuildVersionArn"
        ],
        "members": {
          "imageBuildVersionArn": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imagePackageList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "packageName": {},
                "packageVersion": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImagePipelineImages": {
      "http": {
        "requestUri": "/ListImagePipelineImages"
      },
      "input": {
        "type": "structure",
        "required": [
          "imagePipelineArn"
        ],
        "members": {
          "imagePipelineArn": {},
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageSummaryList": {
            "shape": "S6f"
          },
          "nextToken": {}
        }
      }
    },
    "ListImagePipelines": {
      "http": {
        "requestUri": "/ListImagePipelines"
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imagePipelineList": {
            "type": "list",
            "member": {
              "shape": "S4o"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImageRecipes": {
      "http": {
        "requestUri": "/ListImageRecipes"
      },
      "input": {
        "type": "structure",
        "members": {
          "owner": {},
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageRecipeSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "platform": {},
                "owner": {},
                "parentImage": {},
                "dateCreated": {},
                "tags": {
                  "shape": "Se"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImageScanFindingAggregations": {
      "http": {
        "requestUri": "/ListImageScanFindingAggregations"
      },
      "input": {
        "type": "structure",
        "members": {
          "filter": {
            "shape": "S5w"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "aggregationType": {},
          "responses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "accountAggregation": {
                  "type": "structure",
                  "members": {
                    "accountId": {},
                    "severityCounts": {
                      "shape": "S6z"
                    }
                  }
                },
                "imageAggregation": {
                  "type": "structure",
                  "members": {
                    "imageBuildVersionArn": {},
                    "severityCounts": {
                      "shape": "S6z"
                    }
                  }
                },
                "imagePipelineAggregation": {
                  "type": "structure",
                  "members": {
                    "imagePipelineArn": {},
                    "severityCounts": {
                      "shape": "S6z"
                    }
                  }
                },
                "vulnerabilityIdAggregation": {
                  "type": "structure",
                  "members": {
                    "vulnerabilityId": {},
                    "severityCounts": {
                      "shape": "S6z"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImageScanFindings": {
      "http": {
        "requestUri": "/ListImageScanFindings"
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "findings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "awsAccountId": {},
                "imageBuildVersionArn": {},
                "imagePipelineArn": {},
                "type": {},
                "description": {},
                "title": {},
                "remediation": {
                  "type": "structure",
                  "members": {
                    "recommendation": {
                      "type": "structure",
                      "members": {
                        "text": {},
                        "url": {}
                      }
                    }
                  }
                },
                "severity": {},
                "firstObservedAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                },
                "inspectorScore": {
                  "type": "double"
                },
                "inspectorScoreDetails": {
                  "type": "structure",
                  "members": {
                    "adjustedCvss": {
                      "type": "structure",
                      "members": {
                        "scoreSource": {},
                        "cvssSource": {},
                        "version": {},
                        "score": {
                          "type": "double"
                        },
                        "scoringVector": {},
                        "adjustments": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "metric": {},
                              "reason": {}
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "packageVulnerabilityDetails": {
                  "type": "structure",
                  "required": [
                    "vulnerabilityId"
                  ],
                  "members": {
                    "vulnerabilityId": {},
                    "vulnerablePackages": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "name": {},
                          "version": {},
                          "sourceLayerHash": {},
                          "epoch": {
                            "type": "integer"
                          },
                          "release": {},
                          "arch": {},
                          "packageManager": {},
                          "filePath": {},
                          "fixedInVersion": {},
                          "remediation": {}
                        }
                      }
                    },
                    "source": {},
                    "cvss": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "baseScore": {
                            "type": "double"
                          },
                          "scoringVector": {},
                          "version": {},
                          "source": {}
                        }
                      }
                    },
                    "relatedVulnerabilities": {
                      "type": "list",
                      "member": {}
                    },
                    "sourceUrl": {},
                    "vendorSeverity": {},
                    "vendorCreatedAt": {
                      "type": "timestamp"
                    },
                    "vendorUpdatedAt": {
                      "type": "timestamp"
                    },
                    "referenceUrls": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "fixAvailable": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListImages": {
      "http": {
        "requestUri": "/ListImages"
      },
      "input": {
        "type": "structure",
        "members": {
          "owner": {},
          "filters": {
            "shape": "S5v"
          },
          "byName": {
            "type": "boolean"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "includeDeprecated": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageVersionList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "type": {},
                "version": {},
                "platform": {},
                "osVersion": {},
                "owner": {},
                "dateCreated": {},
                "buildType": {},
                "imageSource": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListInfrastructureConfigurations": {
      "http": {
        "requestUri": "/ListInfrastructureConfigurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "shape": "S5v"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "infrastructureConfigurationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "description": {},
                "dateCreated": {},
                "dateUpdated": {},
                "resourceTags": {
                  "shape": "S2u"
                },
                "tags": {
                  "shape": "Se"
                },
                "instanceTypes": {
                  "shape": "S2n"
                },
                "instanceProfileName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Se"
          }
        }
      }
    },
    "ListWorkflowExecutions": {
      "http": {
        "requestUri": "/ListWorkflowExecutions"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageBuildVersionArn"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "imageBuildVersionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "workflowExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "workflowBuildVersionArn": {},
                "workflowExecutionId": {},
                "type": {},
                "status": {},
                "message": {},
                "totalStepCount": {
                  "type": "integer"
                },
                "totalStepsSucceeded": {
                  "type": "integer"
                },
                "totalStepsFailed": {
                  "type": "integer"
                },
                "totalStepsSkipped": {
                  "type": "integer"
                },
                "startTime": {},
                "endTime": {}
              }
            }
          },
          "imageBuildVersionArn": {},
          "message": {},
          "nextToken": {}
        }
      }
    },
    "ListWorkflowStepExecutions": {
      "http": {
        "requestUri": "/ListWorkflowStepExecutions"
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowExecutionId"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "workflowExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "steps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "stepExecutionId": {},
                "name": {},
                "description": {},
                "action": {},
                "status": {},
                "rollbackStatus": {},
                "message": {},
                "inputs": {},
                "outputs": {},
                "startTime": {},
                "endTime": {}
              }
            }
          },
          "workflowBuildVersionArn": {},
          "workflowExecutionId": {},
          "imageBuildVersionArn": {},
          "message": {},
          "nextToken": {}
        }
      }
    },
    "PutComponentPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/PutComponentPolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "componentArn",
          "policy"
        ],
        "members": {
          "componentArn": {},
          "policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "componentArn": {}
        }
      }
    },
    "PutContainerRecipePolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/PutContainerRecipePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "containerRecipeArn",
          "policy"
        ],
        "members": {
          "containerRecipeArn": {},
          "policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "containerRecipeArn": {}
        }
      }
    },
    "PutImagePolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/PutImagePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageArn",
          "policy"
        ],
        "members": {
          "imageArn": {},
          "policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageArn": {}
        }
      }
    },
    "PutImageRecipePolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/PutImageRecipePolicy"
      },
      "input": {
        "type": "structure",
        "required": [
          "imageRecipeArn",
          "policy"
        ],
        "members": {
          "imageRecipeArn": {},
          "policy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "imageRecipeArn": {}
        }
      }
    },
    "StartImagePipelineExecution": {
      "http": {
        "method": "PUT",
        "requestUri": "/StartImagePipelineExecution"
      },
      "input": {
        "type": "structure",
        "required": [
          "imagePipelineArn",
          "clientToken"
        ],
        "members": {
          "imagePipelineArn": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imageBuildVersionArn": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDistributionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/UpdateDistributionConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "distributionConfigurationArn",
          "distributions",
          "clientToken"
        ],
        "members": {
          "distributionConfigurationArn": {},
          "description": {},
          "distributions": {
            "shape": "S19"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "distributionConfigurationArn": {}
        }
      }
    },
    "UpdateImagePipeline": {
      "http": {
        "method": "PUT",
        "requestUri": "/UpdateImagePipeline"
      },
      "input": {
        "type": "structure",
        "required": [
          "imagePipelineArn",
          "infrastructureConfigurationArn",
          "clientToken"
        ],
        "members": {
          "imagePipelineArn": {},
          "description": {},
          "imageRecipeArn": {},
          "containerRecipeArn": {},
          "infrastructureConfigurationArn": {},
          "distributionConfigurationArn": {},
          "imageTestsConfiguration": {
            "shape": "S25"
          },
          "enhancedImageMetadataEnabled": {
            "type": "boolean"
          },
          "schedule": {
            "shape": "S2b"
          },
          "status": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "imageScanningConfiguration": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "imagePipelineArn": {}
        }
      }
    },
    "UpdateInfrastructureConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/UpdateInfrastructureConfiguration"
      },
      "input": {
        "type": "structure",
        "required": [
          "infrastructureConfigurationArn",
          "instanceProfileName",
          "clientToken"
        ],
        "members": {
          "infrastructureConfigurationArn": {},
          "description": {},
          "instanceTypes": {
            "shape": "S2n"
          },
          "instanceProfileName": {},
          "securityGroupIds": {
            "shape": "S2q"
          },
          "subnetId": {},
          "logging": {
            "shape": "S2r"
          },
          "keyPair": {},
          "terminateInstanceOnFailure": {
            "type": "boolean"
          },
          "snsTopicArn": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "resourceTags": {
            "shape": "S2u"
          },
          "instanceMetadataOptions": {
            "shape": "S2v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "requestId": {},
          "clientToken": {},
          "infrastructureConfigurationArn": {}
        }
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "componentArn"
        ],
        "members": {
          "componentArn": {},
          "parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "value"
              ],
              "members": {
                "name": {},
                "value": {
                  "shape": "Sr"
                }
              }
            }
          }
        }
      }
    },
    "Sr": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "image": {},
        "blockDeviceMappings": {
          "shape": "Su"
        }
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "deviceName": {},
          "ebs": {
            "type": "structure",
            "members": {
              "encrypted": {
                "type": "boolean"
              },
              "deleteOnTermination": {
                "type": "boolean"
              },
              "iops": {
                "type": "integer"
              },
              "kmsKeyId": {},
              "snapshotId": {},
              "volumeSize": {
                "type": "integer"
              },
              "volumeType": {},
              "throughput": {
                "type": "integer"
              }
            }
          },
          "virtualName": {},
          "noDevice": {}
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "service",
        "repositoryName"
      ],
      "members": {
        "service": {},
        "repositoryName": {}
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "region"
        ],
        "members": {
          "region": {},
          "amiDistributionConfiguration": {
            "type": "structure",
            "members": {
              "name": {},
              "description": {},
              "targetAccountIds": {
                "shape": "S1d"
              },
              "amiTags": {
                "shape": "Se"
              },
              "kmsKeyId": {},
              "launchPermission": {
                "type": "structure",
                "members": {
                  "userIds": {
                    "shape": "S1d"
                  },
                  "userGroups": {
                    "shape": "S1g"
                  },
                  "organizationArns": {
                    "type": "list",
                    "member": {}
                  },
                  "organizationalUnitArns": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          },
          "containerDistributionConfiguration": {
            "type": "structure",
            "required": [
              "targetRepository"
            ],
            "members": {
              "description": {},
              "containerTags": {
                "shape": "S1g"
              },
              "targetRepository": {
                "shape": "S14"
              }
            }
          },
          "licenseConfigurationArns": {
            "type": "list",
            "member": {}
          },
          "launchTemplateConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "launchTemplateId"
              ],
              "members": {
                "launchTemplateId": {},
                "accountId": {},
                "setDefaultVersion": {
                  "type": "boolean"
                }
              }
            }
          },
          "s3ExportConfiguration": {
            "type": "structure",
            "required": [
              "roleName",
              "diskImageFormat",
              "s3Bucket"
            ],
            "members": {
              "roleName": {},
              "diskImageFormat": {},
              "s3Bucket": {},
              "s3Prefix": {}
            }
          },
          "fastLaunchConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "enabled"
              ],
              "members": {
                "enabled": {
                  "type": "boolean"
                },
                "snapshotConfiguration": {
                  "type": "structure",
                  "members": {
                    "targetResourceCount": {
                      "type": "integer"
                    }
                  }
                },
                "maxParallelLaunches": {
                  "type": "integer"
                },
                "launchTemplate": {
                  "type": "structure",
                  "members": {
                    "launchTemplateId": {},
                    "launchTemplateName": {},
                    "launchTemplateVersion": {}
                  }
                },
                "accountId": {}
              }
            }
          }
        }
      }
    },
    "S1d": {
      "type": "list",
      "member": {}
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S25": {
      "type": "structure",
      "members": {
        "imageTestsEnabled": {
          "type": "boolean"
        },
        "timeoutMinutes": {
          "type": "integer"
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "imageScanningEnabled": {
          "type": "boolean"
        },
        "ecrConfiguration": {
          "type": "structure",
          "members": {
            "repositoryName": {},
            "containerTags": {
              "shape": "S1g"
            }
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "scheduleExpression": {},
        "timezone": {},
        "pipelineExecutionStartCondition": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "systemsManagerAgent": {
          "type": "structure",
          "members": {
            "uninstallAfterBuild": {
              "type": "boolean"
            }
          }
        },
        "userDataOverride": {}
      }
    },
    "S2n": {
      "type": "list",
      "member": {}
    },
    "S2q": {
      "type": "list",
      "member": {}
    },
    "S2r": {
      "type": "structure",
      "members": {
        "s3Logs": {
          "type": "structure",
          "members": {
            "s3BucketName": {},
            "s3KeyPrefix": {}
          }
        }
      }
    },
    "S2u": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2v": {
      "type": "structure",
      "members": {
        "httpTokens": {},
        "httpPutResponseHopLimit": {
          "type": "integer"
        }
      }
    },
    "S3i": {
      "type": "structure",
      "members": {
        "status": {},
        "reason": {}
      }
    },
    "S3v": {
      "type": "structure",
      "members": {
        "arn": {},
        "containerType": {},
        "name": {},
        "description": {},
        "platform": {},
        "owner": {},
        "version": {},
        "components": {
          "shape": "Sl"
        },
        "instanceConfiguration": {
          "shape": "St"
        },
        "dockerfileTemplateData": {},
        "kmsKeyId": {},
        "encrypted": {
          "type": "boolean"
        },
        "parentImage": {},
        "dateCreated": {},
        "tags": {
          "shape": "Se"
        },
        "workingDirectory": {},
        "targetRepository": {
          "shape": "S14"
        }
      }
    },
    "S41": {
      "type": "structure",
      "required": [
        "timeoutMinutes"
      ],
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "distributions": {
          "shape": "S19"
        },
        "timeoutMinutes": {
          "type": "integer"
        },
        "dateCreated": {},
        "dateUpdated": {},
        "tags": {
          "shape": "Se"
        }
      }
    },
    "S48": {
      "type": "structure",
      "members": {
        "status": {},
        "reason": {}
      }
    },
    "S4a": {
      "type": "structure",
      "members": {
        "arn": {},
        "type": {},
        "name": {},
        "description": {},
        "platform": {},
        "owner": {},
        "version": {},
        "components": {
          "shape": "Sl"
        },
        "parentImage": {},
        "blockDeviceMappings": {
          "shape": "Su"
        },
        "dateCreated": {},
        "tags": {
          "shape": "Se"
        },
        "workingDirectory": {},
        "additionalInstanceConfiguration": {
          "shape": "S2i"
        }
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "instanceTypes": {
          "shape": "S2n"
        },
        "instanceProfileName": {},
        "securityGroupIds": {
          "shape": "S2q"
        },
        "subnetId": {},
        "logging": {
          "shape": "S2r"
        },
        "keyPair": {},
        "terminateInstanceOnFailure": {
          "type": "boolean"
        },
        "snsTopicArn": {},
        "dateCreated": {},
        "dateUpdated": {},
        "resourceTags": {
          "shape": "S2u"
        },
        "instanceMetadataOptions": {
          "shape": "S2v"
        },
        "tags": {
          "shape": "Se"
        }
      }
    },
    "S4d": {
      "type": "structure",
      "members": {
        "amis": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "region": {},
              "image": {},
              "name": {},
              "description": {},
              "state": {
                "shape": "S48"
              },
              "accountId": {}
            }
          }
        },
        "containers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "region": {},
              "imageUris": {
                "shape": "S1g"
              }
            }
          }
        }
      }
    },
    "S4o": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "platform": {},
        "enhancedImageMetadataEnabled": {
          "type": "boolean"
        },
        "imageRecipeArn": {},
        "containerRecipeArn": {},
        "infrastructureConfigurationArn": {},
        "distributionConfigurationArn": {},
        "imageTestsConfiguration": {
          "shape": "S25"
        },
        "schedule": {
          "shape": "S2b"
        },
        "status": {},
        "dateCreated": {},
        "dateUpdated": {},
        "dateLastRun": {},
        "dateNextRun": {},
        "tags": {
          "shape": "Se"
        },
        "imageScanningConfiguration": {
          "shape": "S27"
        }
      }
    },
    "S5v": {
      "type": "list",
      "member": {
        "shape": "S5w"
      }
    },
    "S5w": {
      "type": "structure",
      "members": {
        "name": {},
        "values": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S6f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "type": {},
          "version": {},
          "platform": {},
          "osVersion": {},
          "state": {
            "shape": "S48"
          },
          "owner": {},
          "dateCreated": {},
          "outputResources": {
            "shape": "S4d"
          },
          "tags": {
            "shape": "Se"
          },
          "buildType": {},
          "imageSource": {}
        }
      }
    },
    "S6z": {
      "type": "structure",
      "members": {
        "all": {
          "type": "long"
        },
        "critical": {
          "type": "long"
        },
        "high": {
          "type": "long"
        },
        "medium": {
          "type": "long"
        }
      }
    }
  }
}