{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-31",
    "endpointPrefix": "glue",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Glue",
    "serviceId": "Glue",
    "signatureVersion": "v4",
    "targetPrefix": "AWSGlue",
    "uid": "glue-2017-03-31"
  },
  "operations": {
    "BatchCreatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionInputList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionInputList": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "S12"
          }
        }
      }
    },
    "BatchDeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNameList"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionNameList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Succeeded": {
            "shape": "Sn"
          },
          "Errors": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S14"
            }
          }
        }
      }
    },
    "BatchDeletePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionsToDelete"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionsToDelete": {
            "type": "list",
            "member": {
              "shape": "S1c"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "S12"
          }
        }
      }
    },
    "BatchDeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TablesToDelete"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TablesToDelete": {
            "type": "list",
            "member": {}
          },
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "ErrorDetail": {
                  "shape": "S14"
                }
              }
            }
          }
        }
      }
    },
    "BatchDeleteTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "VersionIds"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableName": {},
                "VersionId": {},
                "ErrorDetail": {
                  "shape": "S14"
                }
              }
            }
          }
        }
      }
    },
    "BatchGetBlueprints": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "IncludeBlueprint": {
            "type": "boolean"
          },
          "IncludeParameterSpec": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprints": {
            "type": "list",
            "member": {
              "shape": "S1w"
            }
          },
          "MissingBlueprints": {
            "shape": "S24"
          }
        }
      }
    },
    "BatchGetCrawlers": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerNames"
        ],
        "members": {
          "CrawlerNames": {
            "shape": "S26"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawlers": {
            "shape": "S28"
          },
          "CrawlersNotFound": {
            "shape": "S26"
          }
        }
      }
    },
    "BatchGetCustomEntityTypes": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "S3r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomEntityTypes": {
            "shape": "S3t"
          },
          "CustomEntityTypesNotFound": {
            "shape": "S3r"
          }
        }
      }
    },
    "BatchGetDataQualityResult": {
      "input": {
        "type": "structure",
        "required": [
          "ResultIds"
        ],
        "members": {
          "ResultIds": {
            "shape": "S3x"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResultId": {},
                "Score": {
                  "type": "double"
                },
                "DataSource": {
                  "shape": "S43"
                },
                "RulesetName": {},
                "EvaluationContext": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "CompletedOn": {
                  "type": "timestamp"
                },
                "JobName": {},
                "JobRunId": {},
                "RulesetEvaluationRunId": {},
                "RuleResults": {
                  "shape": "S46"
                }
              }
            }
          },
          "ResultsNotFound": {
            "shape": "S3x"
          }
        }
      }
    },
    "BatchGetDevEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "DevEndpointNames"
        ],
        "members": {
          "DevEndpointNames": {
            "shape": "S4b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoints": {
            "shape": "S4d"
          },
          "DevEndpointsNotFound": {
            "shape": "S4b"
          }
        }
      }
    },
    "BatchGetJobs": {
      "input": {
        "type": "structure",
        "required": [
          "JobNames"
        ],
        "members": {
          "JobNames": {
            "shape": "S4n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S4p"
          },
          "JobsNotFound": {
            "shape": "S4n"
          }
        }
      }
    },
    "BatchGetPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionsToGet"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionsToGet": {
            "shape": "Sa1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partitions": {
            "shape": "Sa3"
          },
          "UnprocessedKeys": {
            "shape": "Sa1"
          }
        }
      }
    },
    "BatchGetTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "TriggerNames"
        ],
        "members": {
          "TriggerNames": {
            "shape": "Sa6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Triggers": {
            "shape": "Sa8"
          },
          "TriggersNotFound": {
            "shape": "Sa6"
          }
        }
      }
    },
    "BatchGetWorkflows": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "Saq"
          },
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflows": {
            "type": "list",
            "member": {
              "shape": "Sat"
            }
          },
          "MissingWorkflows": {
            "shape": "Saq"
          }
        }
      }
    },
    "BatchStopJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "JobRunIds"
        ],
        "members": {
          "JobName": {},
          "JobRunIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulSubmissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobName": {},
                "JobRunId": {}
              }
            }
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobName": {},
                "JobRunId": {},
                "ErrorDetail": {
                  "shape": "S14"
                }
              }
            }
          }
        }
      }
    },
    "BatchUpdatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PartitionValueList",
                "PartitionInput"
              ],
              "members": {
                "PartitionValueList": {
                  "shape": "Sbr"
                },
                "PartitionInput": {
                  "shape": "S5"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PartitionValueList": {
                  "shape": "Sbr"
                },
                "ErrorDetail": {
                  "shape": "S14"
                }
              }
            }
          }
        }
      }
    },
    "CancelDataQualityRuleRecommendationRun": {
      "input": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelDataQualityRulesetEvaluationRun": {
      "input": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelMLTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "TaskRunId"
        ],
        "members": {
          "TransformId": {},
          "TaskRunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "TaskRunId": {},
          "Status": {}
        }
      }
    },
    "CancelStatement": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId",
          "Id"
        ],
        "members": {
          "SessionId": {},
          "Id": {
            "type": "integer"
          },
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CheckSchemaVersionValidity": {
      "input": {
        "type": "structure",
        "required": [
          "DataFormat",
          "SchemaDefinition"
        ],
        "members": {
          "DataFormat": {},
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Valid": {
            "type": "boolean"
          },
          "Error": {}
        }
      }
    },
    "CreateBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "BlueprintLocation"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BlueprintLocation": {},
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateClassifier": {
      "input": {
        "type": "structure",
        "members": {
          "GrokClassifier": {
            "type": "structure",
            "required": [
              "Classification",
              "Name",
              "GrokPattern"
            ],
            "members": {
              "Classification": {},
              "Name": {},
              "GrokPattern": {},
              "CustomPatterns": {}
            }
          },
          "XMLClassifier": {
            "type": "structure",
            "required": [
              "Classification",
              "Name"
            ],
            "members": {
              "Classification": {},
              "Name": {},
              "RowTag": {}
            }
          },
          "JsonClassifier": {
            "type": "structure",
            "required": [
              "Name",
              "JsonPath"
            ],
            "members": {
              "Name": {},
              "JsonPath": {}
            }
          },
          "CsvClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Delimiter": {},
              "QuoteSymbol": {},
              "ContainsHeader": {},
              "Header": {
                "shape": "Sct"
              },
              "DisableValueTrimming": {
                "type": "boolean"
              },
              "AllowSingleColumn": {
                "type": "boolean"
              },
              "CustomDatatypeConfigured": {
                "type": "boolean"
              },
              "CustomDatatypes": {
                "shape": "Scu"
              },
              "Serde": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionInput"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionInput": {
            "shape": "Scy"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Role",
          "Targets"
        ],
        "members": {
          "Name": {},
          "Role": {},
          "DatabaseName": {},
          "Description": {},
          "Targets": {
            "shape": "S2b"
          },
          "Schedule": {},
          "Classifiers": {
            "shape": "S32"
          },
          "TablePrefix": {},
          "SchemaChangePolicy": {
            "shape": "S35"
          },
          "RecrawlPolicy": {
            "shape": "S33"
          },
          "LineageConfiguration": {
            "shape": "S38"
          },
          "LakeFormationConfiguration": {
            "shape": "S3o"
          },
          "Configuration": {},
          "CrawlerSecurityConfiguration": {},
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateCustomEntityType": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RegexString"
        ],
        "members": {
          "Name": {},
          "RegexString": {},
          "ContextWords": {
            "shape": "S3v"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateDataQualityRuleset": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Ruleset"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Ruleset": {},
          "Tags": {
            "shape": "Scc"
          },
          "TargetTable": {
            "shape": "Sdc"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      },
      "idempotent": true
    },
    "CreateDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseInput": {
            "shape": "Sdf"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "RoleArn"
        ],
        "members": {
          "EndpointName": {},
          "RoleArn": {},
          "SecurityGroupIds": {
            "shape": "S4g"
          },
          "SubnetId": {},
          "PublicKey": {},
          "PublicKeys": {
            "shape": "S4k"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "WorkerType": {},
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "ExtraPythonLibsS3Path": {},
          "ExtraJarsS3Path": {},
          "SecurityConfiguration": {},
          "Tags": {
            "shape": "Scc"
          },
          "Arguments": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "Status": {},
          "SecurityGroupIds": {
            "shape": "S4g"
          },
          "SubnetId": {},
          "RoleArn": {},
          "YarnEndpointAddress": {},
          "ZeppelinRemoteSparkInterpreterPort": {
            "type": "integer"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "WorkerType": {},
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "VpcId": {},
          "ExtraPythonLibsS3Path": {},
          "ExtraJarsS3Path": {},
          "FailureReason": {},
          "SecurityConfiguration": {},
          "CreatedTimestamp": {
            "type": "timestamp"
          },
          "Arguments": {
            "shape": "S4l"
          }
        }
      }
    },
    "CreateJob": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Role",
          "Command"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "LogUri": {},
          "Role": {},
          "ExecutionProperty": {
            "shape": "S4t"
          },
          "Command": {
            "shape": "S4v"
          },
          "DefaultArguments": {
            "shape": "S4z"
          },
          "NonOverridableArguments": {
            "shape": "S4z"
          },
          "Connections": {
            "shape": "S50"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "AllocatedCapacity": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "double"
          },
          "SecurityConfiguration": {},
          "Tags": {
            "shape": "Scc"
          },
          "NotificationProperty": {
            "shape": "S54"
          },
          "GlueVersion": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "WorkerType": {},
          "CodeGenConfigurationNodes": {
            "shape": "S56"
          },
          "ExecutionClass": {},
          "SourceControlDetails": {
            "shape": "S9x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InputRecordTables",
          "Parameters",
          "Role"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "InputRecordTables": {
            "shape": "Sdw"
          },
          "Parameters": {
            "shape": "Sdx"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          },
          "TransformEncryption": {
            "shape": "Se1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "CreatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionInput": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreatePartitionIndex": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionIndex"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionIndex": {
            "shape": "Se8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryName"
        ],
        "members": {
          "RegistryName": {},
          "Description": {},
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryArn": {},
          "RegistryName": {},
          "Description": {},
          "Tags": {
            "shape": "Scc"
          }
        }
      }
    },
    "CreateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaName",
          "DataFormat"
        ],
        "members": {
          "RegistryId": {
            "shape": "See"
          },
          "SchemaName": {},
          "DataFormat": {},
          "Compatibility": {},
          "Description": {},
          "Tags": {
            "shape": "Scc"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "SchemaName": {},
          "SchemaArn": {},
          "Description": {},
          "DataFormat": {},
          "Compatibility": {},
          "SchemaCheckpoint": {
            "type": "long"
          },
          "LatestSchemaVersion": {
            "type": "long"
          },
          "NextSchemaVersion": {
            "type": "long"
          },
          "SchemaStatus": {},
          "Tags": {
            "shape": "Scc"
          },
          "SchemaVersionId": {},
          "SchemaVersionStatus": {}
        }
      }
    },
    "CreateScript": {
      "input": {
        "type": "structure",
        "members": {
          "DagNodes": {
            "shape": "Sel"
          },
          "DagEdges": {
            "shape": "Set"
          },
          "Language": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PythonScript": {},
          "ScalaCode": {}
        }
      }
    },
    "CreateSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "EncryptionConfiguration"
        ],
        "members": {
          "Name": {},
          "EncryptionConfiguration": {
            "shape": "Sf0"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "CreatedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateSession": {
      "input": {
        "type": "structure",
        "required": [
          "Id",
          "Role",
          "Command"
        ],
        "members": {
          "Id": {},
          "Description": {},
          "Role": {},
          "Command": {
            "shape": "Sfc"
          },
          "Timeout": {
            "type": "integer"
          },
          "IdleTimeout": {
            "type": "integer"
          },
          "DefaultArguments": {
            "shape": "Sfd"
          },
          "Connections": {
            "shape": "S50"
          },
          "MaxCapacity": {
            "type": "double"
          },
          "NumberOfWorkers": {
            "type": "integer"
          },
          "WorkerType": {},
          "SecurityConfiguration": {},
          "GlueVersion": {},
          "Tags": {
            "shape": "Scc"
          },
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Session": {
            "shape": "Sfg"
          }
        }
      }
    },
    "CreateTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableInput": {
            "shape": "Sfk"
          },
          "PartitionIndexes": {
            "type": "list",
            "member": {
              "shape": "Se8"
            }
          },
          "TransactionId": {},
          "OpenTableFormatInput": {
            "type": "structure",
            "members": {
              "IcebergInput": {
                "type": "structure",
                "required": [
                  "MetadataOperation"
                ],
                "members": {
                  "MetadataOperation": {},
                  "Version": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Type",
          "Actions"
        ],
        "members": {
          "Name": {},
          "WorkflowName": {},
          "Type": {},
          "Schedule": {},
          "Predicate": {
            "shape": "Saf"
          },
          "Actions": {
            "shape": "Sad"
          },
          "Description": {},
          "StartOnCreation": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Scc"
          },
          "EventBatchingCondition": {
            "shape": "Sam"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "CreateUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionInput": {
            "shape": "Sfx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "DefaultRunProperties": {
            "shape": "Sau"
          },
          "Tags": {
            "shape": "Scc"
          },
          "MaxConcurrentRuns": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionName"
        ],
        "members": {
          "CatalogId": {},
          "ConnectionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCustomEntityType": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteDataQualityRuleset": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "DeleteMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "DeletePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePartitionIndex": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "IndexName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "IndexName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId"
        ],
        "members": {
          "RegistryId": {
            "shape": "See"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "Status": {}
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "PolicyHashCondition": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "Status": {}
        }
      }
    },
    "DeleteSchemaVersions": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "Versions"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "Versions": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionNumber": {
                  "type": "long"
                },
                "ErrorDetails": {
                  "type": "structure",
                  "members": {
                    "ErrorCode": {},
                    "ErrorMessage": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DeleteSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSession": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "DeleteTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Name": {},
          "TransactionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "VersionId"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "GetBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeBlueprint": {
            "type": "boolean"
          },
          "IncludeParameterSpec": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprint": {
            "shape": "S1w"
          }
        }
      }
    },
    "GetBlueprintRun": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName",
          "RunId"
        ],
        "members": {
          "BlueprintName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlueprintRun": {
            "shape": "Shu"
          }
        }
      }
    },
    "GetBlueprintRuns": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName"
        ],
        "members": {
          "BlueprintName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlueprintRuns": {
            "type": "list",
            "member": {
              "shape": "Shu"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCatalogImportStatus": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportStatus": {
            "type": "structure",
            "members": {
              "ImportCompleted": {
                "type": "boolean"
              },
              "ImportTime": {
                "type": "timestamp"
              },
              "ImportedBy": {}
            }
          }
        }
      }
    },
    "GetClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classifier": {
            "shape": "Si8"
          }
        }
      }
    },
    "GetClassifiers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Classifiers": {
            "type": "list",
            "member": {
              "shape": "Si8"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnNames"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnNames": {
            "shape": "Sii"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ColumnStatisticsList": {
            "shape": "Sik"
          },
          "Errors": {
            "shape": "Sj2"
          }
        }
      }
    },
    "GetColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnNames"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnNames": {
            "shape": "Sii"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ColumnStatisticsList": {
            "shape": "Sik"
          },
          "Errors": {
            "shape": "Sj2"
          }
        }
      }
    },
    "GetConnection": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "HidePassword": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "Sj8"
          }
        }
      }
    },
    "GetConnections": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "Filter": {
            "type": "structure",
            "members": {
              "MatchCriteria": {
                "shape": "Sd0"
              },
              "ConnectionType": {}
            }
          },
          "HidePassword": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionList": {
            "type": "list",
            "member": {
              "shape": "Sj8"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawler": {
            "shape": "S29"
          }
        }
      }
    },
    "GetCrawlerMetrics": {
      "input": {
        "type": "structure",
        "members": {
          "CrawlerNameList": {
            "shape": "S26"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrawlerMetricsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CrawlerName": {},
                "TimeLeftSeconds": {
                  "type": "double"
                },
                "StillEstimating": {
                  "type": "boolean"
                },
                "LastRuntimeSeconds": {
                  "type": "double"
                },
                "MedianRuntimeSeconds": {
                  "type": "double"
                },
                "TablesCreated": {
                  "type": "integer"
                },
                "TablesUpdated": {
                  "type": "integer"
                },
                "TablesDeleted": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetCrawlers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawlers": {
            "shape": "S28"
          },
          "NextToken": {}
        }
      }
    },
    "GetCustomEntityType": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "RegexString": {},
          "ContextWords": {
            "shape": "S3v"
          }
        }
      }
    },
    "GetDataCatalogEncryptionSettings": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataCatalogEncryptionSettings": {
            "shape": "Sjp"
          }
        }
      }
    },
    "GetDataQualityResult": {
      "input": {
        "type": "structure",
        "required": [
          "ResultId"
        ],
        "members": {
          "ResultId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResultId": {},
          "Score": {
            "type": "double"
          },
          "DataSource": {
            "shape": "S43"
          },
          "RulesetName": {},
          "EvaluationContext": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "JobName": {},
          "JobRunId": {},
          "RulesetEvaluationRunId": {},
          "RuleResults": {
            "shape": "S46"
          }
        }
      }
    },
    "GetDataQualityRuleRecommendationRun": {
      "input": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {},
          "DataSource": {
            "shape": "S43"
          },
          "Role": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "Status": {},
          "ErrorString": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "ExecutionTime": {
            "type": "integer"
          },
          "RecommendedRuleset": {},
          "CreatedRulesetName": {}
        }
      }
    },
    "GetDataQualityRuleset": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Description": {},
          "Ruleset": {},
          "TargetTable": {
            "shape": "Sdc"
          },
          "CreatedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "RecommendationRunId": {}
        }
      }
    },
    "GetDataQualityRulesetEvaluationRun": {
      "input": {
        "type": "structure",
        "required": [
          "RunId"
        ],
        "members": {
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {},
          "DataSource": {
            "shape": "S43"
          },
          "Role": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "AdditionalRunOptions": {
            "shape": "Sk1"
          },
          "Status": {},
          "ErrorString": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "ExecutionTime": {
            "type": "integer"
          },
          "RulesetNames": {
            "shape": "Sk2"
          },
          "ResultIds": {
            "type": "list",
            "member": {}
          },
          "AdditionalDataSources": {
            "shape": "Sk4"
          }
        }
      }
    },
    "GetDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Database": {
            "shape": "Sk7"
          }
        }
      }
    },
    "GetDatabases": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResourceShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DatabaseList"
        ],
        "members": {
          "DatabaseList": {
            "type": "list",
            "member": {
              "shape": "Sk7"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetDataflowGraph": {
      "input": {
        "type": "structure",
        "members": {
          "PythonScript": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DagNodes": {
            "shape": "Sel"
          },
          "DagEdges": {
            "shape": "Set"
          }
        }
      }
    },
    "GetDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoint": {
            "shape": "S4e"
          }
        }
      }
    },
    "GetDevEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpoints": {
            "shape": "S4d"
          },
          "NextToken": {}
        }
      }
    },
    "GetJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "S4q"
          }
        }
      }
    },
    "GetJobBookmark": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobBookmarkEntry": {
            "shape": "Skp"
          }
        }
      }
    },
    "GetJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "RunId"
        ],
        "members": {
          "JobName": {},
          "RunId": {},
          "PredecessorsIncluded": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRun": {
            "shape": "Sb5"
          }
        }
      }
    },
    "GetJobRuns": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRuns": {
            "shape": "Sb4"
          },
          "NextToken": {}
        }
      }
    },
    "GetJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "shape": "S4p"
          },
          "NextToken": {}
        }
      }
    },
    "GetMLTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "TaskRunId"
        ],
        "members": {
          "TransformId": {},
          "TaskRunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "TaskRunId": {},
          "Status": {},
          "LogGroupName": {},
          "Properties": {
            "shape": "Skz"
          },
          "ErrorString": {},
          "StartedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "CompletedOn": {
            "type": "timestamp"
          },
          "ExecutionTime": {
            "type": "integer"
          }
        }
      }
    },
    "GetMLTaskRuns": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "type": "structure",
            "members": {
              "TaskRunType": {},
              "Status": {},
              "StartedBefore": {
                "type": "timestamp"
              },
              "StartedAfter": {
                "type": "timestamp"
              }
            }
          },
          "Sort": {
            "type": "structure",
            "required": [
              "Column",
              "SortDirection"
            ],
            "members": {
              "Column": {},
              "SortDirection": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TransformId": {},
                "TaskRunId": {},
                "Status": {},
                "LogGroupName": {},
                "Properties": {
                  "shape": "Skz"
                },
                "ErrorString": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "LastModifiedOn": {
                  "type": "timestamp"
                },
                "CompletedOn": {
                  "type": "timestamp"
                },
                "ExecutionTime": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {},
          "Name": {},
          "Description": {},
          "Status": {},
          "CreatedOn": {
            "type": "timestamp"
          },
          "LastModifiedOn": {
            "type": "timestamp"
          },
          "InputRecordTables": {
            "shape": "Sdw"
          },
          "Parameters": {
            "shape": "Sdx"
          },
          "EvaluationMetrics": {
            "shape": "Sli"
          },
          "LabelCount": {
            "type": "integer"
          },
          "Schema": {
            "shape": "Slp"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          },
          "TransformEncryption": {
            "shape": "Se1"
          }
        }
      }
    },
    "GetMLTransforms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "shape": "Sls"
          },
          "Sort": {
            "shape": "Slt"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Transforms"
        ],
        "members": {
          "Transforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TransformId": {},
                "Name": {},
                "Description": {},
                "Status": {},
                "CreatedOn": {
                  "type": "timestamp"
                },
                "LastModifiedOn": {
                  "type": "timestamp"
                },
                "InputRecordTables": {
                  "shape": "Sdw"
                },
                "Parameters": {
                  "shape": "Sdx"
                },
                "EvaluationMetrics": {
                  "shape": "Sli"
                },
                "LabelCount": {
                  "type": "integer"
                },
                "Schema": {
                  "shape": "Slp"
                },
                "Role": {},
                "GlueVersion": {},
                "MaxCapacity": {
                  "type": "double"
                },
                "WorkerType": {},
                "NumberOfWorkers": {
                  "type": "integer"
                },
                "Timeout": {
                  "type": "integer"
                },
                "MaxRetries": {
                  "type": "integer"
                },
                "TransformEncryption": {
                  "shape": "Se1"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMapping": {
      "input": {
        "type": "structure",
        "required": [
          "Source"
        ],
        "members": {
          "Source": {
            "shape": "Slz"
          },
          "Sinks": {
            "shape": "Sm0"
          },
          "Location": {
            "shape": "Sm1"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Mapping"
        ],
        "members": {
          "Mapping": {
            "shape": "Sm3"
          }
        }
      }
    },
    "GetPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partition": {
            "shape": "Sa4"
          }
        }
      }
    },
    "GetPartitionIndexes": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartitionIndexDescriptorList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IndexName",
                "Keys",
                "IndexStatus"
              ],
              "members": {
                "IndexName": {},
                "Keys": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Name",
                      "Type"
                    ],
                    "members": {
                      "Name": {},
                      "Type": {}
                    }
                  }
                },
                "IndexStatus": {},
                "BackfillErrors": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Code": {},
                      "Partitions": {
                        "type": "list",
                        "member": {
                          "shape": "S1c"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetPartitions": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Expression": {},
          "NextToken": {},
          "Segment": {
            "shape": "Smn"
          },
          "MaxResults": {
            "type": "integer"
          },
          "ExcludeColumnSchema": {
            "type": "boolean"
          },
          "TransactionId": {},
          "QueryAsOfTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partitions": {
            "shape": "Sa3"
          },
          "NextToken": {}
        }
      }
    },
    "GetPlan": {
      "input": {
        "type": "structure",
        "required": [
          "Mapping",
          "Source"
        ],
        "members": {
          "Mapping": {
            "shape": "Sm3"
          },
          "Source": {
            "shape": "Slz"
          },
          "Sinks": {
            "shape": "Sm0"
          },
          "Location": {
            "shape": "Sm1"
          },
          "Language": {},
          "AdditionalPlanOptionsMap": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PythonScript": {},
          "ScalaCode": {}
        }
      }
    },
    "GetRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId"
        ],
        "members": {
          "RegistryId": {
            "shape": "See"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "Description": {},
          "Status": {},
          "CreatedTime": {},
          "UpdatedTime": {}
        }
      }
    },
    "GetResourcePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GetResourcePoliciesResponseList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyInJson": {},
                "PolicyHash": {},
                "CreateTime": {
                  "type": "timestamp"
                },
                "UpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyInJson": {},
          "PolicyHash": {},
          "CreateTime": {
            "type": "timestamp"
          },
          "UpdateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {},
          "SchemaName": {},
          "SchemaArn": {},
          "Description": {},
          "DataFormat": {},
          "Compatibility": {},
          "SchemaCheckpoint": {
            "type": "long"
          },
          "LatestSchemaVersion": {
            "type": "long"
          },
          "NextSchemaVersion": {
            "type": "long"
          },
          "SchemaStatus": {},
          "CreatedTime": {},
          "UpdatedTime": {}
        }
      }
    },
    "GetSchemaByDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "SchemaDefinition"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "SchemaArn": {},
          "DataFormat": {},
          "Status": {},
          "CreatedTime": {}
        }
      }
    },
    "GetSchemaVersion": {
      "input": {
        "type": "structure",
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaVersionId": {},
          "SchemaVersionNumber": {
            "shape": "Sna"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "SchemaDefinition": {},
          "DataFormat": {},
          "SchemaArn": {},
          "VersionNumber": {
            "type": "long"
          },
          "Status": {},
          "CreatedTime": {}
        }
      }
    },
    "GetSchemaVersionsDiff": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "FirstSchemaVersionNumber",
          "SecondSchemaVersionNumber",
          "SchemaDiffType"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "FirstSchemaVersionNumber": {
            "shape": "Sna"
          },
          "SecondSchemaVersionNumber": {
            "shape": "Sna"
          },
          "SchemaDiffType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Diff": {}
        }
      }
    },
    "GetSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfiguration": {
            "shape": "Snj"
          }
        }
      }
    },
    "GetSecurityConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfigurations": {
            "type": "list",
            "member": {
              "shape": "Snj"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSession": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Session": {
            "shape": "Sfg"
          }
        }
      }
    },
    "GetStatement": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId",
          "Id"
        ],
        "members": {
          "SessionId": {},
          "Id": {
            "type": "integer"
          },
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {
            "shape": "Snr"
          }
        }
      }
    },
    "GetTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "Name"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Name": {},
          "TransactionId": {},
          "QueryAsOfTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "Sny"
          }
        }
      }
    },
    "GetTableVersion": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "VersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableVersion": {
            "shape": "So2"
          }
        }
      }
    },
    "GetTableVersions": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableVersions": {
            "type": "list",
            "member": {
              "shape": "So2"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetTables": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Expression": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "TransactionId": {},
          "QueryAsOfTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TableList": {
            "shape": "So9"
          },
          "NextToken": {}
        }
      }
    },
    "GetTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Scc"
          }
        }
      }
    },
    "GetTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trigger": {
            "shape": "Sa9"
          }
        }
      }
    },
    "GetTriggers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DependentJobName": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Triggers": {
            "shape": "Sa8"
          },
          "NextToken": {}
        }
      }
    },
    "GetUnfilteredPartitionMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogId",
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "SupportedPermissionTypes"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "AuditContext": {
            "shape": "Soh"
          },
          "SupportedPermissionTypes": {
            "shape": "Sok"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Partition": {
            "shape": "Sa4"
          },
          "AuthorizedColumns": {
            "shape": "Sn"
          },
          "IsRegisteredWithLakeFormation": {
            "type": "boolean"
          }
        }
      }
    },
    "GetUnfilteredPartitionsMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogId",
          "DatabaseName",
          "TableName",
          "SupportedPermissionTypes"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "Expression": {},
          "AuditContext": {
            "shape": "Soh"
          },
          "SupportedPermissionTypes": {
            "shape": "Sok"
          },
          "NextToken": {},
          "Segment": {
            "shape": "Smn"
          },
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnfilteredPartitions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Partition": {
                  "shape": "Sa4"
                },
                "AuthorizedColumns": {
                  "shape": "Sn"
                },
                "IsRegisteredWithLakeFormation": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUnfilteredTableMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "CatalogId",
          "DatabaseName",
          "Name",
          "SupportedPermissionTypes"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Name": {},
          "AuditContext": {
            "shape": "Soh"
          },
          "SupportedPermissionTypes": {
            "shape": "Sok"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Table": {
            "shape": "Sny"
          },
          "AuthorizedColumns": {
            "shape": "Sn"
          },
          "IsRegisteredWithLakeFormation": {
            "type": "boolean"
          },
          "CellFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ColumnName": {},
                "RowFilterExpression": {}
              }
            }
          }
        }
      }
    },
    "GetUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserDefinedFunction": {
            "shape": "Sox"
          }
        }
      }
    },
    "GetUserDefinedFunctions": {
      "input": {
        "type": "structure",
        "required": [
          "Pattern"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "Pattern": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserDefinedFunctions": {
            "type": "list",
            "member": {
              "shape": "Sox"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflow": {
            "shape": "Sat"
          }
        }
      }
    },
    "GetWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "IncludeGraph": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Run": {
            "shape": "Sav"
          }
        }
      }
    },
    "GetWorkflowRunProperties": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunProperties": {
            "shape": "Sau"
          }
        }
      }
    },
    "GetWorkflowRuns": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "IncludeGraph": {
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Runs": {
            "type": "list",
            "member": {
              "shape": "Sav"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ImportCatalogToGlue": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListBlueprints": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Blueprints": {
            "shape": "S24"
          },
          "NextToken": {}
        }
      }
    },
    "ListCrawlers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CrawlerNames": {
            "shape": "S26"
          },
          "NextToken": {}
        }
      }
    },
    "ListCrawls": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FieldName": {},
                "FilterOperator": {},
                "FieldValue": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Crawls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CrawlId": {},
                "State": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Summary": {},
                "ErrorMessage": {},
                "LogGroup": {},
                "LogStream": {},
                "MessagePrefix": {},
                "DPUHour": {
                  "type": "double"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCustomEntityTypes": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomEntityTypes": {
            "shape": "S3t"
          },
          "NextToken": {}
        }
      }
    },
    "ListDataQualityResults": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "DataSource": {
                "shape": "S43"
              },
              "JobName": {},
              "JobRunId": {},
              "StartedAfter": {
                "type": "timestamp"
              },
              "StartedBefore": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Results"
        ],
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResultId": {},
                "DataSource": {
                  "shape": "S43"
                },
                "JobName": {},
                "JobRunId": {},
                "StartedOn": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataQualityRuleRecommendationRuns": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "required": [
              "DataSource"
            ],
            "members": {
              "DataSource": {
                "shape": "S43"
              },
              "StartedBefore": {
                "type": "timestamp"
              },
              "StartedAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Runs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RunId": {},
                "Status": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "DataSource": {
                  "shape": "S43"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataQualityRulesetEvaluationRuns": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "required": [
              "DataSource"
            ],
            "members": {
              "DataSource": {
                "shape": "S43"
              },
              "StartedBefore": {
                "type": "timestamp"
              },
              "StartedAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Runs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RunId": {},
                "Status": {},
                "StartedOn": {
                  "type": "timestamp"
                },
                "DataSource": {
                  "shape": "S43"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataQualityRulesets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "type": "structure",
            "members": {
              "Name": {},
              "Description": {},
              "CreatedBefore": {
                "type": "timestamp"
              },
              "CreatedAfter": {
                "type": "timestamp"
              },
              "LastModifiedBefore": {
                "type": "timestamp"
              },
              "LastModifiedAfter": {
                "type": "timestamp"
              },
              "TargetTable": {
                "shape": "Sdc"
              }
            }
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rulesets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "CreatedOn": {
                  "type": "timestamp"
                },
                "LastModifiedOn": {
                  "type": "timestamp"
                },
                "TargetTable": {
                  "shape": "Sdc"
                },
                "RecommendationRunId": {},
                "RuleCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDevEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DevEndpointNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobNames": {
            "shape": "S4n"
          },
          "NextToken": {}
        }
      }
    },
    "ListMLTransforms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filter": {
            "shape": "Sls"
          },
          "Sort": {
            "shape": "Slt"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformIds"
        ],
        "members": {
          "TransformIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListRegistries": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Registries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RegistryName": {},
                "RegistryArn": {},
                "Description": {},
                "Status": {},
                "CreatedTime": {},
                "UpdatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSchemaVersions": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SchemaArn": {},
                "SchemaVersionId": {},
                "VersionNumber": {
                  "type": "long"
                },
                "Status": {},
                "CreatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSchemas": {
      "input": {
        "type": "structure",
        "members": {
          "RegistryId": {
            "shape": "See"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RegistryName": {},
                "SchemaName": {},
                "SchemaArn": {},
                "Description": {},
                "SchemaStatus": {},
                "CreatedTime": {},
                "UpdatedTime": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSessions": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          },
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ids": {
            "type": "list",
            "member": {}
          },
          "Sessions": {
            "type": "list",
            "member": {
              "shape": "Sfg"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStatements": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId"
        ],
        "members": {
          "SessionId": {},
          "RequestOrigin": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statements": {
            "type": "list",
            "member": {
              "shape": "Snr"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTriggers": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "DependentJobName": {},
          "MaxResults": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TriggerNames": {
            "shape": "Sa6"
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkflows": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workflows": {
            "shape": "Saq"
          },
          "NextToken": {}
        }
      }
    },
    "PutDataCatalogEncryptionSettings": {
      "input": {
        "type": "structure",
        "required": [
          "DataCatalogEncryptionSettings"
        ],
        "members": {
          "CatalogId": {},
          "DataCatalogEncryptionSettings": {
            "shape": "Sjp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyInJson"
        ],
        "members": {
          "PolicyInJson": {},
          "ResourceArn": {},
          "PolicyHashCondition": {},
          "PolicyExistsCondition": {},
          "EnableHybrid": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyHash": {}
        }
      }
    },
    "PutSchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "MetadataKeyValue"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaVersionNumber": {
            "shape": "Sna"
          },
          "SchemaVersionId": {},
          "MetadataKeyValue": {
            "shape": "Srh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {},
          "LatestVersion": {
            "type": "boolean"
          },
          "VersionNumber": {
            "type": "long"
          },
          "SchemaVersionId": {},
          "MetadataKey": {},
          "MetadataValue": {}
        }
      }
    },
    "PutWorkflowRunProperties": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId",
          "RunProperties"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "RunProperties": {
            "shape": "Sau"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "QuerySchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaVersionNumber": {
            "shape": "Sna"
          },
          "SchemaVersionId": {},
          "MetadataList": {
            "type": "list",
            "member": {
              "shape": "Srh"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MetadataInfoMap": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "MetadataValue": {},
                "CreatedTime": {},
                "OtherMetadataValueList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "MetadataValue": {},
                      "CreatedTime": {}
                    }
                  }
                }
              }
            }
          },
          "SchemaVersionId": {},
          "NextToken": {}
        }
      }
    },
    "RegisterSchemaVersion": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId",
          "SchemaDefinition"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaVersionId": {},
          "VersionNumber": {
            "type": "long"
          },
          "Status": {}
        }
      }
    },
    "RemoveSchemaVersionMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "MetadataKeyValue"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaVersionNumber": {
            "shape": "Sna"
          },
          "SchemaVersionId": {},
          "MetadataKeyValue": {
            "shape": "Srh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {},
          "LatestVersion": {
            "type": "boolean"
          },
          "VersionNumber": {
            "type": "long"
          },
          "SchemaVersionId": {},
          "MetadataKey": {},
          "MetadataValue": {}
        }
      }
    },
    "ResetJobBookmark": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobBookmarkEntry": {
            "shape": "Skp"
          }
        }
      }
    },
    "ResumeWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId",
          "NodeIds"
        ],
        "members": {
          "Name": {},
          "RunId": {},
          "NodeIds": {
            "shape": "Ss2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {},
          "NodeIds": {
            "shape": "Ss2"
          }
        }
      }
    },
    "RunStatement": {
      "input": {
        "type": "structure",
        "required": [
          "SessionId",
          "Code"
        ],
        "members": {
          "SessionId": {},
          "Code": {},
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {
            "type": "integer"
          }
        }
      }
    },
    "SearchTables": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {},
                "Comparator": {}
              }
            }
          },
          "SearchText": {},
          "SortCriteria": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FieldName": {},
                "Sort": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "ResourceShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TableList": {
            "shape": "So9"
          }
        }
      }
    },
    "StartBlueprintRun": {
      "input": {
        "type": "structure",
        "required": [
          "BlueprintName",
          "RoleArn"
        ],
        "members": {
          "BlueprintName": {},
          "Parameters": {},
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      }
    },
    "StartCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartDataQualityRuleRecommendationRun": {
      "input": {
        "type": "structure",
        "required": [
          "DataSource",
          "Role"
        ],
        "members": {
          "DataSource": {
            "shape": "S43"
          },
          "Role": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "CreatedRulesetName": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      },
      "idempotent": true
    },
    "StartDataQualityRulesetEvaluationRun": {
      "input": {
        "type": "structure",
        "required": [
          "DataSource",
          "Role",
          "RulesetNames"
        ],
        "members": {
          "DataSource": {
            "shape": "S43"
          },
          "Role": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "ClientToken": {},
          "AdditionalRunOptions": {
            "shape": "Sk1"
          },
          "RulesetNames": {
            "shape": "Sk2"
          },
          "AdditionalDataSources": {
            "shape": "Sk4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      },
      "idempotent": true
    },
    "StartExportLabelsTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "OutputS3Path"
        ],
        "members": {
          "TransformId": {},
          "OutputS3Path": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartImportLabelsTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "InputS3Path"
        ],
        "members": {
          "TransformId": {},
          "InputS3Path": {},
          "ReplaceAllLabels": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartJobRun": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "JobRunId": {},
          "Arguments": {
            "shape": "S4z"
          },
          "AllocatedCapacity": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "double"
          },
          "SecurityConfiguration": {},
          "NotificationProperty": {
            "shape": "S54"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "ExecutionClass": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobRunId": {}
        }
      }
    },
    "StartMLEvaluationTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartMLLabelingSetGenerationTaskRun": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId",
          "OutputS3Path"
        ],
        "members": {
          "TransformId": {},
          "OutputS3Path": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskRunId": {}
        }
      }
    },
    "StartTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "StartWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "RunProperties": {
            "shape": "Sau"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RunId": {}
        }
      }
    },
    "StopCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopSession": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "RequestOrigin": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {}
        }
      }
    },
    "StopTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "StopWorkflowRun": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RunId"
        ],
        "members": {
          "Name": {},
          "RunId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagsToAdd"
        ],
        "members": {
          "ResourceArn": {},
          "TagsToAdd": {
            "shape": "Scc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagsToRemove"
        ],
        "members": {
          "ResourceArn": {},
          "TagsToRemove": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateBlueprint": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "BlueprintLocation"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BlueprintLocation": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "UpdateClassifier": {
      "input": {
        "type": "structure",
        "members": {
          "GrokClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Classification": {},
              "GrokPattern": {},
              "CustomPatterns": {}
            }
          },
          "XMLClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Classification": {},
              "RowTag": {}
            }
          },
          "JsonClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "JsonPath": {}
            }
          },
          "CsvClassifier": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "Delimiter": {},
              "QuoteSymbol": {},
              "ContainsHeader": {},
              "Header": {
                "shape": "Sct"
              },
              "DisableValueTrimming": {
                "type": "boolean"
              },
              "AllowSingleColumn": {
                "type": "boolean"
              },
              "CustomDatatypeConfigured": {
                "type": "boolean"
              },
              "CustomDatatypes": {
                "shape": "Scu"
              },
              "Serde": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateColumnStatisticsForPartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValues",
          "ColumnStatisticsList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValues": {
            "shape": "S6"
          },
          "ColumnStatisticsList": {
            "shape": "Str"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "Stt"
          }
        }
      }
    },
    "UpdateColumnStatisticsForTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "ColumnStatisticsList"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "ColumnStatisticsList": {
            "shape": "Str"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Errors": {
            "shape": "Stt"
          }
        }
      }
    },
    "UpdateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ConnectionInput"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "ConnectionInput": {
            "shape": "Scy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCrawler": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Role": {},
          "DatabaseName": {},
          "Description": {},
          "Targets": {
            "shape": "S2b"
          },
          "Schedule": {},
          "Classifiers": {
            "shape": "S32"
          },
          "TablePrefix": {},
          "SchemaChangePolicy": {
            "shape": "S35"
          },
          "RecrawlPolicy": {
            "shape": "S33"
          },
          "LineageConfiguration": {
            "shape": "S38"
          },
          "LakeFormationConfiguration": {
            "shape": "S3o"
          },
          "Configuration": {},
          "CrawlerSecurityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCrawlerSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "CrawlerName"
        ],
        "members": {
          "CrawlerName": {},
          "Schedule": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDataQualityRuleset": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Ruleset": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Description": {},
          "Ruleset": {}
        }
      }
    },
    "UpdateDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DatabaseInput"
        ],
        "members": {
          "CatalogId": {},
          "Name": {},
          "DatabaseInput": {
            "shape": "Sdf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDevEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {},
          "PublicKey": {},
          "AddPublicKeys": {
            "shape": "S4k"
          },
          "DeletePublicKeys": {
            "shape": "S4k"
          },
          "CustomLibraries": {
            "type": "structure",
            "members": {
              "ExtraPythonLibsS3Path": {},
              "ExtraJarsS3Path": {}
            }
          },
          "UpdateEtlLibraries": {
            "type": "boolean"
          },
          "DeleteArguments": {
            "shape": "S4g"
          },
          "AddArguments": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "JobUpdate"
        ],
        "members": {
          "JobName": {},
          "JobUpdate": {
            "type": "structure",
            "members": {
              "Description": {},
              "LogUri": {},
              "Role": {},
              "ExecutionProperty": {
                "shape": "S4t"
              },
              "Command": {
                "shape": "S4v"
              },
              "DefaultArguments": {
                "shape": "S4z"
              },
              "NonOverridableArguments": {
                "shape": "S4z"
              },
              "Connections": {
                "shape": "S50"
              },
              "MaxRetries": {
                "type": "integer"
              },
              "AllocatedCapacity": {
                "deprecated": true,
                "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
                "type": "integer"
              },
              "Timeout": {
                "type": "integer"
              },
              "MaxCapacity": {
                "type": "double"
              },
              "WorkerType": {},
              "NumberOfWorkers": {
                "type": "integer"
              },
              "SecurityConfiguration": {},
              "NotificationProperty": {
                "shape": "S54"
              },
              "GlueVersion": {},
              "CodeGenConfigurationNodes": {
                "shape": "S56"
              },
              "ExecutionClass": {},
              "SourceControlDetails": {
                "shape": "S9x"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "UpdateJobFromSourceControl": {
      "input": {
        "type": "structure",
        "members": {
          "JobName": {},
          "Provider": {},
          "RepositoryName": {},
          "RepositoryOwner": {},
          "BranchName": {},
          "Folder": {},
          "CommitId": {},
          "AuthStrategy": {},
          "AuthToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "UpdateMLTransform": {
      "input": {
        "type": "structure",
        "required": [
          "TransformId"
        ],
        "members": {
          "TransformId": {},
          "Name": {},
          "Description": {},
          "Parameters": {
            "shape": "Sdx"
          },
          "Role": {},
          "GlueVersion": {},
          "MaxCapacity": {
            "type": "double"
          },
          "WorkerType": {},
          "NumberOfWorkers": {
            "type": "integer"
          },
          "Timeout": {
            "type": "integer"
          },
          "MaxRetries": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransformId": {}
        }
      }
    },
    "UpdatePartition": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableName",
          "PartitionValueList",
          "PartitionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableName": {},
          "PartitionValueList": {
            "shape": "Sbr"
          },
          "PartitionInput": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRegistry": {
      "input": {
        "type": "structure",
        "required": [
          "RegistryId",
          "Description"
        ],
        "members": {
          "RegistryId": {
            "shape": "See"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RegistryName": {},
          "RegistryArn": {}
        }
      }
    },
    "UpdateSchema": {
      "input": {
        "type": "structure",
        "required": [
          "SchemaId"
        ],
        "members": {
          "SchemaId": {
            "shape": "Sw"
          },
          "SchemaVersionNumber": {
            "shape": "Sna"
          },
          "Compatibility": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SchemaArn": {},
          "SchemaName": {},
          "RegistryName": {}
        }
      }
    },
    "UpdateSourceControlFromJob": {
      "input": {
        "type": "structure",
        "members": {
          "JobName": {},
          "Provider": {},
          "RepositoryName": {},
          "RepositoryOwner": {},
          "BranchName": {},
          "Folder": {},
          "CommitId": {},
          "AuthStrategy": {},
          "AuthToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobName": {}
        }
      }
    },
    "UpdateTable": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "TableInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "TableInput": {
            "shape": "Sfk"
          },
          "SkipArchive": {
            "type": "boolean"
          },
          "TransactionId": {},
          "VersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateTrigger": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "TriggerUpdate"
        ],
        "members": {
          "Name": {},
          "TriggerUpdate": {
            "type": "structure",
            "members": {
              "Name": {},
              "Description": {},
              "Schedule": {},
              "Actions": {
                "shape": "Sad"
              },
              "Predicate": {
                "shape": "Saf"
              },
              "EventBatchingCondition": {
                "shape": "Sam"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Trigger": {
            "shape": "Sa9"
          }
        }
      }
    },
    "UpdateUserDefinedFunction": {
      "input": {
        "type": "structure",
        "required": [
          "DatabaseName",
          "FunctionName",
          "FunctionInput"
        ],
        "members": {
          "CatalogId": {},
          "DatabaseName": {},
          "FunctionName": {},
          "FunctionInput": {
            "shape": "Sfx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkflow": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "DefaultRunProperties": {
            "shape": "Sau"
          },
          "MaxConcurrentRuns": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S6"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "Parameters": {
          "shape": "Se"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "Columns": {
          "shape": "Sa"
        },
        "Location": {},
        "AdditionalLocations": {
          "type": "list",
          "member": {}
        },
        "InputFormat": {},
        "OutputFormat": {},
        "Compressed": {
          "type": "boolean"
        },
        "NumberOfBuckets": {
          "type": "integer"
        },
        "SerdeInfo": {
          "type": "structure",
          "members": {
            "Name": {},
            "SerializationLibrary": {},
            "Parameters": {
              "shape": "Se"
            }
          }
        },
        "BucketColumns": {
          "shape": "Sn"
        },
        "SortColumns": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Column",
              "SortOrder"
            ],
            "members": {
              "Column": {},
              "SortOrder": {
                "type": "integer"
              }
            }
          }
        },
        "Parameters": {
          "shape": "Se"
        },
        "SkewedInfo": {
          "type": "structure",
          "members": {
            "SkewedColumnNames": {
              "shape": "Sn"
            },
            "SkewedColumnValues": {
              "type": "list",
              "member": {}
            },
            "SkewedColumnValueLocationMaps": {
              "type": "map",
              "key": {},
              "value": {}
            }
          }
        },
        "StoredAsSubDirectories": {
          "type": "boolean"
        },
        "SchemaReference": {
          "type": "structure",
          "members": {
            "SchemaId": {
              "shape": "Sw"
            },
            "SchemaVersionId": {},
            "SchemaVersionNumber": {
              "type": "long"
            }
          }
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Comment": {},
          "Parameters": {
            "shape": "Se"
          }
        }
      }
    },
    "Se": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "structure",
      "members": {
        "SchemaArn": {},
        "SchemaName": {},
        "RegistryName": {}
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PartitionValues": {
            "shape": "S6"
          },
          "ErrorDetail": {
            "shape": "S14"
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "Values"
      ],
      "members": {
        "Values": {
          "shape": "S6"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "ParameterSpec": {},
        "BlueprintLocation": {},
        "BlueprintServiceLocation": {},
        "Status": {},
        "ErrorMessage": {},
        "LastActiveDefinition": {
          "type": "structure",
          "members": {
            "Description": {},
            "LastModifiedOn": {
              "type": "timestamp"
            },
            "ParameterSpec": {},
            "BlueprintLocation": {},
            "BlueprintServiceLocation": {}
          }
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {}
    },
    "S26": {
      "type": "list",
      "member": {}
    },
    "S28": {
      "type": "list",
      "member": {
        "shape": "S29"
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "Name": {},
        "Role": {},
        "Targets": {
          "shape": "S2b"
        },
        "DatabaseName": {},
        "Description": {},
        "Classifiers": {
          "shape": "S32"
        },
        "RecrawlPolicy": {
          "shape": "S33"
        },
        "SchemaChangePolicy": {
          "shape": "S35"
        },
        "LineageConfiguration": {
          "shape": "S38"
        },
        "State": {},
        "TablePrefix": {},
        "Schedule": {
          "type": "structure",
          "members": {
            "ScheduleExpression": {},
            "State": {}
          }
        },
        "CrawlElapsedTime": {
          "type": "long"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdated": {
          "type": "timestamp"
        },
        "LastCrawl": {
          "type": "structure",
          "members": {
            "Status": {},
            "ErrorMessage": {},
            "LogGroup": {},
            "LogStream": {},
            "MessagePrefix": {},
            "StartTime": {
              "type": "timestamp"
            }
          }
        },
        "Version": {
          "type": "long"
        },
        "Configuration": {},
        "CrawlerSecurityConfiguration": {},
        "LakeFormationConfiguration": {
          "shape": "S3o"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "S3Targets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Path": {},
              "Exclusions": {
                "shape": "S2f"
              },
              "ConnectionName": {},
              "SampleSize": {
                "type": "integer"
              },
              "EventQueueArn": {},
              "DlqEventQueueArn": {}
            }
          }
        },
        "JdbcTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ConnectionName": {},
              "Path": {},
              "Exclusions": {
                "shape": "S2f"
              },
              "EnableAdditionalMetadata": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "MongoDBTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "ConnectionName": {},
              "Path": {},
              "ScanAll": {
                "type": "boolean"
              }
            }
          }
        },
        "DynamoDBTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Path": {},
              "scanAll": {
                "type": "boolean"
              },
              "scanRate": {
                "type": "double"
              }
            }
          }
        },
        "CatalogTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DatabaseName",
              "Tables"
            ],
            "members": {
              "DatabaseName": {},
              "Tables": {
                "type": "list",
                "member": {}
              },
              "ConnectionName": {},
              "EventQueueArn": {},
              "DlqEventQueueArn": {}
            }
          }
        },
        "DeltaTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "DeltaTables": {
                "shape": "S2f"
              },
              "ConnectionName": {},
              "WriteManifest": {
                "type": "boolean"
              },
              "CreateNativeDeltaTable": {
                "type": "boolean"
              }
            }
          }
        },
        "IcebergTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Paths": {
                "shape": "S2f"
              },
              "ConnectionName": {},
              "Exclusions": {
                "shape": "S2f"
              },
              "MaximumTraversalDepth": {
                "type": "integer"
              }
            }
          }
        },
        "HudiTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Paths": {
                "shape": "S2f"
              },
              "ConnectionName": {},
              "Exclusions": {
                "shape": "S2f"
              },
              "MaximumTraversalDepth": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {}
    },
    "S32": {
      "type": "list",
      "member": {}
    },
    "S33": {
      "type": "structure",
      "members": {
        "RecrawlBehavior": {}
      }
    },
    "S35": {
      "type": "structure",
      "members": {
        "UpdateBehavior": {},
        "DeleteBehavior": {}
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "CrawlerLineageSettings": {}
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "UseLakeFormationCredentials": {
          "type": "boolean"
        },
        "AccountId": {}
      }
    },
    "S3r": {
      "type": "list",
      "member": {}
    },
    "S3t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "RegexString"
        ],
        "members": {
          "Name": {},
          "RegexString": {},
          "ContextWords": {
            "shape": "S3v"
          }
        }
      }
    },
    "S3v": {
      "type": "list",
      "member": {}
    },
    "S3x": {
      "type": "list",
      "member": {}
    },
    "S43": {
      "type": "structure",
      "required": [
        "GlueTable"
      ],
      "members": {
        "GlueTable": {
          "shape": "S44"
        }
      }
    },
    "S44": {
      "type": "structure",
      "required": [
        "DatabaseName",
        "TableName"
      ],
      "members": {
        "DatabaseName": {},
        "TableName": {},
        "CatalogId": {},
        "ConnectionName": {},
        "AdditionalOptions": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S46": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Description": {},
          "EvaluationMessage": {},
          "Result": {},
          "EvaluatedMetrics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "double"
            }
          }
        }
      }
    },
    "S4b": {
      "type": "list",
      "member": {}
    },
    "S4d": {
      "type": "list",
      "member": {
        "shape": "S4e"
      }
    },
    "S4e": {
      "type": "structure",
      "members": {
        "EndpointName": {},
        "RoleArn": {},
        "SecurityGroupIds": {
          "shape": "S4g"
        },
        "SubnetId": {},
        "YarnEndpointAddress": {},
        "PrivateAddress": {},
        "ZeppelinRemoteSparkInterpreterPort": {
          "type": "integer"
        },
        "PublicAddress": {},
        "Status": {},
        "WorkerType": {},
        "GlueVersion": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "NumberOfNodes": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "ExtraPythonLibsS3Path": {},
        "ExtraJarsS3Path": {},
        "FailureReason": {},
        "LastUpdateStatus": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        },
        "LastModifiedTimestamp": {
          "type": "timestamp"
        },
        "PublicKey": {},
        "PublicKeys": {
          "shape": "S4k"
        },
        "SecurityConfiguration": {},
        "Arguments": {
          "shape": "S4l"
        }
      }
    },
    "S4g": {
      "type": "list",
      "member": {}
    },
    "S4k": {
      "type": "list",
      "member": {}
    },
    "S4l": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4n": {
      "type": "list",
      "member": {}
    },
    "S4p": {
      "type": "list",
      "member": {
        "shape": "S4q"
      }
    },
    "S4q": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "LogUri": {},
        "Role": {},
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "ExecutionProperty": {
          "shape": "S4t"
        },
        "Command": {
          "shape": "S4v"
        },
        "DefaultArguments": {
          "shape": "S4z"
        },
        "NonOverridableArguments": {
          "shape": "S4z"
        },
        "Connections": {
          "shape": "S50"
        },
        "MaxRetries": {
          "type": "integer"
        },
        "AllocatedCapacity": {
          "deprecated": true,
          "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "double"
        },
        "WorkerType": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "SecurityConfiguration": {},
        "NotificationProperty": {
          "shape": "S54"
        },
        "GlueVersion": {},
        "CodeGenConfigurationNodes": {
          "shape": "S56"
        },
        "ExecutionClass": {},
        "SourceControlDetails": {
          "shape": "S9x"
        }
      }
    },
    "S4t": {
      "type": "structure",
      "members": {
        "MaxConcurrentRuns": {
          "type": "integer"
        }
      }
    },
    "S4v": {
      "type": "structure",
      "members": {
        "Name": {},
        "ScriptLocation": {},
        "PythonVersion": {},
        "Runtime": {}
      }
    },
    "S4z": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S50": {
      "type": "structure",
      "members": {
        "Connections": {
          "shape": "S51"
        }
      }
    },
    "S51": {
      "type": "list",
      "member": {}
    },
    "S54": {
      "type": "structure",
      "members": {
        "NotifyDelayAfter": {
          "type": "integer"
        }
      }
    },
    "S56": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "AthenaConnectorSource": {
            "type": "structure",
            "required": [
              "Name",
              "ConnectionName",
              "ConnectorName",
              "ConnectionType",
              "SchemaName"
            ],
            "members": {
              "Name": {},
              "ConnectionName": {},
              "ConnectorName": {},
              "ConnectionType": {},
              "ConnectionTable": {},
              "SchemaName": {},
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "JDBCConnectorSource": {
            "type": "structure",
            "required": [
              "Name",
              "ConnectionName",
              "ConnectorName",
              "ConnectionType"
            ],
            "members": {
              "Name": {},
              "ConnectionName": {},
              "ConnectorName": {},
              "ConnectionType": {},
              "AdditionalOptions": {
                "type": "structure",
                "members": {
                  "FilterPredicate": {},
                  "PartitionColumn": {},
                  "LowerBound": {
                    "type": "long"
                  },
                  "UpperBound": {
                    "type": "long"
                  },
                  "NumPartitions": {
                    "type": "long"
                  },
                  "JobBookmarkKeys": {
                    "shape": "S5l"
                  },
                  "JobBookmarkKeysSortOrder": {},
                  "DataTypeMapping": {
                    "type": "map",
                    "key": {},
                    "value": {}
                  }
                }
              },
              "ConnectionTable": {},
              "Query": {},
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "SparkConnectorSource": {
            "type": "structure",
            "required": [
              "Name",
              "ConnectionName",
              "ConnectorName",
              "ConnectionType"
            ],
            "members": {
              "Name": {},
              "ConnectionName": {},
              "ConnectorName": {},
              "ConnectionType": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "CatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "RedshiftSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "RedshiftTmpDir": {},
              "TmpDirIAMRole": {}
            }
          },
          "S3CatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "PartitionPredicate": {},
              "AdditionalOptions": {
                "shape": "S5v"
              }
            }
          },
          "S3CsvSource": {
            "type": "structure",
            "required": [
              "Name",
              "Paths",
              "Separator",
              "QuoteChar"
            ],
            "members": {
              "Name": {},
              "Paths": {
                "shape": "S5l"
              },
              "CompressionType": {},
              "Exclusions": {
                "shape": "S5l"
              },
              "GroupSize": {},
              "GroupFiles": {},
              "Recurse": {
                "type": "boolean"
              },
              "MaxBand": {
                "type": "integer"
              },
              "MaxFilesInBand": {
                "type": "integer"
              },
              "AdditionalOptions": {
                "shape": "S61"
              },
              "Separator": {},
              "Escaper": {},
              "QuoteChar": {},
              "Multiline": {
                "type": "boolean"
              },
              "WithHeader": {
                "type": "boolean"
              },
              "WriteHeader": {
                "type": "boolean"
              },
              "SkipFirst": {
                "type": "boolean"
              },
              "OptimizePerformance": {
                "type": "boolean"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3JsonSource": {
            "type": "structure",
            "required": [
              "Name",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Paths": {
                "shape": "S5l"
              },
              "CompressionType": {},
              "Exclusions": {
                "shape": "S5l"
              },
              "GroupSize": {},
              "GroupFiles": {},
              "Recurse": {
                "type": "boolean"
              },
              "MaxBand": {
                "type": "integer"
              },
              "MaxFilesInBand": {
                "type": "integer"
              },
              "AdditionalOptions": {
                "shape": "S61"
              },
              "JsonPath": {},
              "Multiline": {
                "type": "boolean"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3ParquetSource": {
            "type": "structure",
            "required": [
              "Name",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Paths": {
                "shape": "S5l"
              },
              "CompressionType": {},
              "Exclusions": {
                "shape": "S5l"
              },
              "GroupSize": {},
              "GroupFiles": {},
              "Recurse": {
                "type": "boolean"
              },
              "MaxBand": {
                "type": "integer"
              },
              "MaxFilesInBand": {
                "type": "integer"
              },
              "AdditionalOptions": {
                "shape": "S61"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "RelationalCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "DynamoDBCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "JDBCConnectorTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "ConnectionName",
              "ConnectionTable",
              "ConnectorName",
              "ConnectionType"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "ConnectionName": {},
              "ConnectionTable": {},
              "ConnectorName": {},
              "ConnectionType": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "SparkConnectorTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "ConnectionName",
              "ConnectorName",
              "ConnectionType"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "ConnectionName": {},
              "ConnectorName": {},
              "ConnectionType": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "CatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {}
            }
          },
          "RedshiftTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {},
              "RedshiftTmpDir": {},
              "TmpDirIAMRole": {},
              "UpsertRedshiftOptions": {
                "type": "structure",
                "members": {
                  "TableLocation": {},
                  "ConnectionName": {},
                  "UpsertKeys": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          },
          "S3CatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Table",
              "Database"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Table": {},
              "Database": {},
              "SchemaChangePolicy": {
                "shape": "S6j"
              }
            }
          },
          "S3GlueParquetTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Path"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Path": {},
              "Compression": {},
              "SchemaChangePolicy": {
                "shape": "S6m"
              }
            }
          },
          "S3DirectTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Path",
              "Format"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Path": {},
              "Compression": {},
              "Format": {},
              "SchemaChangePolicy": {
                "shape": "S6m"
              }
            }
          },
          "ApplyMapping": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Mapping"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Mapping": {
                "shape": "S6q"
              }
            }
          },
          "SelectFields": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Paths": {
                "shape": "S6i"
              }
            }
          },
          "DropFields": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Paths": {
                "shape": "S6i"
              }
            }
          },
          "RenameField": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "SourcePath",
              "TargetPath"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "SourcePath": {
                "shape": "S5l"
              },
              "TargetPath": {
                "shape": "S5l"
              }
            }
          },
          "Spigot": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Path"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Path": {},
              "Topk": {
                "type": "integer"
              },
              "Prob": {
                "type": "double"
              }
            }
          },
          "Join": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "JoinType",
              "Columns"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6z"
              },
              "JoinType": {},
              "Columns": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "From",
                    "Keys"
                  ],
                  "members": {
                    "From": {},
                    "Keys": {
                      "shape": "S6i"
                    }
                  }
                }
              }
            }
          },
          "SplitFields": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Paths": {
                "shape": "S6i"
              }
            }
          },
          "SelectFromCollection": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Index"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Index": {
                "type": "integer"
              }
            }
          },
          "FillMissingValues": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "ImputedPath"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "ImputedPath": {},
              "FilledPath": {}
            }
          },
          "Filter": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "LogicalOperator",
              "Filters"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "LogicalOperator": {},
              "Filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Operation",
                    "Values"
                  ],
                  "members": {
                    "Operation": {},
                    "Negated": {
                      "type": "boolean"
                    },
                    "Values": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "Type",
                          "Value"
                        ],
                        "members": {
                          "Type": {},
                          "Value": {
                            "shape": "S5l"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "CustomCode": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Code",
              "ClassName"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S7g"
              },
              "Code": {},
              "ClassName": {},
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "SparkSQL": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "SqlQuery",
              "SqlAliases"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S7g"
              },
              "SqlQuery": {},
              "SqlAliases": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "From",
                    "Alias"
                  ],
                  "members": {
                    "From": {},
                    "Alias": {}
                  }
                }
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "DirectKinesisSource": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "WindowSize": {
                "type": "integer"
              },
              "DetectSchema": {
                "type": "boolean"
              },
              "StreamingOptions": {
                "shape": "S7n"
              },
              "DataPreviewOptions": {
                "shape": "S7q"
              }
            }
          },
          "DirectKafkaSource": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "StreamingOptions": {
                "shape": "S7u"
              },
              "WindowSize": {
                "type": "integer"
              },
              "DetectSchema": {
                "type": "boolean"
              },
              "DataPreviewOptions": {
                "shape": "S7q"
              }
            }
          },
          "CatalogKinesisSource": {
            "type": "structure",
            "required": [
              "Name",
              "Table",
              "Database"
            ],
            "members": {
              "Name": {},
              "WindowSize": {
                "type": "integer"
              },
              "DetectSchema": {
                "type": "boolean"
              },
              "Table": {},
              "Database": {},
              "StreamingOptions": {
                "shape": "S7n"
              },
              "DataPreviewOptions": {
                "shape": "S7q"
              }
            }
          },
          "CatalogKafkaSource": {
            "type": "structure",
            "required": [
              "Name",
              "Table",
              "Database"
            ],
            "members": {
              "Name": {},
              "WindowSize": {
                "type": "integer"
              },
              "DetectSchema": {
                "type": "boolean"
              },
              "Table": {},
              "Database": {},
              "StreamingOptions": {
                "shape": "S7u"
              },
              "DataPreviewOptions": {
                "shape": "S7q"
              }
            }
          },
          "DropNullFields": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "NullCheckBoxList": {
                "type": "structure",
                "members": {
                  "IsEmpty": {
                    "type": "boolean"
                  },
                  "IsNullString": {
                    "type": "boolean"
                  },
                  "IsNegOne": {
                    "type": "boolean"
                  }
                }
              },
              "NullTextList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Value",
                    "Datatype"
                  ],
                  "members": {
                    "Value": {},
                    "Datatype": {
                      "type": "structure",
                      "required": [
                        "Id",
                        "Label"
                      ],
                      "members": {
                        "Id": {},
                        "Label": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "Merge": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Source",
              "PrimaryKeys"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6z"
              },
              "Source": {},
              "PrimaryKeys": {
                "shape": "S6i"
              }
            }
          },
          "Union": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "UnionType"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6z"
              },
              "UnionType": {}
            }
          },
          "PIIDetection": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "PiiType",
              "EntityTypesToDetect"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PiiType": {},
              "EntityTypesToDetect": {
                "shape": "S5l"
              },
              "OutputColumnName": {},
              "SampleFraction": {
                "type": "double"
              },
              "ThresholdFraction": {
                "type": "double"
              },
              "MaskValue": {}
            }
          },
          "Aggregate": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Groups",
              "Aggs"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Groups": {
                "shape": "S6i"
              },
              "Aggs": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Column",
                    "AggFunc"
                  ],
                  "members": {
                    "Column": {
                      "shape": "S5l"
                    },
                    "AggFunc": {}
                  }
                }
              }
            }
          },
          "DropDuplicates": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Columns": {
                "type": "list",
                "member": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "GovernedCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Table",
              "Database"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Table": {},
              "Database": {},
              "SchemaChangePolicy": {
                "shape": "S6j"
              }
            }
          },
          "GovernedCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "PartitionPredicate": {},
              "AdditionalOptions": {
                "shape": "S5v"
              }
            }
          },
          "MicrosoftSQLServerCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "MySQLCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "OracleSQLCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "PostgreSQLCatalogSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {}
            }
          },
          "MicrosoftSQLServerCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {}
            }
          },
          "MySQLCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {}
            }
          },
          "OracleSQLCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {}
            }
          },
          "PostgreSQLCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Database": {},
              "Table": {}
            }
          },
          "DynamicTransform": {
            "type": "structure",
            "required": [
              "Name",
              "TransformName",
              "Inputs",
              "FunctionName",
              "Path"
            ],
            "members": {
              "Name": {},
              "TransformName": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Name",
                    "Type"
                  ],
                  "members": {
                    "Name": {},
                    "Type": {},
                    "ValidationRule": {},
                    "ValidationMessage": {},
                    "Value": {
                      "shape": "S5l"
                    },
                    "ListType": {},
                    "IsOptional": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "FunctionName": {},
              "Path": {},
              "Version": {},
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "EvaluateDataQuality": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Ruleset"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Ruleset": {},
              "Output": {},
              "PublishingOptions": {
                "shape": "S8y"
              },
              "StopJobOnFailureOptions": {
                "shape": "S8z"
              }
            }
          },
          "S3CatalogHudiSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "AdditionalHudiOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "CatalogHudiSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "AdditionalHudiOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3HudiSource": {
            "type": "structure",
            "required": [
              "Name",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Paths": {
                "shape": "S5l"
              },
              "AdditionalHudiOptions": {
                "shape": "S5r"
              },
              "AdditionalOptions": {
                "shape": "S61"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3HudiCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Table",
              "Database",
              "AdditionalOptions"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Table": {},
              "Database": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "SchemaChangePolicy": {
                "shape": "S6j"
              }
            }
          },
          "S3HudiDirectTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Path",
              "Compression",
              "Format",
              "AdditionalOptions"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "Path": {},
              "Compression": {},
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Format": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "SchemaChangePolicy": {
                "shape": "S6m"
              }
            }
          },
          "DirectJDBCSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table",
              "ConnectionName",
              "ConnectionType"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "ConnectionName": {},
              "ConnectionType": {},
              "RedshiftTmpDir": {}
            }
          },
          "S3CatalogDeltaSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "AdditionalDeltaOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "CatalogDeltaSource": {
            "type": "structure",
            "required": [
              "Name",
              "Database",
              "Table"
            ],
            "members": {
              "Name": {},
              "Database": {},
              "Table": {},
              "AdditionalDeltaOptions": {
                "shape": "S5r"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3DeltaSource": {
            "type": "structure",
            "required": [
              "Name",
              "Paths"
            ],
            "members": {
              "Name": {},
              "Paths": {
                "shape": "S5l"
              },
              "AdditionalDeltaOptions": {
                "shape": "S5r"
              },
              "AdditionalOptions": {
                "shape": "S61"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "S3DeltaCatalogTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Table",
              "Database"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Table": {},
              "Database": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "SchemaChangePolicy": {
                "shape": "S6j"
              }
            }
          },
          "S3DeltaDirectTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Path",
              "Compression",
              "Format"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "PartitionKeys": {
                "shape": "S6i"
              },
              "Path": {},
              "Compression": {},
              "Format": {},
              "AdditionalOptions": {
                "shape": "S5r"
              },
              "SchemaChangePolicy": {
                "shape": "S6m"
              }
            }
          },
          "AmazonRedshiftSource": {
            "type": "structure",
            "members": {
              "Name": {},
              "Data": {
                "shape": "S9g"
              }
            }
          },
          "AmazonRedshiftTarget": {
            "type": "structure",
            "members": {
              "Name": {},
              "Data": {
                "shape": "S9g"
              },
              "Inputs": {
                "shape": "S6b"
              }
            }
          },
          "EvaluateDataQualityMultiFrame": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "Ruleset"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S7g"
              },
              "AdditionalDataSources": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "Ruleset": {},
              "PublishingOptions": {
                "shape": "S8y"
              },
              "AdditionalOptions": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "StopJobOnFailureOptions": {
                "shape": "S8z"
              }
            }
          },
          "Recipe": {
            "type": "structure",
            "required": [
              "Name",
              "Inputs",
              "RecipeReference"
            ],
            "members": {
              "Name": {},
              "Inputs": {
                "shape": "S6b"
              },
              "RecipeReference": {
                "type": "structure",
                "required": [
                  "RecipeArn",
                  "RecipeVersion"
                ],
                "members": {
                  "RecipeArn": {},
                  "RecipeVersion": {}
                }
              }
            }
          },
          "SnowflakeSource": {
            "type": "structure",
            "required": [
              "Name",
              "Data"
            ],
            "members": {
              "Name": {},
              "Data": {
                "shape": "S9u"
              },
              "OutputSchemas": {
                "shape": "S5d"
              }
            }
          },
          "SnowflakeTarget": {
            "type": "structure",
            "required": [
              "Name",
              "Data"
            ],
            "members": {
              "Name": {},
              "Data": {
                "shape": "S9u"
              },
              "Inputs": {
                "shape": "S6b"
              }
            }
          }
        }
      },
      "sensitive": true
    },
    "S5d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Columns": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "Name": {},
                "Type": {}
              }
            }
          }
        }
      }
    },
    "S5l": {
      "type": "list",
      "member": {}
    },
    "S5r": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5v": {
      "type": "structure",
      "members": {
        "BoundedSize": {
          "type": "long"
        },
        "BoundedFiles": {
          "type": "long"
        }
      }
    },
    "S61": {
      "type": "structure",
      "members": {
        "BoundedSize": {
          "type": "long"
        },
        "BoundedFiles": {
          "type": "long"
        },
        "EnableSamplePath": {
          "type": "boolean"
        },
        "SamplePath": {}
      }
    },
    "S6b": {
      "type": "list",
      "member": {}
    },
    "S6i": {
      "type": "list",
      "member": {
        "shape": "S5l"
      }
    },
    "S6j": {
      "type": "structure",
      "members": {
        "EnableUpdateCatalog": {
          "type": "boolean"
        },
        "UpdateBehavior": {}
      }
    },
    "S6m": {
      "type": "structure",
      "members": {
        "EnableUpdateCatalog": {
          "type": "boolean"
        },
        "UpdateBehavior": {},
        "Table": {},
        "Database": {}
      }
    },
    "S6q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ToKey": {},
          "FromPath": {
            "shape": "S5l"
          },
          "FromType": {},
          "ToType": {},
          "Dropped": {
            "type": "boolean"
          },
          "Children": {
            "shape": "S6q"
          }
        }
      }
    },
    "S6z": {
      "type": "list",
      "member": {}
    },
    "S7g": {
      "type": "list",
      "member": {}
    },
    "S7n": {
      "type": "structure",
      "members": {
        "EndpointUrl": {},
        "StreamName": {},
        "Classification": {},
        "Delimiter": {},
        "StartingPosition": {},
        "MaxFetchTimeInMs": {
          "type": "long"
        },
        "MaxFetchRecordsPerShard": {
          "type": "long"
        },
        "MaxRecordPerRead": {
          "type": "long"
        },
        "AddIdleTimeBetweenReads": {
          "type": "boolean"
        },
        "IdleTimeBetweenReadsInMs": {
          "type": "long"
        },
        "DescribeShardInterval": {
          "type": "long"
        },
        "NumRetries": {
          "type": "integer"
        },
        "RetryIntervalMs": {
          "type": "long"
        },
        "MaxRetryIntervalMs": {
          "type": "long"
        },
        "AvoidEmptyBatches": {
          "type": "boolean"
        },
        "StreamArn": {},
        "RoleArn": {},
        "RoleSessionName": {},
        "AddRecordTimestamp": {},
        "EmitConsumerLagMetrics": {},
        "StartingTimestamp": {
          "shape": "S7p"
        }
      }
    },
    "S7p": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S7q": {
      "type": "structure",
      "members": {
        "PollingTime": {
          "type": "long"
        },
        "RecordPollingLimit": {
          "type": "long"
        }
      }
    },
    "S7u": {
      "type": "structure",
      "members": {
        "BootstrapServers": {},
        "SecurityProtocol": {},
        "ConnectionName": {},
        "TopicName": {},
        "Assign": {},
        "SubscribePattern": {},
        "Classification": {},
        "Delimiter": {},
        "StartingOffsets": {},
        "EndingOffsets": {},
        "PollTimeoutMs": {
          "type": "long"
        },
        "NumRetries": {
          "type": "integer"
        },
        "RetryIntervalMs": {
          "type": "long"
        },
        "MaxOffsetsPerTrigger": {
          "type": "long"
        },
        "MinPartitions": {
          "type": "integer"
        },
        "IncludeHeaders": {
          "type": "boolean"
        },
        "AddRecordTimestamp": {},
        "EmitConsumerLagMetrics": {},
        "StartingTimestamp": {
          "shape": "S7p"
        }
      }
    },
    "S8y": {
      "type": "structure",
      "members": {
        "EvaluationContext": {},
        "ResultsS3Prefix": {},
        "CloudWatchMetricsEnabled": {
          "type": "boolean"
        },
        "ResultsPublishingEnabled": {
          "type": "boolean"
        }
      }
    },
    "S8z": {
      "type": "structure",
      "members": {
        "StopJobOnFailureTiming": {}
      }
    },
    "S9g": {
      "type": "structure",
      "members": {
        "AccessType": {},
        "SourceType": {},
        "Connection": {
          "shape": "S9h"
        },
        "Schema": {
          "shape": "S9h"
        },
        "Table": {
          "shape": "S9h"
        },
        "CatalogDatabase": {
          "shape": "S9h"
        },
        "CatalogTable": {
          "shape": "S9h"
        },
        "CatalogRedshiftSchema": {},
        "CatalogRedshiftTable": {},
        "TempDir": {},
        "IamRole": {
          "shape": "S9h"
        },
        "AdvancedOptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        },
        "SampleQuery": {},
        "PreAction": {},
        "PostAction": {},
        "Action": {},
        "TablePrefix": {},
        "Upsert": {
          "type": "boolean"
        },
        "MergeAction": {},
        "MergeWhenMatched": {},
        "MergeWhenNotMatched": {},
        "MergeClause": {},
        "CrawlerConnection": {},
        "TableSchema": {
          "shape": "S9k"
        },
        "StagingTable": {},
        "SelectedColumns": {
          "shape": "S9k"
        }
      }
    },
    "S9h": {
      "type": "structure",
      "members": {
        "Value": {},
        "Label": {},
        "Description": {}
      }
    },
    "S9k": {
      "type": "list",
      "member": {
        "shape": "S9h"
      }
    },
    "S9u": {
      "type": "structure",
      "members": {
        "SourceType": {},
        "Connection": {
          "shape": "S9h"
        },
        "Schema": {},
        "Table": {},
        "Database": {},
        "TempDir": {},
        "IamRole": {
          "shape": "S9h"
        },
        "AdditionalOptions": {
          "shape": "S5r"
        },
        "SampleQuery": {},
        "PreAction": {},
        "PostAction": {},
        "Action": {},
        "Upsert": {
          "type": "boolean"
        },
        "MergeAction": {},
        "MergeWhenMatched": {},
        "MergeWhenNotMatched": {},
        "MergeClause": {},
        "StagingTable": {},
        "SelectedColumns": {
          "shape": "S9k"
        },
        "AutoPushdown": {
          "type": "boolean"
        },
        "TableSchema": {
          "shape": "S9k"
        }
      }
    },
    "S9x": {
      "type": "structure",
      "members": {
        "Provider": {},
        "Repository": {},
        "Owner": {},
        "Branch": {},
        "Folder": {},
        "LastCommitId": {},
        "AuthStrategy": {},
        "AuthToken": {}
      }
    },
    "Sa1": {
      "type": "list",
      "member": {
        "shape": "S1c"
      }
    },
    "Sa3": {
      "type": "list",
      "member": {
        "shape": "Sa4"
      }
    },
    "Sa4": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S6"
        },
        "DatabaseName": {},
        "TableName": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "Parameters": {
          "shape": "Se"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "CatalogId": {}
      }
    },
    "Sa6": {
      "type": "list",
      "member": {}
    },
    "Sa8": {
      "type": "list",
      "member": {
        "shape": "Sa9"
      }
    },
    "Sa9": {
      "type": "structure",
      "members": {
        "Name": {},
        "WorkflowName": {},
        "Id": {},
        "Type": {},
        "State": {},
        "Description": {},
        "Schedule": {},
        "Actions": {
          "shape": "Sad"
        },
        "Predicate": {
          "shape": "Saf"
        },
        "EventBatchingCondition": {
          "shape": "Sam"
        }
      }
    },
    "Sad": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "JobName": {},
          "Arguments": {
            "shape": "S4z"
          },
          "Timeout": {
            "type": "integer"
          },
          "SecurityConfiguration": {},
          "NotificationProperty": {
            "shape": "S54"
          },
          "CrawlerName": {}
        }
      }
    },
    "Saf": {
      "type": "structure",
      "members": {
        "Logical": {},
        "Conditions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "LogicalOperator": {},
              "JobName": {},
              "State": {},
              "CrawlerName": {},
              "CrawlState": {}
            }
          }
        }
      }
    },
    "Sam": {
      "type": "structure",
      "required": [
        "BatchSize"
      ],
      "members": {
        "BatchSize": {
          "type": "integer"
        },
        "BatchWindow": {
          "type": "integer"
        }
      }
    },
    "Saq": {
      "type": "list",
      "member": {}
    },
    "Sat": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "DefaultRunProperties": {
          "shape": "Sau"
        },
        "CreatedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "LastRun": {
          "shape": "Sav"
        },
        "Graph": {
          "shape": "Say"
        },
        "MaxConcurrentRuns": {
          "type": "integer"
        },
        "BlueprintDetails": {
          "type": "structure",
          "members": {
            "BlueprintName": {},
            "RunId": {}
          }
        }
      }
    },
    "Sau": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sav": {
      "type": "structure",
      "members": {
        "Name": {},
        "WorkflowRunId": {},
        "PreviousRunId": {},
        "WorkflowRunProperties": {
          "shape": "Sau"
        },
        "StartedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "Status": {},
        "ErrorMessage": {},
        "Statistics": {
          "type": "structure",
          "members": {
            "TotalActions": {
              "type": "integer"
            },
            "TimeoutActions": {
              "type": "integer"
            },
            "FailedActions": {
              "type": "integer"
            },
            "StoppedActions": {
              "type": "integer"
            },
            "SucceededActions": {
              "type": "integer"
            },
            "RunningActions": {
              "type": "integer"
            },
            "ErroredActions": {
              "type": "integer"
            },
            "WaitingActions": {
              "type": "integer"
            }
          }
        },
        "Graph": {
          "shape": "Say"
        },
        "StartingEventBatchCondition": {
          "type": "structure",
          "members": {
            "BatchSize": {
              "type": "integer"
            },
            "BatchWindow": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Say": {
      "type": "structure",
      "members": {
        "Nodes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Name": {},
              "UniqueId": {},
              "TriggerDetails": {
                "type": "structure",
                "members": {
                  "Trigger": {
                    "shape": "Sa9"
                  }
                }
              },
              "JobDetails": {
                "type": "structure",
                "members": {
                  "JobRuns": {
                    "shape": "Sb4"
                  }
                }
              },
              "CrawlerDetails": {
                "type": "structure",
                "members": {
                  "Crawls": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "State": {},
                        "StartedOn": {
                          "type": "timestamp"
                        },
                        "CompletedOn": {
                          "type": "timestamp"
                        },
                        "ErrorMessage": {},
                        "LogGroup": {},
                        "LogStream": {}
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Edges": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SourceId": {},
              "DestinationId": {}
            }
          }
        }
      }
    },
    "Sb4": {
      "type": "list",
      "member": {
        "shape": "Sb5"
      }
    },
    "Sb5": {
      "type": "structure",
      "members": {
        "Id": {},
        "Attempt": {
          "type": "integer"
        },
        "PreviousRunId": {},
        "TriggerName": {},
        "JobName": {},
        "StartedOn": {
          "type": "timestamp"
        },
        "LastModifiedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "JobRunState": {},
        "Arguments": {
          "shape": "S4z"
        },
        "ErrorMessage": {},
        "PredecessorRuns": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "JobName": {},
              "RunId": {}
            }
          }
        },
        "AllocatedCapacity": {
          "deprecated": true,
          "deprecatedMessage": "This property is deprecated, use MaxCapacity instead.",
          "type": "integer"
        },
        "ExecutionTime": {
          "type": "integer"
        },
        "Timeout": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "double"
        },
        "WorkerType": {},
        "NumberOfWorkers": {
          "type": "integer"
        },
        "SecurityConfiguration": {},
        "LogGroupName": {},
        "NotificationProperty": {
          "shape": "S54"
        },
        "GlueVersion": {},
        "DPUSeconds": {
          "type": "double"
        },
        "ExecutionClass": {}
      }
    },
    "Sbr": {
      "type": "list",
      "member": {}
    },
    "Scc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sct": {
      "type": "list",
      "member": {}
    },
    "Scu": {
      "type": "list",
      "member": {}
    },
    "Scy": {
      "type": "structure",
      "required": [
        "Name",
        "ConnectionType",
        "ConnectionProperties"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "ConnectionType": {},
        "MatchCriteria": {
          "shape": "Sd0"
        },
        "ConnectionProperties": {
          "shape": "Sd1"
        },
        "PhysicalConnectionRequirements": {
          "shape": "Sd3"
        }
      }
    },
    "Sd0": {
      "type": "list",
      "member": {}
    },
    "Sd1": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sd3": {
      "type": "structure",
      "members": {
        "SubnetId": {},
        "SecurityGroupIdList": {
          "type": "list",
          "member": {}
        },
        "AvailabilityZone": {}
      }
    },
    "Sdc": {
      "type": "structure",
      "required": [
        "TableName",
        "DatabaseName"
      ],
      "members": {
        "TableName": {},
        "DatabaseName": {},
        "CatalogId": {}
      }
    },
    "Sdf": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "LocationUri": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreateTableDefaultPermissions": {
          "shape": "Sdh"
        },
        "TargetDatabase": {
          "shape": "Sdn"
        },
        "FederatedDatabase": {
          "shape": "Sdo"
        }
      }
    },
    "Sdh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "type": "structure",
            "members": {
              "DataLakePrincipalIdentifier": {}
            }
          },
          "Permissions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sdn": {
      "type": "structure",
      "members": {
        "CatalogId": {},
        "DatabaseName": {},
        "Region": {}
      }
    },
    "Sdo": {
      "type": "structure",
      "members": {
        "Identifier": {},
        "ConnectionName": {}
      }
    },
    "Sdw": {
      "type": "list",
      "member": {
        "shape": "S44"
      }
    },
    "Sdx": {
      "type": "structure",
      "required": [
        "TransformType"
      ],
      "members": {
        "TransformType": {},
        "FindMatchesParameters": {
          "type": "structure",
          "members": {
            "PrimaryKeyColumnName": {},
            "PrecisionRecallTradeoff": {
              "type": "double"
            },
            "AccuracyCostTradeoff": {
              "type": "double"
            },
            "EnforceProvidedLabels": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "Se1": {
      "type": "structure",
      "members": {
        "MlUserDataEncryption": {
          "type": "structure",
          "required": [
            "MlUserDataEncryptionMode"
          ],
          "members": {
            "MlUserDataEncryptionMode": {},
            "KmsKeyId": {}
          }
        },
        "TaskRunSecurityConfigurationName": {}
      }
    },
    "Se8": {
      "type": "structure",
      "required": [
        "Keys",
        "IndexName"
      ],
      "members": {
        "Keys": {
          "type": "list",
          "member": {}
        },
        "IndexName": {}
      }
    },
    "See": {
      "type": "structure",
      "members": {
        "RegistryName": {},
        "RegistryArn": {}
      }
    },
    "Sel": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "NodeType",
          "Args"
        ],
        "members": {
          "Id": {},
          "NodeType": {},
          "Args": {
            "shape": "Sep"
          },
          "LineNumber": {
            "type": "integer"
          }
        }
      }
    },
    "Sep": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {},
          "Param": {
            "type": "boolean"
          }
        }
      }
    },
    "Set": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Source",
          "Target"
        ],
        "members": {
          "Source": {},
          "Target": {},
          "TargetParameter": {}
        }
      }
    },
    "Sf0": {
      "type": "structure",
      "members": {
        "S3Encryption": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "S3EncryptionMode": {},
              "KmsKeyArn": {}
            }
          }
        },
        "CloudWatchEncryption": {
          "type": "structure",
          "members": {
            "CloudWatchEncryptionMode": {},
            "KmsKeyArn": {}
          }
        },
        "JobBookmarksEncryption": {
          "type": "structure",
          "members": {
            "JobBookmarksEncryptionMode": {},
            "KmsKeyArn": {}
          }
        }
      }
    },
    "Sfc": {
      "type": "structure",
      "members": {
        "Name": {},
        "PythonVersion": {}
      }
    },
    "Sfd": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sfg": {
      "type": "structure",
      "members": {
        "Id": {},
        "CreatedOn": {
          "type": "timestamp"
        },
        "Status": {},
        "ErrorMessage": {},
        "Description": {},
        "Role": {},
        "Command": {
          "shape": "Sfc"
        },
        "DefaultArguments": {
          "shape": "Sfd"
        },
        "Connections": {
          "shape": "S50"
        },
        "Progress": {
          "type": "double"
        },
        "MaxCapacity": {
          "type": "double"
        },
        "SecurityConfiguration": {},
        "GlueVersion": {}
      }
    },
    "Sfk": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Owner": {},
        "LastAccessTime": {
          "type": "timestamp"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "Retention": {
          "type": "integer"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "PartitionKeys": {
          "shape": "Sa"
        },
        "ViewOriginalText": {},
        "ViewExpandedText": {},
        "TableType": {},
        "Parameters": {
          "shape": "Se"
        },
        "TargetTable": {
          "shape": "Sfo"
        }
      }
    },
    "Sfo": {
      "type": "structure",
      "members": {
        "CatalogId": {},
        "DatabaseName": {},
        "Name": {},
        "Region": {}
      }
    },
    "Sfx": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "ClassName": {},
        "OwnerName": {},
        "OwnerType": {},
        "ResourceUris": {
          "shape": "Sfz"
        }
      }
    },
    "Sfz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "Uri": {}
        }
      }
    },
    "Shu": {
      "type": "structure",
      "members": {
        "BlueprintName": {},
        "RunId": {},
        "WorkflowName": {},
        "State": {},
        "StartedOn": {
          "type": "timestamp"
        },
        "CompletedOn": {
          "type": "timestamp"
        },
        "ErrorMessage": {},
        "RollbackErrorMessage": {},
        "Parameters": {},
        "RoleArn": {}
      }
    },
    "Si8": {
      "type": "structure",
      "members": {
        "GrokClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "Classification",
            "GrokPattern"
          ],
          "members": {
            "Name": {},
            "Classification": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "GrokPattern": {},
            "CustomPatterns": {}
          }
        },
        "XMLClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "Classification"
          ],
          "members": {
            "Name": {},
            "Classification": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "RowTag": {}
          }
        },
        "JsonClassifier": {
          "type": "structure",
          "required": [
            "Name",
            "JsonPath"
          ],
          "members": {
            "Name": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "JsonPath": {}
          }
        },
        "CsvClassifier": {
          "type": "structure",
          "required": [
            "Name"
          ],
          "members": {
            "Name": {},
            "CreationTime": {
              "type": "timestamp"
            },
            "LastUpdated": {
              "type": "timestamp"
            },
            "Version": {
              "type": "long"
            },
            "Delimiter": {},
            "QuoteSymbol": {},
            "ContainsHeader": {},
            "Header": {
              "shape": "Sct"
            },
            "DisableValueTrimming": {
              "type": "boolean"
            },
            "AllowSingleColumn": {
              "type": "boolean"
            },
            "CustomDatatypeConfigured": {
              "type": "boolean"
            },
            "CustomDatatypes": {
              "shape": "Scu"
            },
            "Serde": {}
          }
        }
      }
    },
    "Sii": {
      "type": "list",
      "member": {}
    },
    "Sik": {
      "type": "list",
      "member": {
        "shape": "Sil"
      }
    },
    "Sil": {
      "type": "structure",
      "required": [
        "ColumnName",
        "ColumnType",
        "AnalyzedTime",
        "StatisticsData"
      ],
      "members": {
        "ColumnName": {},
        "ColumnType": {},
        "AnalyzedTime": {
          "type": "timestamp"
        },
        "StatisticsData": {
          "type": "structure",
          "required": [
            "Type"
          ],
          "members": {
            "Type": {},
            "BooleanColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfTrues",
                "NumberOfFalses",
                "NumberOfNulls"
              ],
              "members": {
                "NumberOfTrues": {
                  "type": "long"
                },
                "NumberOfFalses": {
                  "type": "long"
                },
                "NumberOfNulls": {
                  "type": "long"
                }
              }
            },
            "DateColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "timestamp"
                },
                "MaximumValue": {
                  "type": "timestamp"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "DecimalColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "shape": "Sit"
                },
                "MaximumValue": {
                  "shape": "Sit"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "DoubleColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "double"
                },
                "MaximumValue": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "LongColumnStatisticsData": {
              "type": "structure",
              "required": [
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MinimumValue": {
                  "type": "long"
                },
                "MaximumValue": {
                  "type": "long"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "StringColumnStatisticsData": {
              "type": "structure",
              "required": [
                "MaximumLength",
                "AverageLength",
                "NumberOfNulls",
                "NumberOfDistinctValues"
              ],
              "members": {
                "MaximumLength": {
                  "type": "long"
                },
                "AverageLength": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                },
                "NumberOfDistinctValues": {
                  "type": "long"
                }
              }
            },
            "BinaryColumnStatisticsData": {
              "type": "structure",
              "required": [
                "MaximumLength",
                "AverageLength",
                "NumberOfNulls"
              ],
              "members": {
                "MaximumLength": {
                  "type": "long"
                },
                "AverageLength": {
                  "type": "double"
                },
                "NumberOfNulls": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "Sit": {
      "type": "structure",
      "required": [
        "UnscaledValue",
        "Scale"
      ],
      "members": {
        "UnscaledValue": {
          "type": "blob"
        },
        "Scale": {
          "type": "integer"
        }
      }
    },
    "Sj2": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ColumnName": {},
          "Error": {
            "shape": "S14"
          }
        }
      }
    },
    "Sj8": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "ConnectionType": {},
        "MatchCriteria": {
          "shape": "Sd0"
        },
        "ConnectionProperties": {
          "shape": "Sd1"
        },
        "PhysicalConnectionRequirements": {
          "shape": "Sd3"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "LastUpdatedBy": {}
      }
    },
    "Sjp": {
      "type": "structure",
      "members": {
        "EncryptionAtRest": {
          "type": "structure",
          "required": [
            "CatalogEncryptionMode"
          ],
          "members": {
            "CatalogEncryptionMode": {},
            "SseAwsKmsKeyId": {}
          }
        },
        "ConnectionPasswordEncryption": {
          "type": "structure",
          "required": [
            "ReturnConnectionPasswordEncrypted"
          ],
          "members": {
            "ReturnConnectionPasswordEncrypted": {
              "type": "boolean"
            },
            "AwsKmsKeyId": {}
          }
        }
      }
    },
    "Sk1": {
      "type": "structure",
      "members": {
        "CloudWatchMetricsEnabled": {
          "type": "boolean"
        },
        "ResultsS3Prefix": {}
      }
    },
    "Sk2": {
      "type": "list",
      "member": {}
    },
    "Sk4": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S43"
      }
    },
    "Sk7": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "LocationUri": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreateTime": {
          "type": "timestamp"
        },
        "CreateTableDefaultPermissions": {
          "shape": "Sdh"
        },
        "TargetDatabase": {
          "shape": "Sdn"
        },
        "CatalogId": {},
        "FederatedDatabase": {
          "shape": "Sdo"
        }
      }
    },
    "Skp": {
      "type": "structure",
      "members": {
        "JobName": {},
        "Version": {
          "type": "integer"
        },
        "Run": {
          "type": "integer"
        },
        "Attempt": {
          "type": "integer"
        },
        "PreviousRunId": {},
        "RunId": {},
        "JobBookmark": {}
      }
    },
    "Skz": {
      "type": "structure",
      "members": {
        "TaskType": {},
        "ImportLabelsTaskRunProperties": {
          "type": "structure",
          "members": {
            "InputS3Path": {},
            "Replace": {
              "type": "boolean"
            }
          }
        },
        "ExportLabelsTaskRunProperties": {
          "type": "structure",
          "members": {
            "OutputS3Path": {}
          }
        },
        "LabelingSetGenerationTaskRunProperties": {
          "type": "structure",
          "members": {
            "OutputS3Path": {}
          }
        },
        "FindMatchesTaskRunProperties": {
          "type": "structure",
          "members": {
            "JobId": {},
            "JobName": {},
            "JobRunId": {}
          }
        }
      }
    },
    "Sli": {
      "type": "structure",
      "required": [
        "TransformType"
      ],
      "members": {
        "TransformType": {},
        "FindMatchesMetrics": {
          "type": "structure",
          "members": {
            "AreaUnderPRCurve": {
              "type": "double"
            },
            "Precision": {
              "type": "double"
            },
            "Recall": {
              "type": "double"
            },
            "F1": {
              "type": "double"
            },
            "ConfusionMatrix": {
              "type": "structure",
              "members": {
                "NumTruePositives": {
                  "type": "long"
                },
                "NumFalsePositives": {
                  "type": "long"
                },
                "NumTrueNegatives": {
                  "type": "long"
                },
                "NumFalseNegatives": {
                  "type": "long"
                }
              }
            },
            "ColumnImportances": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "ColumnName": {},
                  "Importance": {
                    "type": "double"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Slp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "DataType": {}
        }
      }
    },
    "Sls": {
      "type": "structure",
      "members": {
        "Name": {},
        "TransformType": {},
        "Status": {},
        "GlueVersion": {},
        "CreatedBefore": {
          "type": "timestamp"
        },
        "CreatedAfter": {
          "type": "timestamp"
        },
        "LastModifiedBefore": {
          "type": "timestamp"
        },
        "LastModifiedAfter": {
          "type": "timestamp"
        },
        "Schema": {
          "shape": "Slp"
        }
      }
    },
    "Slt": {
      "type": "structure",
      "required": [
        "Column",
        "SortDirection"
      ],
      "members": {
        "Column": {},
        "SortDirection": {}
      }
    },
    "Slz": {
      "type": "structure",
      "required": [
        "DatabaseName",
        "TableName"
      ],
      "members": {
        "DatabaseName": {},
        "TableName": {}
      }
    },
    "Sm0": {
      "type": "list",
      "member": {
        "shape": "Slz"
      }
    },
    "Sm1": {
      "type": "structure",
      "members": {
        "Jdbc": {
          "shape": "Sep"
        },
        "S3": {
          "shape": "Sep"
        },
        "DynamoDB": {
          "shape": "Sep"
        }
      }
    },
    "Sm3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SourceTable": {},
          "SourcePath": {},
          "SourceType": {},
          "TargetTable": {},
          "TargetPath": {},
          "TargetType": {}
        }
      }
    },
    "Smn": {
      "type": "structure",
      "required": [
        "SegmentNumber",
        "TotalSegments"
      ],
      "members": {
        "SegmentNumber": {
          "type": "integer"
        },
        "TotalSegments": {
          "type": "integer"
        }
      }
    },
    "Sna": {
      "type": "structure",
      "members": {
        "LatestVersion": {
          "type": "boolean"
        },
        "VersionNumber": {
          "type": "long"
        }
      }
    },
    "Snj": {
      "type": "structure",
      "members": {
        "Name": {},
        "CreatedTimeStamp": {
          "type": "timestamp"
        },
        "EncryptionConfiguration": {
          "shape": "Sf0"
        }
      }
    },
    "Snr": {
      "type": "structure",
      "members": {
        "Id": {
          "type": "integer"
        },
        "Code": {},
        "State": {},
        "Output": {
          "type": "structure",
          "members": {
            "Data": {
              "type": "structure",
              "members": {
                "TextPlain": {}
              }
            },
            "ExecutionCount": {
              "type": "integer"
            },
            "Status": {},
            "ErrorName": {},
            "ErrorValue": {},
            "Traceback": {
              "shape": "S51"
            }
          }
        },
        "Progress": {
          "type": "double"
        },
        "StartedOn": {
          "type": "long"
        },
        "CompletedOn": {
          "type": "long"
        }
      }
    },
    "Sny": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "DatabaseName": {},
        "Description": {},
        "Owner": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "UpdateTime": {
          "type": "timestamp"
        },
        "LastAccessTime": {
          "type": "timestamp"
        },
        "LastAnalyzedTime": {
          "type": "timestamp"
        },
        "Retention": {
          "type": "integer"
        },
        "StorageDescriptor": {
          "shape": "S9"
        },
        "PartitionKeys": {
          "shape": "Sa"
        },
        "ViewOriginalText": {},
        "ViewExpandedText": {},
        "TableType": {},
        "Parameters": {
          "shape": "Se"
        },
        "CreatedBy": {},
        "IsRegisteredWithLakeFormation": {
          "type": "boolean"
        },
        "TargetTable": {
          "shape": "Sfo"
        },
        "CatalogId": {},
        "VersionId": {},
        "FederatedTable": {
          "type": "structure",
          "members": {
            "Identifier": {},
            "DatabaseIdentifier": {},
            "ConnectionName": {}
          }
        }
      }
    },
    "So2": {
      "type": "structure",
      "members": {
        "Table": {
          "shape": "Sny"
        },
        "VersionId": {}
      }
    },
    "So9": {
      "type": "list",
      "member": {
        "shape": "Sny"
      }
    },
    "Soh": {
      "type": "structure",
      "members": {
        "AdditionalAuditContext": {},
        "RequestedColumns": {
          "type": "list",
          "member": {}
        },
        "AllColumnsRequested": {
          "type": "boolean"
        }
      }
    },
    "Sok": {
      "type": "list",
      "member": {}
    },
    "Sox": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "DatabaseName": {},
        "ClassName": {},
        "OwnerName": {},
        "OwnerType": {},
        "CreateTime": {
          "type": "timestamp"
        },
        "ResourceUris": {
          "shape": "Sfz"
        },
        "CatalogId": {}
      }
    },
    "Srh": {
      "type": "structure",
      "members": {
        "MetadataKey": {},
        "MetadataValue": {}
      }
    },
    "Ss2": {
      "type": "list",
      "member": {}
    },
    "Str": {
      "type": "list",
      "member": {
        "shape": "Sil"
      }
    },
    "Stt": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ColumnStatistics": {
            "shape": "Sil"
          },
          "Error": {
            "shape": "S14"
          }
        }
      }
    }
  }
}