{
  "pagination": {
    "DescribeActivations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ActivationList"
    },
    "DescribeAssociationExecutionTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AssociationExecutionTargets"
    },
    "DescribeAssociationExecutions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AssociationExecutions"
    },
    "DescribeAutomationExecutions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AutomationExecutionMetadataList"
    },
    "DescribeAutomationStepExecutions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "StepExecutions"
    },
    "DescribeAvailablePatches": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Patches"
    },
    "DescribeEffectiveInstanceAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "DescribeEffectivePatchesForPatchBaseline": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EffectivePatches"
    },
    "DescribeInstanceAssociationsStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceAssociationStatusInfos"
    },
    "DescribeInstanceInformation": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceInformationList"
    },
    "DescribeInstancePatchStates": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstancePatchStates"
    },
    "DescribeInstancePatchStatesForPatchGroup": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstancePatchStates"
    },
    "DescribeInstancePatches": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Patches"
    },
    "DescribeInventoryDeletions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InventoryDeletions"
    },
    "DescribeMaintenanceWindowExecutionTaskInvocations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WindowExecutionTaskInvocationIdentities"
    },
    "DescribeMaintenanceWindowExecutionTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WindowExecutionTaskIdentities"
    },
    "DescribeMaintenanceWindowExecutions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WindowExecutions"
    },
    "DescribeMaintenanceWindowSchedule": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScheduledWindowExecutions"
    },
    "DescribeMaintenanceWindowTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Targets"
    },
    "DescribeMaintenanceWindowTasks": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Tasks"
    },
    "DescribeMaintenanceWindows": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WindowIdentities"
    },
    "DescribeMaintenanceWindowsForTarget": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "WindowIdentities"
    },
    "DescribeOpsItems": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "OpsItemSummaries"
    },
    "DescribeParameters": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "DescribePatchBaselines": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "BaselineIdentities"
    },
    "DescribePatchGroups": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Mappings"
    },
    "DescribePatchProperties": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Properties"
    },
    "DescribeSessions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Sessions"
    },
    "GetInventory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Entities"
    },
    "GetInventorySchema": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Schemas"
    },
    "GetOpsSummary": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Entities"
    },
    "GetParameterHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetParametersByPath": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetResourcePolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Policies"
    },
    "ListAssociationVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "AssociationVersions"
    },
    "ListAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Associations"
    },
    "ListCommandInvocations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "CommandInvocations"
    },
    "ListCommands": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Commands"
    },
    "ListComplianceItems": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ComplianceItems"
    },
    "ListComplianceSummaries": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ComplianceSummaryItems"
    },
    "ListDocumentVersions": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DocumentVersions"
    },
    "ListDocuments": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DocumentIdentifiers"
    },
    "ListOpsItemEvents": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListOpsItemRelatedItems": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Summaries"
    },
    "ListOpsMetadata": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "OpsMetadataList"
    },
    "ListResourceComplianceSummaries": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ResourceComplianceSummaryItems"
    },
    "ListResourceDataSync": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ResourceDataSyncItems"
    }
  }
}