{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-03-12",
    "endpointPrefix": "finspace",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "finspace",
    "serviceFullName": "FinSpace User Environment Management service",
    "serviceId": "finspace",
    "signatureVersion": "v4",
    "signingName": "finspace",
    "uid": "finspace-2021-03-12"
  },
  "operations": {
    "CreateEnvironment": {
      "http": {
        "requestUri": "/environment"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "S5"
          },
          "federationMode": {},
          "federationParameters": {
            "shape": "S9"
          },
          "superuserParameters": {
            "type": "structure",
            "required": [
              "emailAddress",
              "firstName",
              "lastName"
            ],
            "members": {
              "emailAddress": {
                "type": "string",
                "sensitive": true
              },
              "firstName": {},
              "lastName": {}
            }
          },
          "dataBundles": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentId": {},
          "environmentArn": {},
          "environmentUrl": {}
        }
      }
    },
    "CreateKxChangeset": {
      "http": {
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}/changesets"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName",
          "changeRequests",
          "clientToken"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          },
          "changeRequests": {
            "shape": "Ss"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changesetId": {},
          "databaseName": {},
          "environmentId": {},
          "changeRequests": {
            "shape": "Ss"
          },
          "createdTimestamp": {
            "type": "timestamp"
          },
          "lastModifiedTimestamp": {
            "type": "timestamp"
          },
          "status": {},
          "errorInfo": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateKxCluster": {
      "http": {
        "requestUri": "/kx/environments/{environmentId}/clusters"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "clusterName",
          "clusterType",
          "capacityConfiguration",
          "releaseLabel",
          "azMode"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {},
          "clusterType": {},
          "databases": {
            "shape": "S1a"
          },
          "cacheStorageConfigurations": {
            "shape": "S1g"
          },
          "autoScalingConfiguration": {
            "shape": "S1j"
          },
          "clusterDescription": {},
          "capacityConfiguration": {
            "shape": "S1p"
          },
          "releaseLabel": {},
          "vpcConfiguration": {
            "shape": "S1s"
          },
          "initializationScript": {},
          "commandLineArguments": {
            "shape": "S20"
          },
          "code": {
            "shape": "S24"
          },
          "executionRole": {},
          "savedownStorageConfiguration": {
            "shape": "S29"
          },
          "azMode": {},
          "availabilityZoneId": {},
          "tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentId": {},
          "status": {},
          "statusReason": {},
          "clusterName": {},
          "clusterType": {},
          "databases": {
            "shape": "S1a"
          },
          "cacheStorageConfigurations": {
            "shape": "S1g"
          },
          "autoScalingConfiguration": {
            "shape": "S1j"
          },
          "clusterDescription": {},
          "capacityConfiguration": {
            "shape": "S1p"
          },
          "releaseLabel": {},
          "vpcConfiguration": {
            "shape": "S1s"
          },
          "initializationScript": {},
          "commandLineArguments": {
            "shape": "S20"
          },
          "code": {
            "shape": "S24"
          },
          "executionRole": {},
          "lastModifiedTimestamp": {
            "type": "timestamp"
          },
          "savedownStorageConfiguration": {
            "shape": "S29"
          },
          "azMode": {},
          "availabilityZoneId": {},
          "createdTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateKxDatabase": {
      "http": {
        "requestUri": "/kx/environments/{environmentId}/databases"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName",
          "clientToken"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {},
          "description": {},
          "tags": {
            "shape": "S5"
          },
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "databaseName": {},
          "databaseArn": {},
          "environmentId": {},
          "description": {},
          "createdTimestamp": {
            "type": "timestamp"
          },
          "lastModifiedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateKxEnvironment": {
      "http": {
        "requestUri": "/kx/environments"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "kmsKeyId"
        ],
        "members": {
          "name": {},
          "description": {},
          "kmsKeyId": {},
          "tags": {
            "shape": "S5"
          },
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "status": {},
          "environmentId": {},
          "description": {},
          "environmentArn": {},
          "kmsKeyId": {},
          "creationTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "CreateKxUser": {
      "http": {
        "requestUri": "/kx/environments/{environmentId}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "userName",
          "iamRole"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "userName": {},
          "iamRole": {},
          "tags": {
            "shape": "S5"
          },
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userName": {},
          "userArn": {},
          "environmentId": {},
          "iamRole": {}
        }
      }
    },
    "DeleteEnvironment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteKxCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/kx/environments/{environmentId}/clusters/{clusterName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "clusterName"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {
            "location": "uri",
            "locationName": "clusterName"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteKxDatabase": {
      "http": {
        "method": "DELETE",
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName",
          "clientToken"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          },
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteKxEnvironment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/kx/environments/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteKxUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/kx/environments/{environmentId}/users/{userName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "userName",
          "environmentId"
        ],
        "members": {
          "userName": {
            "location": "uri",
            "locationName": "userName"
          },
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetEnvironment": {
      "http": {
        "method": "GET",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environment": {
            "shape": "S36"
          }
        }
      }
    },
    "GetKxChangeset": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}/changesets/{changesetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName",
          "changesetId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          },
          "changesetId": {
            "location": "uri",
            "locationName": "changesetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "changesetId": {},
          "databaseName": {},
          "environmentId": {},
          "changeRequests": {
            "shape": "Ss"
          },
          "createdTimestamp": {
            "type": "timestamp"
          },
          "activeFromTimestamp": {
            "type": "timestamp"
          },
          "lastModifiedTimestamp": {
            "type": "timestamp"
          },
          "status": {},
          "errorInfo": {
            "shape": "S12"
          }
        }
      }
    },
    "GetKxCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/clusters/{clusterName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "clusterName"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {
            "location": "uri",
            "locationName": "clusterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusReason": {},
          "clusterName": {},
          "clusterType": {},
          "databases": {
            "shape": "S1a"
          },
          "cacheStorageConfigurations": {
            "shape": "S1g"
          },
          "autoScalingConfiguration": {
            "shape": "S1j"
          },
          "clusterDescription": {},
          "capacityConfiguration": {
            "shape": "S1p"
          },
          "releaseLabel": {},
          "vpcConfiguration": {
            "shape": "S1s"
          },
          "initializationScript": {},
          "commandLineArguments": {
            "shape": "S20"
          },
          "code": {
            "shape": "S24"
          },
          "executionRole": {},
          "lastModifiedTimestamp": {
            "type": "timestamp"
          },
          "savedownStorageConfiguration": {
            "shape": "S29"
          },
          "azMode": {},
          "availabilityZoneId": {},
          "createdTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetKxConnectionString": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/connectionString"
      },
      "input": {
        "type": "structure",
        "required": [
          "userArn",
          "environmentId",
          "clusterName"
        ],
        "members": {
          "userArn": {
            "location": "querystring",
            "locationName": "userArn"
          },
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {
            "location": "querystring",
            "locationName": "clusterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "signedConnectionString": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetKxDatabase": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "databaseName": {},
          "databaseArn": {},
          "environmentId": {},
          "description": {},
          "createdTimestamp": {
            "type": "timestamp"
          },
          "lastModifiedTimestamp": {
            "type": "timestamp"
          },
          "lastCompletedChangesetId": {},
          "numBytes": {
            "type": "long"
          },
          "numChangesets": {
            "type": "integer"
          },
          "numFiles": {
            "type": "integer"
          }
        }
      }
    },
    "GetKxEnvironment": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "environmentId": {},
          "awsAccountId": {},
          "status": {},
          "tgwStatus": {},
          "dnsStatus": {},
          "errorMessage": {},
          "description": {},
          "environmentArn": {},
          "kmsKeyId": {},
          "dedicatedServiceAccountId": {},
          "transitGatewayConfiguration": {
            "shape": "S3p"
          },
          "customDNSConfiguration": {
            "shape": "S42"
          },
          "creationTimestamp": {
            "type": "timestamp"
          },
          "updateTimestamp": {
            "type": "timestamp"
          },
          "availabilityZoneIds": {
            "shape": "S46"
          },
          "certificateAuthorityArn": {}
        }
      }
    },
    "GetKxUser": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/users/{userName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "userName",
          "environmentId"
        ],
        "members": {
          "userName": {
            "location": "uri",
            "locationName": "userName"
          },
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userName": {},
          "userArn": {},
          "environmentId": {},
          "iamRole": {}
        }
      }
    },
    "ListEnvironments": {
      "http": {
        "method": "GET",
        "requestUri": "/environment"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environments": {
            "type": "list",
            "member": {
              "shape": "S36"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxChangesets": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}/changesets"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "kxChangesets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "changesetId": {},
                "createdTimestamp": {
                  "type": "timestamp"
                },
                "activeFromTimestamp": {
                  "type": "timestamp"
                },
                "lastModifiedTimestamp": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxClusterNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/clusters/{clusterName}/nodes"
      },
      "input": {
        "type": "structure",
        "required": [
          "clusterName",
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {
            "location": "uri",
            "locationName": "clusterName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nodes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "nodeId": {},
                "availabilityZoneId": {},
                "launchTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/clusters"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterType": {
            "location": "querystring",
            "locationName": "clusterType"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "kxClusterSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "status": {},
                "statusReason": {},
                "clusterName": {},
                "clusterType": {},
                "clusterDescription": {},
                "releaseLabel": {},
                "initializationScript": {},
                "executionRole": {},
                "azMode": {},
                "availabilityZoneId": {},
                "lastModifiedTimestamp": {
                  "type": "timestamp"
                },
                "createdTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxDatabases": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/databases"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "kxDatabases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "databaseName": {},
                "createdTimestamp": {
                  "type": "timestamp"
                },
                "lastModifiedTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxEnvironments": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "environmentId": {},
                "awsAccountId": {},
                "status": {},
                "tgwStatus": {},
                "dnsStatus": {},
                "errorMessage": {},
                "description": {},
                "environmentArn": {},
                "kmsKeyId": {},
                "dedicatedServiceAccountId": {},
                "transitGatewayConfiguration": {
                  "shape": "S3p"
                },
                "customDNSConfiguration": {
                  "shape": "S42"
                },
                "creationTimestamp": {
                  "type": "timestamp"
                },
                "updateTimestamp": {
                  "type": "timestamp"
                },
                "availabilityZoneIds": {
                  "shape": "S46"
                },
                "certificateAuthorityArn": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListKxUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/kx/environments/{environmentId}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "userArn": {},
                "userName": {},
                "iamRole": {},
                "createTimestamp": {
                  "type": "timestamp"
                },
                "updateTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S5"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEnvironment": {
      "http": {
        "method": "PUT",
        "requestUri": "/environment/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "name": {},
          "description": {},
          "federationMode": {},
          "federationParameters": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environment": {
            "shape": "S36"
          }
        }
      }
    },
    "UpdateKxClusterDatabases": {
      "http": {
        "method": "PUT",
        "requestUri": "/kx/environments/{environmentId}/clusters/{clusterName}/configuration/databases"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "clusterName",
          "databases"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "clusterName": {
            "location": "uri",
            "locationName": "clusterName"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "databases": {
            "shape": "S1a"
          },
          "deploymentConfiguration": {
            "type": "structure",
            "required": [
              "deploymentStrategy"
            ],
            "members": {
              "deploymentStrategy": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateKxDatabase": {
      "http": {
        "method": "PUT",
        "requestUri": "/kx/environments/{environmentId}/databases/{databaseName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "databaseName",
          "clientToken"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "databaseName": {
            "location": "uri",
            "locationName": "databaseName"
          },
          "description": {},
          "clientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "databaseName": {},
          "environmentId": {},
          "description": {},
          "lastModifiedTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateKxEnvironment": {
      "http": {
        "method": "PUT",
        "requestUri": "/kx/environments/{environmentId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "name": {},
          "description": {},
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "environmentId": {},
          "awsAccountId": {},
          "status": {},
          "tgwStatus": {},
          "dnsStatus": {},
          "errorMessage": {},
          "description": {},
          "environmentArn": {},
          "kmsKeyId": {},
          "dedicatedServiceAccountId": {},
          "transitGatewayConfiguration": {
            "shape": "S3p"
          },
          "customDNSConfiguration": {
            "shape": "S42"
          },
          "creationTimestamp": {
            "type": "timestamp"
          },
          "updateTimestamp": {
            "type": "timestamp"
          },
          "availabilityZoneIds": {
            "shape": "S46"
          }
        }
      }
    },
    "UpdateKxEnvironmentNetwork": {
      "http": {
        "method": "PUT",
        "requestUri": "/kx/environments/{environmentId}/network"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "transitGatewayConfiguration": {
            "shape": "S3p"
          },
          "customDNSConfiguration": {
            "shape": "S42"
          },
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "environmentId": {},
          "awsAccountId": {},
          "status": {},
          "tgwStatus": {},
          "dnsStatus": {},
          "errorMessage": {},
          "description": {},
          "environmentArn": {},
          "kmsKeyId": {},
          "dedicatedServiceAccountId": {},
          "transitGatewayConfiguration": {
            "shape": "S3p"
          },
          "customDNSConfiguration": {
            "shape": "S42"
          },
          "creationTimestamp": {
            "type": "timestamp"
          },
          "updateTimestamp": {
            "type": "timestamp"
          },
          "availabilityZoneIds": {
            "shape": "S46"
          }
        }
      }
    },
    "UpdateKxUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/kx/environments/{environmentId}/users/{userName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "userName",
          "iamRole"
        ],
        "members": {
          "environmentId": {
            "location": "uri",
            "locationName": "environmentId"
          },
          "userName": {
            "location": "uri",
            "locationName": "userName"
          },
          "iamRole": {},
          "clientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userName": {},
          "userArn": {},
          "environmentId": {},
          "iamRole": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "samlMetadataDocument": {},
        "samlMetadataURL": {},
        "applicationCallBackURL": {},
        "federationURN": {},
        "federationProviderName": {},
        "attributeMap": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "changeType",
          "dbPath"
        ],
        "members": {
          "changeType": {},
          "s3Path": {},
          "dbPath": {}
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "errorMessage": {},
        "errorType": {}
      }
    },
    "S1a": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "databaseName"
        ],
        "members": {
          "databaseName": {},
          "cacheConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "cacheType",
                "dbPaths"
              ],
              "members": {
                "cacheType": {},
                "dbPaths": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "changesetId": {}
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "type",
          "size"
        ],
        "members": {
          "type": {},
          "size": {
            "type": "integer"
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "minNodeCount": {
          "type": "integer"
        },
        "maxNodeCount": {
          "type": "integer"
        },
        "autoScalingMetric": {},
        "metricTarget": {
          "type": "double"
        },
        "scaleInCooldownSeconds": {
          "type": "double"
        },
        "scaleOutCooldownSeconds": {
          "type": "double"
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "nodeType": {},
        "nodeCount": {
          "type": "integer"
        }
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "vpcId": {},
        "securityGroupIds": {
          "type": "list",
          "member": {}
        },
        "subnetIds": {
          "type": "list",
          "member": {}
        },
        "ipAddressType": {}
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "s3Bucket": {},
        "s3Key": {},
        "s3ObjectVersion": {}
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "type",
        "size"
      ],
      "members": {
        "type": {},
        "size": {
          "type": "integer"
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "name": {},
        "environmentId": {},
        "awsAccountId": {},
        "status": {},
        "environmentUrl": {},
        "description": {},
        "environmentArn": {},
        "sageMakerStudioDomainUrl": {},
        "kmsKeyId": {},
        "dedicatedServiceAccountId": {},
        "federationMode": {},
        "federationParameters": {
          "shape": "S9"
        }
      }
    },
    "S3p": {
      "type": "structure",
      "required": [
        "transitGatewayID",
        "routableCIDRSpace"
      ],
      "members": {
        "transitGatewayID": {},
        "routableCIDRSpace": {},
        "attachmentNetworkAclConfiguration": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ruleNumber",
              "protocol",
              "ruleAction",
              "cidrBlock"
            ],
            "members": {
              "ruleNumber": {
                "type": "integer"
              },
              "protocol": {},
              "ruleAction": {},
              "portRange": {
                "type": "structure",
                "required": [
                  "from",
                  "to"
                ],
                "members": {
                  "from": {
                    "type": "integer"
                  },
                  "to": {
                    "type": "integer"
                  }
                }
              },
              "icmpTypeCode": {
                "type": "structure",
                "required": [
                  "type",
                  "code"
                ],
                "members": {
                  "type": {
                    "type": "integer"
                  },
                  "code": {
                    "type": "integer"
                  }
                }
              },
              "cidrBlock": {}
            }
          }
        }
      }
    },
    "S42": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "customDNSServerName",
          "customDNSServerIP"
        ],
        "members": {
          "customDNSServerName": {},
          "customDNSServerIP": {}
        }
      }
    },
    "S46": {
      "type": "list",
      "member": {}
    }
  }
}