{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-25",
    "endpointPrefix": "appsync",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWSAppSync",
    "serviceFullName": "AWS AppSync",
    "serviceId": "AppSync",
    "signatureVersion": "v4",
    "signingName": "appsync",
    "uid": "appsync-2017-07-25"
  },
  "operations": {
    "AssociateApi": {
      "http": {
        "requestUri": "/v1/domainnames/{domainName}/apiassociation"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "apiId"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          },
          "apiId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiAssociation": {
            "shape": "S5"
          }
        }
      }
    },
    "AssociateMergedGraphqlApi": {
      "http": {
        "requestUri": "/v1/sourceApis/{sourceApiIdentifier}/mergedApiAssociations"
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceApiIdentifier",
          "mergedApiIdentifier"
        ],
        "members": {
          "sourceApiIdentifier": {
            "location": "uri",
            "locationName": "sourceApiIdentifier"
          },
          "mergedApiIdentifier": {},
          "description": {},
          "sourceApiAssociationConfig": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociation": {
            "shape": "Sb"
          }
        }
      }
    },
    "AssociateSourceGraphqlApi": {
      "http": {
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations"
      },
      "input": {
        "type": "structure",
        "required": [
          "mergedApiIdentifier",
          "sourceApiIdentifier"
        ],
        "members": {
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          },
          "sourceApiIdentifier": {},
          "description": {},
          "sourceApiAssociationConfig": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociation": {
            "shape": "Sb"
          }
        }
      }
    },
    "CreateApiCache": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/ApiCaches"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "ttl",
          "apiCachingBehavior",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "ttl": {
            "type": "long"
          },
          "transitEncryptionEnabled": {
            "type": "boolean"
          },
          "atRestEncryptionEnabled": {
            "type": "boolean"
          },
          "apiCachingBehavior": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiCache": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateApiKey": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/apikeys"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "description": {},
          "expires": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKey": {
            "shape": "Sq"
          }
        }
      }
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/datasources"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "type": {},
          "serviceRoleArn": {},
          "dynamodbConfig": {
            "shape": "Su"
          },
          "lambdaConfig": {
            "shape": "Sw"
          },
          "elasticsearchConfig": {
            "shape": "Sx"
          },
          "openSearchServiceConfig": {
            "shape": "Sy"
          },
          "httpConfig": {
            "shape": "Sz"
          },
          "relationalDatabaseConfig": {
            "shape": "S13"
          },
          "eventBridgeConfig": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "S18"
          }
        }
      }
    },
    "CreateDomainName": {
      "http": {
        "requestUri": "/v1/domainnames"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "certificateArn"
        ],
        "members": {
          "domainName": {},
          "certificateArn": {},
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainNameConfig": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/functions"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "dataSourceName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "functionVersion": {},
          "syncConfig": {
            "shape": "S1g"
          },
          "maxBatchSize": {
            "type": "integer"
          },
          "runtime": {
            "shape": "S1l"
          },
          "code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "S1p"
          }
        }
      }
    },
    "CreateGraphqlApi": {
      "http": {
        "requestUri": "/v1/apis"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "authenticationType"
        ],
        "members": {
          "name": {},
          "logConfig": {
            "shape": "S1r"
          },
          "authenticationType": {},
          "userPoolConfig": {
            "shape": "S1u"
          },
          "openIDConnectConfig": {
            "shape": "S1w"
          },
          "tags": {
            "shape": "S1x"
          },
          "additionalAuthenticationProviders": {
            "shape": "S20"
          },
          "xrayEnabled": {
            "type": "boolean"
          },
          "lambdaAuthorizerConfig": {
            "shape": "S23"
          },
          "visibility": {},
          "apiType": {},
          "mergedApiExecutionRoleArn": {},
          "ownerContact": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S28"
          }
        }
      }
    },
    "CreateResolver": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {},
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "kind": {},
          "pipelineConfig": {
            "shape": "S2c"
          },
          "syncConfig": {
            "shape": "S1g"
          },
          "cachingConfig": {
            "shape": "S2e"
          },
          "maxBatchSize": {
            "type": "integer"
          },
          "runtime": {
            "shape": "S1l"
          },
          "code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S2h"
          }
        }
      }
    },
    "CreateType": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "definition",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "definition": {},
          "format": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S2l"
          }
        }
      }
    },
    "DeleteApiCache": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/ApiCaches"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteApiKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/apikeys/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "id"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDomainName": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/domainnames/{domainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteGraphqlApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteResolver": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/domainnames/{domainName}/apiassociation"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateMergedGraphqlApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/sourceApis/{sourceApiIdentifier}/mergedApiAssociations/{associationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "sourceApiIdentifier",
          "associationId"
        ],
        "members": {
          "sourceApiIdentifier": {
            "location": "uri",
            "locationName": "sourceApiIdentifier"
          },
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociationStatus": {}
        }
      }
    },
    "DisassociateSourceGraphqlApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations/{associationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "mergedApiIdentifier",
          "associationId"
        ],
        "members": {
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          },
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociationStatus": {}
        }
      }
    },
    "EvaluateCode": {
      "http": {
        "requestUri": "/v1/dataplane-evaluatecode"
      },
      "input": {
        "type": "structure",
        "required": [
          "runtime",
          "code",
          "context"
        ],
        "members": {
          "runtime": {
            "shape": "S1l"
          },
          "code": {},
          "context": {},
          "function": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "evaluationResult": {},
          "error": {
            "type": "structure",
            "members": {
              "message": {},
              "codeErrors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "errorType": {},
                    "value": {},
                    "location": {
                      "type": "structure",
                      "members": {
                        "line": {
                          "type": "integer"
                        },
                        "column": {
                          "type": "integer"
                        },
                        "span": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "logs": {
            "shape": "S3k"
          }
        }
      }
    },
    "EvaluateMappingTemplate": {
      "http": {
        "requestUri": "/v1/dataplane-evaluatetemplate"
      },
      "input": {
        "type": "structure",
        "required": [
          "template",
          "context"
        ],
        "members": {
          "template": {},
          "context": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "evaluationResult": {},
          "error": {
            "type": "structure",
            "members": {
              "message": {}
            }
          },
          "logs": {
            "shape": "S3k"
          }
        }
      }
    },
    "FlushApiCache": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/FlushCache"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetApiAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/domainnames/{domainName}/apiassociation"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiAssociation": {
            "shape": "S5"
          }
        }
      }
    },
    "GetApiCache": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/ApiCaches"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiCache": {
            "shape": "Sm"
          }
        }
      }
    },
    "GetDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "S18"
          }
        }
      }
    },
    "GetDomainName": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/domainnames/{domainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainNameConfig": {
            "shape": "S1d"
          }
        }
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "S1p"
          }
        }
      }
    },
    "GetGraphqlApi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S28"
          }
        }
      }
    },
    "GetIntrospectionSchema": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/schema"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "includeDirectives": {
            "location": "querystring",
            "locationName": "includeDirectives",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schema": {
            "type": "blob"
          }
        },
        "payload": "schema"
      }
    },
    "GetResolver": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S2h"
          }
        }
      }
    },
    "GetSchemaCreationStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/schemacreation"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "details": {}
        }
      }
    },
    "GetSourceApiAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations/{associationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "mergedApiIdentifier",
          "associationId"
        ],
        "members": {
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          },
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociation": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetType": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S2l"
          }
        }
      }
    },
    "ListApiKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/apikeys"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKeys": {
            "type": "list",
            "member": {
              "shape": "Sq"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/datasources"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSources": {
            "type": "list",
            "member": {
              "shape": "S18"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDomainNames": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/domainnames"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainNameConfigs": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functions": {
            "type": "list",
            "member": {
              "shape": "S1p"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListGraphqlApis": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "apiType": {
            "location": "querystring",
            "locationName": "apiType"
          },
          "owner": {
            "location": "querystring",
            "locationName": "owner"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApis": {
            "type": "list",
            "member": {
              "shape": "S28"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListResolvers": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolvers": {
            "shape": "S51"
          },
          "nextToken": {}
        }
      }
    },
    "ListResolversByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolvers": {
            "shape": "S51"
          },
          "nextToken": {}
        }
      }
    },
    "ListSourceApiAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/sourceApiAssociations"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "associationId": {},
                "associationArn": {},
                "sourceApiId": {},
                "sourceApiArn": {},
                "mergedApiId": {},
                "mergedApiArn": {},
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1x"
          }
        }
      }
    },
    "ListTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "types": {
            "shape": "S5d"
          },
          "nextToken": {}
        }
      }
    },
    "ListTypesByAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations/{associationId}/types"
      },
      "input": {
        "type": "structure",
        "required": [
          "mergedApiIdentifier",
          "associationId",
          "format"
        ],
        "members": {
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          },
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "types": {
            "shape": "S5d"
          },
          "nextToken": {}
        }
      }
    },
    "StartSchemaCreation": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/schemacreation"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "definition"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "definition": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {}
        }
      }
    },
    "StartSchemaMerge": {
      "http": {
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations/{associationId}/merge"
      },
      "input": {
        "type": "structure",
        "required": [
          "associationId",
          "mergedApiIdentifier"
        ],
        "members": {
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          },
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociationStatus": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S1x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApiCache": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/ApiCaches/update"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "ttl",
          "apiCachingBehavior",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "ttl": {
            "type": "long"
          },
          "apiCachingBehavior": {},
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiCache": {
            "shape": "Sm"
          }
        }
      }
    },
    "UpdateApiKey": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/apikeys/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "id"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "description": {},
          "expires": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKey": {
            "shape": "Sq"
          }
        }
      }
    },
    "UpdateDataSource": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "type": {},
          "serviceRoleArn": {},
          "dynamodbConfig": {
            "shape": "Su"
          },
          "lambdaConfig": {
            "shape": "Sw"
          },
          "elasticsearchConfig": {
            "shape": "Sx"
          },
          "openSearchServiceConfig": {
            "shape": "Sy"
          },
          "httpConfig": {
            "shape": "Sz"
          },
          "relationalDatabaseConfig": {
            "shape": "S13"
          },
          "eventBridgeConfig": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "S18"
          }
        }
      }
    },
    "UpdateDomainName": {
      "http": {
        "requestUri": "/v1/domainnames/{domainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {
            "location": "uri",
            "locationName": "domainName"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainNameConfig": {
            "shape": "S1d"
          }
        }
      }
    },
    "UpdateFunction": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "functionId",
          "dataSourceName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          },
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "functionVersion": {},
          "syncConfig": {
            "shape": "S1g"
          },
          "maxBatchSize": {
            "type": "integer"
          },
          "runtime": {
            "shape": "S1l"
          },
          "code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "S1p"
          }
        }
      }
    },
    "UpdateGraphqlApi": {
      "http": {
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "logConfig": {
            "shape": "S1r"
          },
          "authenticationType": {},
          "userPoolConfig": {
            "shape": "S1u"
          },
          "openIDConnectConfig": {
            "shape": "S1w"
          },
          "additionalAuthenticationProviders": {
            "shape": "S20"
          },
          "xrayEnabled": {
            "type": "boolean"
          },
          "lambdaAuthorizerConfig": {
            "shape": "S23"
          },
          "mergedApiExecutionRoleArn": {},
          "ownerContact": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S28"
          }
        }
      }
    },
    "UpdateResolver": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          },
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "kind": {},
          "pipelineConfig": {
            "shape": "S2c"
          },
          "syncConfig": {
            "shape": "S1g"
          },
          "cachingConfig": {
            "shape": "S2e"
          },
          "maxBatchSize": {
            "type": "integer"
          },
          "runtime": {
            "shape": "S1l"
          },
          "code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S2h"
          }
        }
      }
    },
    "UpdateSourceApiAssociation": {
      "http": {
        "requestUri": "/v1/mergedApis/{mergedApiIdentifier}/sourceApiAssociations/{associationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "associationId",
          "mergedApiIdentifier"
        ],
        "members": {
          "associationId": {
            "location": "uri",
            "locationName": "associationId"
          },
          "mergedApiIdentifier": {
            "location": "uri",
            "locationName": "mergedApiIdentifier"
          },
          "description": {},
          "sourceApiAssociationConfig": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceApiAssociation": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateType": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "definition": {},
          "format": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S2l"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "domainName": {},
        "apiId": {},
        "associationStatus": {},
        "deploymentDetail": {}
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "mergeType": {}
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "associationId": {},
        "associationArn": {},
        "sourceApiId": {},
        "sourceApiArn": {},
        "mergedApiArn": {},
        "mergedApiId": {},
        "description": {},
        "sourceApiAssociationConfig": {
          "shape": "S8"
        },
        "sourceApiAssociationStatus": {},
        "sourceApiAssociationStatusDetail": {},
        "lastSuccessfulMergeDate": {
          "type": "timestamp"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "ttl": {
          "type": "long"
        },
        "apiCachingBehavior": {},
        "transitEncryptionEnabled": {
          "type": "boolean"
        },
        "atRestEncryptionEnabled": {
          "type": "boolean"
        },
        "type": {},
        "status": {}
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "expires": {
          "type": "long"
        },
        "deletes": {
          "type": "long"
        }
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "tableName",
        "awsRegion"
      ],
      "members": {
        "tableName": {},
        "awsRegion": {},
        "useCallerCredentials": {
          "type": "boolean"
        },
        "deltaSyncConfig": {
          "type": "structure",
          "members": {
            "baseTableTTL": {
              "type": "long"
            },
            "deltaSyncTableName": {},
            "deltaSyncTableTTL": {
              "type": "long"
            }
          }
        },
        "versioned": {
          "type": "boolean"
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "lambdaFunctionArn"
      ],
      "members": {
        "lambdaFunctionArn": {}
      }
    },
    "Sx": {
      "type": "structure",
      "required": [
        "endpoint",
        "awsRegion"
      ],
      "members": {
        "endpoint": {},
        "awsRegion": {}
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "endpoint",
        "awsRegion"
      ],
      "members": {
        "endpoint": {},
        "awsRegion": {}
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "endpoint": {},
        "authorizationConfig": {
          "type": "structure",
          "required": [
            "authorizationType"
          ],
          "members": {
            "authorizationType": {},
            "awsIamConfig": {
              "type": "structure",
              "members": {
                "signingRegion": {},
                "signingServiceName": {}
              }
            }
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "relationalDatabaseSourceType": {},
        "rdsHttpEndpointConfig": {
          "type": "structure",
          "members": {
            "awsRegion": {},
            "dbClusterIdentifier": {},
            "databaseName": {},
            "schema": {},
            "awsSecretStoreArn": {}
          }
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "eventBusArn"
      ],
      "members": {
        "eventBusArn": {}
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "dataSourceArn": {},
        "name": {},
        "description": {},
        "type": {},
        "serviceRoleArn": {},
        "dynamodbConfig": {
          "shape": "Su"
        },
        "lambdaConfig": {
          "shape": "Sw"
        },
        "elasticsearchConfig": {
          "shape": "Sx"
        },
        "openSearchServiceConfig": {
          "shape": "Sy"
        },
        "httpConfig": {
          "shape": "Sz"
        },
        "relationalDatabaseConfig": {
          "shape": "S13"
        },
        "eventBridgeConfig": {
          "shape": "S16"
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "domainName": {},
        "description": {},
        "certificateArn": {},
        "appsyncDomainName": {},
        "hostedZoneId": {}
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "conflictHandler": {},
        "conflictDetection": {},
        "lambdaConflictHandlerConfig": {
          "type": "structure",
          "members": {
            "lambdaConflictHandlerArn": {}
          }
        }
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "name",
        "runtimeVersion"
      ],
      "members": {
        "name": {},
        "runtimeVersion": {}
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "functionId": {},
        "functionArn": {},
        "name": {},
        "description": {},
        "dataSourceName": {},
        "requestMappingTemplate": {},
        "responseMappingTemplate": {},
        "functionVersion": {},
        "syncConfig": {
          "shape": "S1g"
        },
        "maxBatchSize": {
          "type": "integer"
        },
        "runtime": {
          "shape": "S1l"
        },
        "code": {}
      }
    },
    "S1r": {
      "type": "structure",
      "required": [
        "fieldLogLevel",
        "cloudWatchLogsRoleArn"
      ],
      "members": {
        "fieldLogLevel": {},
        "cloudWatchLogsRoleArn": {},
        "excludeVerboseContent": {
          "type": "boolean"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "required": [
        "userPoolId",
        "awsRegion",
        "defaultAction"
      ],
      "members": {
        "userPoolId": {},
        "awsRegion": {},
        "defaultAction": {},
        "appIdClientRegex": {}
      }
    },
    "S1w": {
      "type": "structure",
      "required": [
        "issuer"
      ],
      "members": {
        "issuer": {},
        "clientId": {},
        "iatTTL": {
          "type": "long"
        },
        "authTTL": {
          "type": "long"
        }
      }
    },
    "S1x": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "authenticationType": {},
          "openIDConnectConfig": {
            "shape": "S1w"
          },
          "userPoolConfig": {
            "type": "structure",
            "required": [
              "userPoolId",
              "awsRegion"
            ],
            "members": {
              "userPoolId": {},
              "awsRegion": {},
              "appIdClientRegex": {}
            }
          },
          "lambdaAuthorizerConfig": {
            "shape": "S23"
          }
        }
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "authorizerUri"
      ],
      "members": {
        "authorizerResultTtlInSeconds": {
          "type": "integer"
        },
        "authorizerUri": {},
        "identityValidationExpression": {}
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "name": {},
        "apiId": {},
        "authenticationType": {},
        "logConfig": {
          "shape": "S1r"
        },
        "userPoolConfig": {
          "shape": "S1u"
        },
        "openIDConnectConfig": {
          "shape": "S1w"
        },
        "arn": {},
        "uris": {
          "shape": "S29"
        },
        "tags": {
          "shape": "S1x"
        },
        "additionalAuthenticationProviders": {
          "shape": "S20"
        },
        "xrayEnabled": {
          "type": "boolean"
        },
        "wafWebAclArn": {},
        "lambdaAuthorizerConfig": {
          "shape": "S23"
        },
        "dns": {
          "shape": "S29"
        },
        "visibility": {},
        "apiType": {},
        "mergedApiExecutionRoleArn": {},
        "owner": {},
        "ownerContact": {}
      }
    },
    "S29": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2c": {
      "type": "structure",
      "members": {
        "functions": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "ttl"
      ],
      "members": {
        "ttl": {
          "type": "long"
        },
        "cachingKeys": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "typeName": {},
        "fieldName": {},
        "dataSourceName": {},
        "resolverArn": {},
        "requestMappingTemplate": {},
        "responseMappingTemplate": {},
        "kind": {},
        "pipelineConfig": {
          "shape": "S2c"
        },
        "syncConfig": {
          "shape": "S1g"
        },
        "cachingConfig": {
          "shape": "S2e"
        },
        "maxBatchSize": {
          "type": "integer"
        },
        "runtime": {
          "shape": "S1l"
        },
        "code": {}
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "name": {},
        "description": {},
        "arn": {},
        "definition": {},
        "format": {}
      }
    },
    "S3k": {
      "type": "list",
      "member": {}
    },
    "S51": {
      "type": "list",
      "member": {
        "shape": "S2h"
      }
    },
    "S5d": {
      "type": "list",
      "member": {
        "shape": "S2l"
      }
    }
  }
}