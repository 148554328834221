{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "entityresolution",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWSEntityResolution",
    "serviceFullName": "AWS EntityResolution",
    "serviceId": "EntityResolution",
    "signatureVersion": "v4",
    "signingName": "entityresolution",
    "uid": "entityresolution-2018-05-10"
  },
  "operations": {
    "CreateMatchingWorkflow": {
      "http": {
        "requestUri": "/matchingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "S5"
          },
          "outputSourceConfig": {
            "shape": "Sa"
          },
          "resolutionTechniques": {
            "shape": "Sh"
          },
          "roleArn": {},
          "tags": {
            "shape": "Sq"
          },
          "workflowName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "S5"
          },
          "outputSourceConfig": {
            "shape": "Sa"
          },
          "resolutionTechniques": {
            "shape": "Sh"
          },
          "roleArn": {},
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "CreateSchemaMapping": {
      "http": {
        "requestUri": "/schemas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "Sw"
          },
          "schemaName": {},
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "description",
          "mappedInputFields",
          "schemaArn",
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "Sw"
          },
          "schemaArn": {},
          "schemaName": {}
        }
      }
    },
    "DeleteMatchingWorkflow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {}
        }
      },
      "idempotent": true
    },
    "DeleteSchemaMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/schemas/{schemaName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "schemaName"
        ],
        "members": {
          "schemaName": {
            "location": "uri",
            "locationName": "schemaName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {}
        }
      },
      "idempotent": true
    },
    "GetMatchId": {
      "http": {
        "requestUri": "/matchingworkflows/{workflowName}/matches",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "record",
          "workflowName"
        ],
        "members": {
          "record": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "matchId": {}
        }
      }
    },
    "GetMatchingJob": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}/jobs/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "workflowName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId",
          "startTime",
          "status"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "errorDetails": {
            "type": "structure",
            "members": {
              "errorMessage": {}
            }
          },
          "jobId": {},
          "metrics": {
            "type": "structure",
            "members": {
              "inputRecords": {
                "type": "integer"
              },
              "matchIDs": {
                "type": "integer"
              },
              "recordsNotProcessed": {
                "type": "integer"
              },
              "totalRecordsProcessed": {
                "type": "integer"
              }
            }
          },
          "startTime": {
            "type": "timestamp"
          },
          "status": {}
        }
      }
    },
    "GetMatchingWorkflow": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "updatedAt",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "incrementalRunConfig": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "S5"
          },
          "outputSourceConfig": {
            "shape": "Sa"
          },
          "resolutionTechniques": {
            "shape": "Sh"
          },
          "roleArn": {},
          "tags": {
            "shape": "Sq"
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "GetSchemaMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/schemas/{schemaName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "schemaName"
        ],
        "members": {
          "schemaName": {
            "location": "uri",
            "locationName": "schemaName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "mappedInputFields",
          "schemaArn",
          "schemaName",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "mappedInputFields": {
            "shape": "Sw"
          },
          "schemaArn": {},
          "schemaName": {},
          "tags": {
            "shape": "Sq"
          },
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListMatchingJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobId",
                "startTime",
                "status"
              ],
              "members": {
                "endTime": {
                  "type": "timestamp"
                },
                "jobId": {},
                "startTime": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMatchingWorkflows": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workflowSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "updatedAt",
                "workflowArn",
                "workflowName"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                },
                "workflowArn": {},
                "workflowName": {}
              }
            }
          }
        }
      }
    },
    "ListSchemaMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/schemas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "schemaList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "schemaArn",
                "schemaName",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "schemaArn": {},
                "schemaName": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "Sq"
          }
        }
      }
    },
    "StartMatchingJob": {
      "http": {
        "requestUri": "/matchingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateMatchingWorkflow": {
      "http": {
        "method": "PUT",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "S5"
          },
          "outputSourceConfig": {
            "shape": "Sa"
          },
          "resolutionTechniques": {
            "shape": "Sh"
          },
          "roleArn": {},
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "S5"
          },
          "outputSourceConfig": {
            "shape": "Sa"
          },
          "resolutionTechniques": {
            "shape": "Sh"
          },
          "roleArn": {},
          "workflowName": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "incrementalRunType": {}
      }
    },
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "inputSourceARN",
          "schemaName"
        ],
        "members": {
          "applyNormalization": {
            "type": "boolean"
          },
          "inputSourceARN": {},
          "schemaName": {}
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "output",
          "outputS3Path"
        ],
        "members": {
          "KMSArn": {},
          "applyNormalization": {
            "type": "boolean"
          },
          "output": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name"
              ],
              "members": {
                "hashed": {
                  "type": "boolean"
                },
                "name": {}
              }
            }
          },
          "outputS3Path": {}
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "resolutionType": {},
        "ruleBasedProperties": {
          "type": "structure",
          "required": [
            "attributeMatchingModel",
            "rules"
          ],
          "members": {
            "attributeMatchingModel": {},
            "rules": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "matchingKeys",
                  "ruleName"
                ],
                "members": {
                  "matchingKeys": {
                    "type": "list",
                    "member": {}
                  },
                  "ruleName": {}
                }
              }
            }
          }
        }
      }
    },
    "Sq": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "fieldName",
          "type"
        ],
        "members": {
          "fieldName": {},
          "groupName": {},
          "matchKey": {},
          "type": {}
        }
      }
    }
  }
}