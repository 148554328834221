{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-13",
    "endpointPrefix": "ecs",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon ECS",
    "serviceFullName": "Amazon EC2 Container Service",
    "serviceId": "ECS",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonEC2ContainerServiceV20141113",
    "uid": "ecs-2014-11-13"
  },
  "operations": {
    "CreateCapacityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "autoScalingGroupProvider"
        ],
        "members": {
          "name": {},
          "autoScalingGroupProvider": {
            "shape": "S3"
          },
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProvider": {
            "shape": "Sf"
          }
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "members": {
          "clusterName": {},
          "tags": {
            "shape": "Sa"
          },
          "settings": {
            "shape": "Sj"
          },
          "configuration": {
            "shape": "Sm"
          },
          "capacityProviders": {
            "shape": "Sr"
          },
          "defaultCapacityProviderStrategy": {
            "shape": "Ss"
          },
          "serviceConnectDefaults": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sy"
          }
        }
      }
    },
    "CreateService": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName"
        ],
        "members": {
          "cluster": {},
          "serviceName": {},
          "taskDefinition": {},
          "loadBalancers": {
            "shape": "S17"
          },
          "serviceRegistries": {
            "shape": "S1a"
          },
          "desiredCount": {
            "type": "integer"
          },
          "clientToken": {},
          "launchType": {},
          "capacityProviderStrategy": {
            "shape": "Ss"
          },
          "platformVersion": {},
          "role": {},
          "deploymentConfiguration": {
            "shape": "S1d"
          },
          "placementConstraints": {
            "shape": "S1g"
          },
          "placementStrategy": {
            "shape": "S1j"
          },
          "networkConfiguration": {
            "shape": "S1m"
          },
          "healthCheckGracePeriodSeconds": {
            "type": "integer"
          },
          "schedulingStrategy": {},
          "deploymentController": {
            "shape": "S1q"
          },
          "tags": {
            "shape": "Sa"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "propagateTags": {},
          "enableExecuteCommand": {
            "type": "boolean"
          },
          "serviceConnectConfiguration": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S25"
          }
        }
      }
    },
    "CreateTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "service",
          "cluster",
          "taskDefinition"
        ],
        "members": {
          "service": {},
          "cluster": {},
          "externalId": {},
          "taskDefinition": {},
          "networkConfiguration": {
            "shape": "S1m"
          },
          "loadBalancers": {
            "shape": "S17"
          },
          "serviceRegistries": {
            "shape": "S1a"
          },
          "launchType": {},
          "capacityProviderStrategy": {
            "shape": "Ss"
          },
          "platformVersion": {},
          "scale": {
            "shape": "S29"
          },
          "clientToken": {},
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S27"
          }
        }
      }
    },
    "DeleteAccountSetting": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "principalArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S2p"
          }
        }
      }
    },
    "DeleteAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "S2r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S2r"
          }
        }
      }
    },
    "DeleteCapacityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "capacityProvider"
        ],
        "members": {
          "capacityProvider": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProvider": {
            "shape": "Sf"
          }
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sy"
          }
        }
      }
    },
    "DeleteService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S25"
          }
        }
      }
    },
    "DeleteTaskDefinitions": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinitions"
        ],
        "members": {
          "taskDefinitions": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinitions": {
            "type": "list",
            "member": {
              "shape": "S35"
            }
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DeleteTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "taskSet"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSet": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S27"
          }
        }
      }
    },
    "DeregisterContainerInstance": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S5c"
          }
        }
      }
    },
    "DeregisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S35"
          }
        }
      }
    },
    "DescribeCapacityProviders": {
      "input": {
        "type": "structure",
        "members": {
          "capacityProviders": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProviders": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          },
          "failures": {
            "shape": "S56"
          },
          "nextToken": {}
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "clusters": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "type": "list",
            "member": {
              "shape": "Sy"
            }
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DescribeContainerInstances": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S63"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DescribeServices": {
      "input": {
        "type": "structure",
        "required": [
          "services"
        ],
        "members": {
          "cluster": {},
          "services": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "services": {
            "type": "list",
            "member": {
              "shape": "S25"
            }
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DescribeTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {},
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S35"
          },
          "tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "DescribeTaskSets": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSets": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSets": {
            "shape": "S26"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DescribeTasks": {
      "input": {
        "type": "structure",
        "required": [
          "tasks"
        ],
        "members": {
          "cluster": {},
          "tasks": {
            "shape": "Sr"
          },
          "include": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S6l"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "DiscoverPollEndpoint": {
      "input": {
        "type": "structure",
        "members": {
          "containerInstance": {},
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {},
          "telemetryEndpoint": {},
          "serviceConnectEndpoint": {}
        }
      }
    },
    "ExecuteCommand": {
      "input": {
        "type": "structure",
        "required": [
          "command",
          "interactive",
          "task"
        ],
        "members": {
          "cluster": {},
          "container": {},
          "command": {},
          "interactive": {
            "type": "boolean"
          },
          "task": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusterArn": {},
          "containerArn": {},
          "containerName": {},
          "interactive": {
            "type": "boolean"
          },
          "session": {
            "type": "structure",
            "members": {
              "sessionId": {},
              "streamUrl": {},
              "tokenValue": {
                "type": "string",
                "sensitive": true
              }
            }
          },
          "taskArn": {}
        }
      }
    },
    "GetTaskProtection": {
      "input": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {},
          "tasks": {
            "shape": "Sr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "protectedTasks": {
            "shape": "S7d"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "ListAccountSettings": {
      "input": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {},
          "principalArn": {},
          "effectiveSettings": {
            "type": "boolean"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "settings": {
            "type": "list",
            "member": {
              "shape": "S2p"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "targetType"
        ],
        "members": {
          "cluster": {},
          "targetType": {},
          "attributeName": {},
          "attributeValue": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S2r"
          },
          "nextToken": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusterArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListContainerInstances": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "filter": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstanceArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "launchType": {},
          "schedulingStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListServicesByNamespace": {
      "input": {
        "type": "structure",
        "required": [
          "namespace"
        ],
        "members": {
          "namespace": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "ListTaskDefinitionFamilies": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "families": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListTaskDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "sort": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinitionArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "ListTasks": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "containerInstance": {},
          "family": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "startedBy": {},
          "serviceName": {},
          "desiredStatus": {},
          "launchType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskArns": {
            "shape": "Sr"
          },
          "nextToken": {}
        }
      }
    },
    "PutAccountSetting": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {},
          "principalArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S2p"
          }
        }
      }
    },
    "PutAccountSettingDefault": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "setting": {
            "shape": "S2p"
          }
        }
      }
    },
    "PutAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "S2r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "S2r"
          }
        }
      }
    },
    "PutClusterCapacityProviders": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "capacityProviders",
          "defaultCapacityProviderStrategy"
        ],
        "members": {
          "cluster": {},
          "capacityProviders": {
            "shape": "Sr"
          },
          "defaultCapacityProviderStrategy": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sy"
          }
        }
      }
    },
    "RegisterContainerInstance": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "instanceIdentityDocument": {},
          "instanceIdentityDocumentSignature": {},
          "totalResources": {
            "shape": "S5f"
          },
          "versionInfo": {
            "shape": "S5e"
          },
          "containerInstanceArn": {},
          "attributes": {
            "shape": "S2r"
          },
          "platformDevices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "type"
              ],
              "members": {
                "id": {},
                "type": {}
              }
            }
          },
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S5c"
          }
        }
      }
    },
    "RegisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "family",
          "containerDefinitions"
        ],
        "members": {
          "family": {},
          "taskRoleArn": {},
          "executionRoleArn": {},
          "networkMode": {},
          "containerDefinitions": {
            "shape": "S36"
          },
          "volumes": {
            "shape": "S4c"
          },
          "placementConstraints": {
            "shape": "S4q"
          },
          "requiresCompatibilities": {
            "shape": "S4t"
          },
          "cpu": {},
          "memory": {},
          "tags": {
            "shape": "Sa"
          },
          "pidMode": {},
          "ipcMode": {},
          "proxyConfiguration": {
            "shape": "S52"
          },
          "inferenceAccelerators": {
            "shape": "S4y"
          },
          "ephemeralStorage": {
            "shape": "S55"
          },
          "runtimePlatform": {
            "shape": "S4v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S35"
          },
          "tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "RunTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "capacityProviderStrategy": {
            "shape": "Ss"
          },
          "cluster": {},
          "count": {
            "type": "integer"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "enableExecuteCommand": {
            "type": "boolean"
          },
          "group": {},
          "launchType": {},
          "networkConfiguration": {
            "shape": "S1m"
          },
          "overrides": {
            "shape": "S6z"
          },
          "placementConstraints": {
            "shape": "S1g"
          },
          "placementStrategy": {
            "shape": "S1j"
          },
          "platformVersion": {},
          "propagateTags": {},
          "referenceId": {},
          "startedBy": {},
          "tags": {
            "shape": "Sa"
          },
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S6l"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "StartTask": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances",
          "taskDefinition"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sr"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "enableExecuteCommand": {
            "type": "boolean"
          },
          "group": {},
          "networkConfiguration": {
            "shape": "S1m"
          },
          "overrides": {
            "shape": "S6z"
          },
          "propagateTags": {},
          "referenceId": {},
          "startedBy": {},
          "tags": {
            "shape": "Sa"
          },
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S6l"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "StopTask": {
      "input": {
        "type": "structure",
        "required": [
          "task"
        ],
        "members": {
          "cluster": {},
          "task": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "task": {
            "shape": "S6m"
          }
        }
      }
    },
    "SubmitAttachmentStateChanges": {
      "input": {
        "type": "structure",
        "required": [
          "attachments"
        ],
        "members": {
          "cluster": {},
          "attachments": {
            "shape": "S8q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "SubmitContainerStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "containerName": {},
          "runtimeId": {},
          "status": {},
          "exitCode": {
            "type": "integer"
          },
          "reason": {},
          "networkBindings": {
            "shape": "S6q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "SubmitTaskStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "status": {},
          "reason": {},
          "containers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "containerName": {},
                "imageDigest": {},
                "runtimeId": {},
                "exitCode": {
                  "type": "integer"
                },
                "networkBindings": {
                  "shape": "S6q"
                },
                "reason": {},
                "status": {}
              }
            }
          },
          "attachments": {
            "shape": "S8q"
          },
          "managedAgents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "containerName",
                "managedAgentName",
                "status"
              ],
              "members": {
                "containerName": {},
                "managedAgentName": {},
                "status": {},
                "reason": {}
              }
            }
          },
          "pullStartedAt": {
            "type": "timestamp"
          },
          "pullStoppedAt": {
            "type": "timestamp"
          },
          "executionStoppedAt": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateCapacityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "autoScalingGroupProvider"
        ],
        "members": {
          "name": {},
          "autoScalingGroupProvider": {
            "type": "structure",
            "members": {
              "managedScaling": {
                "shape": "S4"
              },
              "managedTerminationProtection": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "capacityProvider": {
            "shape": "Sf"
          }
        }
      }
    },
    "UpdateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {},
          "settings": {
            "shape": "Sj"
          },
          "configuration": {
            "shape": "Sm"
          },
          "serviceConnectDefaults": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sy"
          }
        }
      }
    },
    "UpdateClusterSettings": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "settings"
        ],
        "members": {
          "cluster": {},
          "settings": {
            "shape": "Sj"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sy"
          }
        }
      }
    },
    "UpdateContainerAgent": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S5c"
          }
        }
      }
    },
    "UpdateContainerInstancesState": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances",
          "status"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "Sr"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S63"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "UpdateService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "desiredCount": {
            "type": "integer"
          },
          "taskDefinition": {},
          "capacityProviderStrategy": {
            "shape": "Ss"
          },
          "deploymentConfiguration": {
            "shape": "S1d"
          },
          "networkConfiguration": {
            "shape": "S1m"
          },
          "placementConstraints": {
            "shape": "S1g"
          },
          "placementStrategy": {
            "shape": "S1j"
          },
          "platformVersion": {},
          "forceNewDeployment": {
            "type": "boolean"
          },
          "healthCheckGracePeriodSeconds": {
            "type": "integer"
          },
          "enableExecuteCommand": {
            "type": "boolean"
          },
          "enableECSManagedTags": {
            "type": "boolean"
          },
          "loadBalancers": {
            "shape": "S17"
          },
          "propagateTags": {},
          "serviceRegistries": {
            "shape": "S1a"
          },
          "serviceConnectConfiguration": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "S25"
          }
        }
      }
    },
    "UpdateServicePrimaryTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "primaryTaskSet"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "primaryTaskSet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S27"
          }
        }
      }
    },
    "UpdateTaskProtection": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "tasks",
          "protectionEnabled"
        ],
        "members": {
          "cluster": {},
          "tasks": {
            "shape": "Sr"
          },
          "protectionEnabled": {
            "type": "boolean"
          },
          "expiresInMinutes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "protectedTasks": {
            "shape": "S7d"
          },
          "failures": {
            "shape": "S56"
          }
        }
      }
    },
    "UpdateTaskSet": {
      "input": {
        "type": "structure",
        "required": [
          "cluster",
          "service",
          "taskSet",
          "scale"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "taskSet": {},
          "scale": {
            "shape": "S29"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskSet": {
            "shape": "S27"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "autoScalingGroupArn"
      ],
      "members": {
        "autoScalingGroupArn": {},
        "managedScaling": {
          "shape": "S4"
        },
        "managedTerminationProtection": {}
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "status": {},
        "targetCapacity": {
          "type": "integer"
        },
        "minimumScalingStepSize": {
          "type": "integer"
        },
        "maximumScalingStepSize": {
          "type": "integer"
        },
        "instanceWarmupPeriod": {
          "type": "integer"
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "capacityProviderArn": {},
        "name": {},
        "status": {},
        "autoScalingGroupProvider": {
          "shape": "S3"
        },
        "updateStatus": {},
        "updateStatusReason": {},
        "tags": {
          "shape": "Sa"
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "executeCommandConfiguration": {
          "type": "structure",
          "members": {
            "kmsKeyId": {},
            "logging": {},
            "logConfiguration": {
              "type": "structure",
              "members": {
                "cloudWatchLogGroupName": {},
                "cloudWatchEncryptionEnabled": {
                  "type": "boolean"
                },
                "s3BucketName": {},
                "s3EncryptionEnabled": {
                  "type": "boolean"
                },
                "s3KeyPrefix": {}
              }
            }
          }
        }
      }
    },
    "Sr": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "capacityProvider"
        ],
        "members": {
          "capacityProvider": {},
          "weight": {
            "type": "integer"
          },
          "base": {
            "type": "integer"
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "namespace"
      ],
      "members": {
        "namespace": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "clusterArn": {},
        "clusterName": {},
        "configuration": {
          "shape": "Sm"
        },
        "status": {},
        "registeredContainerInstancesCount": {
          "type": "integer"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "activeServicesCount": {
          "type": "integer"
        },
        "statistics": {
          "type": "list",
          "member": {
            "shape": "S11"
          }
        },
        "tags": {
          "shape": "Sa"
        },
        "settings": {
          "shape": "Sj"
        },
        "capacityProviders": {
          "shape": "Sr"
        },
        "defaultCapacityProviderStrategy": {
          "shape": "Ss"
        },
        "attachments": {
          "shape": "S12"
        },
        "attachmentsStatus": {},
        "serviceConnectDefaults": {
          "type": "structure",
          "members": {
            "namespace": {}
          }
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "name": {},
        "value": {}
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "id": {},
          "type": {},
          "status": {},
          "details": {
            "type": "list",
            "member": {
              "shape": "S11"
            }
          }
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "targetGroupArn": {},
          "loadBalancerName": {},
          "containerName": {},
          "containerPort": {
            "type": "integer"
          }
        }
      }
    },
    "S1a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "registryArn": {},
          "port": {
            "type": "integer"
          },
          "containerName": {},
          "containerPort": {
            "type": "integer"
          }
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "deploymentCircuitBreaker": {
          "type": "structure",
          "required": [
            "enable",
            "rollback"
          ],
          "members": {
            "enable": {
              "type": "boolean"
            },
            "rollback": {
              "type": "boolean"
            }
          }
        },
        "maximumPercent": {
          "type": "integer"
        },
        "minimumHealthyPercent": {
          "type": "integer"
        },
        "alarms": {
          "type": "structure",
          "required": [
            "alarmNames",
            "enable",
            "rollback"
          ],
          "members": {
            "alarmNames": {
              "shape": "Sr"
            },
            "enable": {
              "type": "boolean"
            },
            "rollback": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "field": {}
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "awsvpcConfiguration": {
          "type": "structure",
          "required": [
            "subnets"
          ],
          "members": {
            "subnets": {
              "shape": "Sr"
            },
            "securityGroups": {
              "shape": "Sr"
            },
            "assignPublicIp": {}
          }
        }
      }
    },
    "S1q": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {}
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "enabled"
      ],
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "namespace": {},
        "services": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portName"
            ],
            "members": {
              "portName": {},
              "discoveryName": {},
              "clientAliases": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "port"
                  ],
                  "members": {
                    "port": {
                      "type": "integer"
                    },
                    "dnsName": {}
                  }
                }
              },
              "ingressPortOverride": {
                "type": "integer"
              }
            }
          }
        },
        "logConfiguration": {
          "shape": "S1z"
        }
      }
    },
    "S1z": {
      "type": "structure",
      "required": [
        "logDriver"
      ],
      "members": {
        "logDriver": {},
        "options": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "secretOptions": {
          "shape": "S22"
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "valueFrom"
        ],
        "members": {
          "name": {},
          "valueFrom": {}
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "serviceArn": {},
        "serviceName": {},
        "clusterArn": {},
        "loadBalancers": {
          "shape": "S17"
        },
        "serviceRegistries": {
          "shape": "S1a"
        },
        "status": {},
        "desiredCount": {
          "type": "integer"
        },
        "runningCount": {
          "type": "integer"
        },
        "pendingCount": {
          "type": "integer"
        },
        "launchType": {},
        "capacityProviderStrategy": {
          "shape": "Ss"
        },
        "platformVersion": {},
        "platformFamily": {},
        "taskDefinition": {},
        "deploymentConfiguration": {
          "shape": "S1d"
        },
        "taskSets": {
          "shape": "S26"
        },
        "deployments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "status": {},
              "taskDefinition": {},
              "desiredCount": {
                "type": "integer"
              },
              "pendingCount": {
                "type": "integer"
              },
              "runningCount": {
                "type": "integer"
              },
              "failedTasks": {
                "type": "integer"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "updatedAt": {
                "type": "timestamp"
              },
              "capacityProviderStrategy": {
                "shape": "Ss"
              },
              "launchType": {},
              "platformVersion": {},
              "platformFamily": {},
              "networkConfiguration": {
                "shape": "S1m"
              },
              "rolloutState": {},
              "rolloutStateReason": {},
              "serviceConnectConfiguration": {
                "shape": "S1t"
              },
              "serviceConnectResources": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "discoveryName": {},
                    "discoveryArn": {}
                  }
                }
              }
            }
          }
        },
        "roleArn": {},
        "events": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "createdAt": {
                "type": "timestamp"
              },
              "message": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "placementConstraints": {
          "shape": "S1g"
        },
        "placementStrategy": {
          "shape": "S1j"
        },
        "networkConfiguration": {
          "shape": "S1m"
        },
        "healthCheckGracePeriodSeconds": {
          "type": "integer"
        },
        "schedulingStrategy": {},
        "deploymentController": {
          "shape": "S1q"
        },
        "tags": {
          "shape": "Sa"
        },
        "createdBy": {},
        "enableECSManagedTags": {
          "type": "boolean"
        },
        "propagateTags": {},
        "enableExecuteCommand": {
          "type": "boolean"
        }
      }
    },
    "S26": {
      "type": "list",
      "member": {
        "shape": "S27"
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "id": {},
        "taskSetArn": {},
        "serviceArn": {},
        "clusterArn": {},
        "startedBy": {},
        "externalId": {},
        "status": {},
        "taskDefinition": {},
        "computedDesiredCount": {
          "type": "integer"
        },
        "pendingCount": {
          "type": "integer"
        },
        "runningCount": {
          "type": "integer"
        },
        "createdAt": {
          "type": "timestamp"
        },
        "updatedAt": {
          "type": "timestamp"
        },
        "launchType": {},
        "capacityProviderStrategy": {
          "shape": "Ss"
        },
        "platformVersion": {},
        "platformFamily": {},
        "networkConfiguration": {
          "shape": "S1m"
        },
        "loadBalancers": {
          "shape": "S17"
        },
        "serviceRegistries": {
          "shape": "S1a"
        },
        "scale": {
          "shape": "S29"
        },
        "stabilityStatus": {},
        "stabilityStatusAt": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "Sa"
        }
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "value": {
          "type": "double"
        },
        "unit": {}
      }
    },
    "S2p": {
      "type": "structure",
      "members": {
        "name": {},
        "value": {},
        "principalArn": {}
      }
    },
    "S2r": {
      "type": "list",
      "member": {
        "shape": "S2s"
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "value": {},
        "targetType": {},
        "targetId": {}
      }
    },
    "S35": {
      "type": "structure",
      "members": {
        "taskDefinitionArn": {},
        "containerDefinitions": {
          "shape": "S36"
        },
        "family": {},
        "taskRoleArn": {},
        "executionRoleArn": {},
        "networkMode": {},
        "revision": {
          "type": "integer"
        },
        "volumes": {
          "shape": "S4c"
        },
        "status": {},
        "requiresAttributes": {
          "type": "list",
          "member": {
            "shape": "S2s"
          }
        },
        "placementConstraints": {
          "shape": "S4q"
        },
        "compatibilities": {
          "shape": "S4t"
        },
        "runtimePlatform": {
          "shape": "S4v"
        },
        "requiresCompatibilities": {
          "shape": "S4t"
        },
        "cpu": {},
        "memory": {},
        "inferenceAccelerators": {
          "shape": "S4y"
        },
        "pidMode": {},
        "ipcMode": {},
        "proxyConfiguration": {
          "shape": "S52"
        },
        "registeredAt": {
          "type": "timestamp"
        },
        "deregisteredAt": {
          "type": "timestamp"
        },
        "registeredBy": {},
        "ephemeralStorage": {
          "shape": "S55"
        }
      }
    },
    "S36": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "image": {},
          "repositoryCredentials": {
            "type": "structure",
            "required": [
              "credentialsParameter"
            ],
            "members": {
              "credentialsParameter": {}
            }
          },
          "cpu": {
            "type": "integer"
          },
          "memory": {
            "type": "integer"
          },
          "memoryReservation": {
            "type": "integer"
          },
          "links": {
            "shape": "Sr"
          },
          "portMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "containerPort": {
                  "type": "integer"
                },
                "hostPort": {
                  "type": "integer"
                },
                "protocol": {},
                "name": {},
                "appProtocol": {},
                "containerPortRange": {}
              }
            }
          },
          "essential": {
            "type": "boolean"
          },
          "entryPoint": {
            "shape": "Sr"
          },
          "command": {
            "shape": "Sr"
          },
          "environment": {
            "shape": "S3d"
          },
          "environmentFiles": {
            "shape": "S3e"
          },
          "mountPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceVolume": {},
                "containerPath": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "volumesFrom": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceContainer": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "linuxParameters": {
            "type": "structure",
            "members": {
              "capabilities": {
                "type": "structure",
                "members": {
                  "add": {
                    "shape": "Sr"
                  },
                  "drop": {
                    "shape": "Sr"
                  }
                }
              },
              "devices": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "hostPath"
                  ],
                  "members": {
                    "hostPath": {},
                    "containerPath": {},
                    "permissions": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              },
              "initProcessEnabled": {
                "type": "boolean"
              },
              "sharedMemorySize": {
                "type": "integer"
              },
              "tmpfs": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "containerPath",
                    "size"
                  ],
                  "members": {
                    "containerPath": {},
                    "size": {
                      "type": "integer"
                    },
                    "mountOptions": {
                      "shape": "Sr"
                    }
                  }
                }
              },
              "maxSwap": {
                "type": "integer"
              },
              "swappiness": {
                "type": "integer"
              }
            }
          },
          "secrets": {
            "shape": "S22"
          },
          "dependsOn": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "containerName",
                "condition"
              ],
              "members": {
                "containerName": {},
                "condition": {}
              }
            }
          },
          "startTimeout": {
            "type": "integer"
          },
          "stopTimeout": {
            "type": "integer"
          },
          "hostname": {},
          "user": {},
          "workingDirectory": {},
          "disableNetworking": {
            "type": "boolean"
          },
          "privileged": {
            "type": "boolean"
          },
          "readonlyRootFilesystem": {
            "type": "boolean"
          },
          "dnsServers": {
            "shape": "Sr"
          },
          "dnsSearchDomains": {
            "shape": "Sr"
          },
          "extraHosts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "hostname",
                "ipAddress"
              ],
              "members": {
                "hostname": {},
                "ipAddress": {}
              }
            }
          },
          "dockerSecurityOptions": {
            "shape": "Sr"
          },
          "interactive": {
            "type": "boolean"
          },
          "pseudoTerminal": {
            "type": "boolean"
          },
          "dockerLabels": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "ulimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "softLimit",
                "hardLimit"
              ],
              "members": {
                "name": {},
                "softLimit": {
                  "type": "integer"
                },
                "hardLimit": {
                  "type": "integer"
                }
              }
            }
          },
          "logConfiguration": {
            "shape": "S1z"
          },
          "healthCheck": {
            "type": "structure",
            "required": [
              "command"
            ],
            "members": {
              "command": {
                "shape": "Sr"
              },
              "interval": {
                "type": "integer"
              },
              "timeout": {
                "type": "integer"
              },
              "retries": {
                "type": "integer"
              },
              "startPeriod": {
                "type": "integer"
              }
            }
          },
          "systemControls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "namespace": {},
                "value": {}
              }
            }
          },
          "resourceRequirements": {
            "shape": "S45"
          },
          "firelensConfiguration": {
            "type": "structure",
            "required": [
              "type"
            ],
            "members": {
              "type": {},
              "options": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          },
          "credentialSpecs": {
            "shape": "Sr"
          }
        }
      }
    },
    "S3d": {
      "type": "list",
      "member": {
        "shape": "S11"
      }
    },
    "S3e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S45": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S4c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "host": {
            "type": "structure",
            "members": {
              "sourcePath": {}
            }
          },
          "dockerVolumeConfiguration": {
            "type": "structure",
            "members": {
              "scope": {},
              "autoprovision": {
                "type": "boolean"
              },
              "driver": {},
              "driverOpts": {
                "shape": "S4h"
              },
              "labels": {
                "shape": "S4h"
              }
            }
          },
          "efsVolumeConfiguration": {
            "type": "structure",
            "required": [
              "fileSystemId"
            ],
            "members": {
              "fileSystemId": {},
              "rootDirectory": {},
              "transitEncryption": {},
              "transitEncryptionPort": {
                "type": "integer"
              },
              "authorizationConfig": {
                "type": "structure",
                "members": {
                  "accessPointId": {},
                  "iam": {}
                }
              }
            }
          },
          "fsxWindowsFileServerVolumeConfiguration": {
            "type": "structure",
            "required": [
              "fileSystemId",
              "rootDirectory",
              "authorizationConfig"
            ],
            "members": {
              "fileSystemId": {},
              "rootDirectory": {},
              "authorizationConfig": {
                "type": "structure",
                "required": [
                  "credentialsParameter",
                  "domain"
                ],
                "members": {
                  "credentialsParameter": {},
                  "domain": {}
                }
              }
            }
          }
        }
      }
    },
    "S4h": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "S4t": {
      "type": "list",
      "member": {}
    },
    "S4v": {
      "type": "structure",
      "members": {
        "cpuArchitecture": {},
        "operatingSystemFamily": {}
      }
    },
    "S4y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "deviceName",
          "deviceType"
        ],
        "members": {
          "deviceName": {},
          "deviceType": {}
        }
      }
    },
    "S52": {
      "type": "structure",
      "required": [
        "containerName"
      ],
      "members": {
        "type": {},
        "containerName": {},
        "properties": {
          "type": "list",
          "member": {
            "shape": "S11"
          }
        }
      }
    },
    "S55": {
      "type": "structure",
      "required": [
        "sizeInGiB"
      ],
      "members": {
        "sizeInGiB": {
          "type": "integer"
        }
      }
    },
    "S56": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "reason": {},
          "detail": {}
        }
      }
    },
    "S5c": {
      "type": "structure",
      "members": {
        "containerInstanceArn": {},
        "ec2InstanceId": {},
        "capacityProviderName": {},
        "version": {
          "type": "long"
        },
        "versionInfo": {
          "shape": "S5e"
        },
        "remainingResources": {
          "shape": "S5f"
        },
        "registeredResources": {
          "shape": "S5f"
        },
        "status": {},
        "statusReason": {},
        "agentConnected": {
          "type": "boolean"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "agentUpdateStatus": {},
        "attributes": {
          "shape": "S2r"
        },
        "registeredAt": {
          "type": "timestamp"
        },
        "attachments": {
          "shape": "S12"
        },
        "tags": {
          "shape": "Sa"
        },
        "healthStatus": {
          "type": "structure",
          "members": {
            "overallStatus": {},
            "details": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "type": {},
                  "status": {},
                  "lastUpdated": {
                    "type": "timestamp"
                  },
                  "lastStatusChange": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "agentVersion": {},
        "agentHash": {},
        "dockerVersion": {}
      }
    },
    "S5f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {},
          "doubleValue": {
            "type": "double"
          },
          "longValue": {
            "type": "long"
          },
          "integerValue": {
            "type": "integer"
          },
          "stringSetValue": {
            "shape": "Sr"
          }
        }
      }
    },
    "S63": {
      "type": "list",
      "member": {
        "shape": "S5c"
      }
    },
    "S6l": {
      "type": "list",
      "member": {
        "shape": "S6m"
      }
    },
    "S6m": {
      "type": "structure",
      "members": {
        "attachments": {
          "shape": "S12"
        },
        "attributes": {
          "shape": "S2r"
        },
        "availabilityZone": {},
        "capacityProviderName": {},
        "clusterArn": {},
        "connectivity": {},
        "connectivityAt": {
          "type": "timestamp"
        },
        "containerInstanceArn": {},
        "containers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "containerArn": {},
              "taskArn": {},
              "name": {},
              "image": {},
              "imageDigest": {},
              "runtimeId": {},
              "lastStatus": {},
              "exitCode": {
                "type": "integer"
              },
              "reason": {},
              "networkBindings": {
                "shape": "S6q"
              },
              "networkInterfaces": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "attachmentId": {},
                    "privateIpv4Address": {},
                    "ipv6Address": {}
                  }
                }
              },
              "healthStatus": {},
              "managedAgents": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "lastStartedAt": {
                      "type": "timestamp"
                    },
                    "name": {},
                    "reason": {},
                    "lastStatus": {}
                  }
                }
              },
              "cpu": {},
              "memory": {},
              "memoryReservation": {},
              "gpuIds": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "cpu": {},
        "createdAt": {
          "type": "timestamp"
        },
        "desiredStatus": {},
        "enableExecuteCommand": {
          "type": "boolean"
        },
        "executionStoppedAt": {
          "type": "timestamp"
        },
        "group": {},
        "healthStatus": {},
        "inferenceAccelerators": {
          "shape": "S4y"
        },
        "lastStatus": {},
        "launchType": {},
        "memory": {},
        "overrides": {
          "shape": "S6z"
        },
        "platformVersion": {},
        "platformFamily": {},
        "pullStartedAt": {
          "type": "timestamp"
        },
        "pullStoppedAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "startedBy": {},
        "stopCode": {},
        "stoppedAt": {
          "type": "timestamp"
        },
        "stoppedReason": {},
        "stoppingAt": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "Sa"
        },
        "taskArn": {},
        "taskDefinitionArn": {},
        "version": {
          "type": "long"
        },
        "ephemeralStorage": {
          "shape": "S55"
        }
      }
    },
    "S6q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "bindIP": {},
          "containerPort": {
            "type": "integer"
          },
          "hostPort": {
            "type": "integer"
          },
          "protocol": {},
          "containerPortRange": {},
          "hostPortRange": {}
        }
      }
    },
    "S6z": {
      "type": "structure",
      "members": {
        "containerOverrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "command": {
                "shape": "Sr"
              },
              "environment": {
                "shape": "S3d"
              },
              "environmentFiles": {
                "shape": "S3e"
              },
              "cpu": {
                "type": "integer"
              },
              "memory": {
                "type": "integer"
              },
              "memoryReservation": {
                "type": "integer"
              },
              "resourceRequirements": {
                "shape": "S45"
              }
            }
          }
        },
        "cpu": {},
        "inferenceAcceleratorOverrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "deviceName": {},
              "deviceType": {}
            }
          }
        },
        "executionRoleArn": {},
        "memory": {},
        "taskRoleArn": {},
        "ephemeralStorage": {
          "shape": "S55"
        }
      }
    },
    "S7d": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "taskArn": {},
          "protectionEnabled": {
            "type": "boolean"
          },
          "expirationDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "S8q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "attachmentArn",
          "status"
        ],
        "members": {
          "attachmentArn": {},
          "status": {}
        }
      }
    }
  }
}