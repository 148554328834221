{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-08-04",
    "endpointPrefix": "firehose",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Firehose",
    "serviceFullName": "Amazon Kinesis Firehose",
    "serviceId": "Firehose",
    "signatureVersion": "v4",
    "targetPrefix": "Firehose_20150804",
    "uid": "firehose-2015-08-04"
  },
  "operations": {
    "CreateDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "DeliveryStreamType": {},
          "KinesisStreamSourceConfiguration": {
            "type": "structure",
            "required": [
              "KinesisStreamARN",
              "RoleARN"
            ],
            "members": {
              "KinesisStreamARN": {},
              "RoleARN": {}
            }
          },
          "DeliveryStreamEncryptionConfigurationInput": {
            "shape": "S7"
          },
          "S3DestinationConfiguration": {
            "shape": "Sa",
            "deprecated": true
          },
          "ExtendedS3DestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "BucketARN"
            ],
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Se"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Si"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "Sa"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sz"
              },
              "DynamicPartitioningConfiguration": {
                "shape": "S1o"
              }
            }
          },
          "RedshiftDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "ClusterJDBCURL",
              "CopyCommand",
              "Username",
              "Password",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1t"
              },
              "Username": {
                "shape": "S1x"
              },
              "Password": {
                "shape": "S1y"
              },
              "RetryOptions": {
                "shape": "S1z"
              },
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "Sa"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "ElasticsearchDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "IndexName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S28"
              },
              "RetryOptions": {
                "shape": "S2b"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "VpcConfiguration": {
                "shape": "S2e"
              }
            }
          },
          "AmazonopensearchserviceDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "IndexName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S2n"
              },
              "RetryOptions": {
                "shape": "S2q"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "VpcConfiguration": {
                "shape": "S2e"
              }
            }
          },
          "SplunkDestinationConfiguration": {
            "type": "structure",
            "required": [
              "HECEndpoint",
              "HECEndpointType",
              "HECToken",
              "S3Configuration"
            ],
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2y"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "HttpEndpointDestinationConfiguration": {
            "type": "structure",
            "required": [
              "EndpointConfiguration",
              "S3Configuration"
            ],
            "members": {
              "EndpointConfiguration": {
                "shape": "S32"
              },
              "BufferingHints": {
                "shape": "S36"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "RequestConfiguration": {
                "shape": "S39"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "RoleARN": {},
              "RetryOptions": {
                "shape": "S3f"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              }
            }
          },
          "Tags": {
            "shape": "S3i"
          },
          "AmazonOpenSearchServerlessDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "IndexName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "CollectionEndpoint": {},
              "IndexName": {},
              "BufferingHints": {
                "shape": "S3p"
              },
              "RetryOptions": {
                "shape": "S3s"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "Sa"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "VpcConfiguration": {
                "shape": "S2e"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryStreamARN": {}
        }
      }
    },
    "DeleteDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "AllowForceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartDestinationId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamDescription"
        ],
        "members": {
          "DeliveryStreamDescription": {
            "type": "structure",
            "required": [
              "DeliveryStreamName",
              "DeliveryStreamARN",
              "DeliveryStreamStatus",
              "DeliveryStreamType",
              "VersionId",
              "Destinations",
              "HasMoreDestinations"
            ],
            "members": {
              "DeliveryStreamName": {},
              "DeliveryStreamARN": {},
              "DeliveryStreamStatus": {},
              "FailureDescription": {
                "shape": "S45"
              },
              "DeliveryStreamEncryptionConfiguration": {
                "type": "structure",
                "members": {
                  "KeyARN": {},
                  "KeyType": {},
                  "Status": {},
                  "FailureDescription": {
                    "shape": "S45"
                  }
                }
              },
              "DeliveryStreamType": {},
              "VersionId": {},
              "CreateTimestamp": {
                "type": "timestamp"
              },
              "LastUpdateTimestamp": {
                "type": "timestamp"
              },
              "Source": {
                "type": "structure",
                "members": {
                  "KinesisStreamSourceDescription": {
                    "type": "structure",
                    "members": {
                      "KinesisStreamARN": {},
                      "RoleARN": {},
                      "DeliveryStartTimestamp": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              },
              "Destinations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DestinationId"
                  ],
                  "members": {
                    "DestinationId": {},
                    "S3DestinationDescription": {
                      "shape": "S4g"
                    },
                    "ExtendedS3DestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "BucketARN",
                        "BufferingHints",
                        "CompressionFormat",
                        "EncryptionConfiguration"
                      ],
                      "members": {
                        "RoleARN": {},
                        "BucketARN": {},
                        "Prefix": {},
                        "ErrorOutputPrefix": {},
                        "BufferingHints": {
                          "shape": "Se"
                        },
                        "CompressionFormat": {},
                        "EncryptionConfiguration": {
                          "shape": "Si"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S4g"
                        },
                        "DataFormatConversionConfiguration": {
                          "shape": "Sz"
                        },
                        "DynamicPartitioningConfiguration": {
                          "shape": "S1o"
                        }
                      }
                    },
                    "RedshiftDestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "ClusterJDBCURL",
                        "CopyCommand",
                        "Username",
                        "S3DestinationDescription"
                      ],
                      "members": {
                        "RoleARN": {},
                        "ClusterJDBCURL": {},
                        "CopyCommand": {
                          "shape": "S1t"
                        },
                        "Username": {
                          "shape": "S1x"
                        },
                        "RetryOptions": {
                          "shape": "S1z"
                        },
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S4g"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        }
                      }
                    },
                    "ElasticsearchDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "DomainARN": {},
                        "ClusterEndpoint": {},
                        "IndexName": {},
                        "TypeName": {},
                        "IndexRotationPeriod": {},
                        "BufferingHints": {
                          "shape": "S28"
                        },
                        "RetryOptions": {
                          "shape": "S2b"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "VpcConfigurationDescription": {
                          "shape": "S4k"
                        }
                      }
                    },
                    "AmazonopensearchserviceDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "DomainARN": {},
                        "ClusterEndpoint": {},
                        "IndexName": {},
                        "TypeName": {},
                        "IndexRotationPeriod": {},
                        "BufferingHints": {
                          "shape": "S2n"
                        },
                        "RetryOptions": {
                          "shape": "S2q"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "VpcConfigurationDescription": {
                          "shape": "S4k"
                        }
                      }
                    },
                    "SplunkDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "HECEndpoint": {},
                        "HECEndpointType": {},
                        "HECToken": {},
                        "HECAcknowledgmentTimeoutInSeconds": {
                          "type": "integer"
                        },
                        "RetryOptions": {
                          "shape": "S2y"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        }
                      }
                    },
                    "HttpEndpointDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "EndpointConfiguration": {
                          "type": "structure",
                          "members": {
                            "Url": {
                              "shape": "S33"
                            },
                            "Name": {}
                          }
                        },
                        "BufferingHints": {
                          "shape": "S36"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "RequestConfiguration": {
                          "shape": "S39"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "RoleARN": {},
                        "RetryOptions": {
                          "shape": "S3f"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        }
                      }
                    },
                    "AmazonOpenSearchServerlessDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "CollectionEndpoint": {},
                        "IndexName": {},
                        "BufferingHints": {
                          "shape": "S3p"
                        },
                        "RetryOptions": {
                          "shape": "S3s"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S4g"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sq"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sl"
                        },
                        "VpcConfigurationDescription": {
                          "shape": "S4k"
                        }
                      }
                    }
                  }
                }
              },
              "HasMoreDestinations": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "ListDeliveryStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "DeliveryStreamType": {},
          "ExclusiveStartDeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamNames",
          "HasMoreDeliveryStreams"
        ],
        "members": {
          "DeliveryStreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreDeliveryStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "ExclusiveStartTagKey": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "HasMoreTags"
        ],
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S3j"
            }
          },
          "HasMoreTags": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Record"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Record": {
            "shape": "S4z"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RecordId"
        ],
        "members": {
          "RecordId": {},
          "Encrypted": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecordBatch": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Records"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Records": {
            "type": "list",
            "member": {
              "shape": "S4z"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedPutCount",
          "RequestResponses"
        ],
        "members": {
          "FailedPutCount": {
            "type": "integer"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "RequestResponses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecordId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "StartDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "DeliveryStreamEncryptionConfigurationInput": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Tags"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Tags": {
            "shape": "S3i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "TagKeys"
        ],
        "members": {
          "DeliveryStreamName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDestination": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "CurrentDeliveryStreamVersionId",
          "DestinationId"
        ],
        "members": {
          "DeliveryStreamName": {},
          "CurrentDeliveryStreamVersionId": {},
          "DestinationId": {},
          "S3DestinationUpdate": {
            "shape": "S5k",
            "deprecated": true
          },
          "ExtendedS3DestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Se"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Si"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S5k"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sz"
              },
              "DynamicPartitioningConfiguration": {
                "shape": "S1o"
              }
            }
          },
          "RedshiftDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1t"
              },
              "Username": {
                "shape": "S1x"
              },
              "Password": {
                "shape": "S1y"
              },
              "RetryOptions": {
                "shape": "S1z"
              },
              "S3Update": {
                "shape": "S5k"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S5k"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "ElasticsearchDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S28"
              },
              "RetryOptions": {
                "shape": "S2b"
              },
              "S3Update": {
                "shape": "S5k"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "AmazonopensearchserviceDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "ClusterEndpoint": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S2n"
              },
              "RetryOptions": {
                "shape": "S2q"
              },
              "S3Update": {
                "shape": "S5k"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "SplunkDestinationUpdate": {
            "type": "structure",
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2y"
              },
              "S3BackupMode": {},
              "S3Update": {
                "shape": "S5k"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          },
          "HttpEndpointDestinationUpdate": {
            "type": "structure",
            "members": {
              "EndpointConfiguration": {
                "shape": "S32"
              },
              "BufferingHints": {
                "shape": "S36"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              },
              "RequestConfiguration": {
                "shape": "S39"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "RoleARN": {},
              "RetryOptions": {
                "shape": "S3f"
              },
              "S3BackupMode": {},
              "S3Update": {
                "shape": "S5k"
              }
            }
          },
          "AmazonOpenSearchServerlessDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "CollectionEndpoint": {},
              "IndexName": {},
              "BufferingHints": {
                "shape": "S3p"
              },
              "RetryOptions": {
                "shape": "S3s"
              },
              "S3Update": {
                "shape": "S5k"
              },
              "ProcessingConfiguration": {
                "shape": "Sq"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sl"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "KeyType"
      ],
      "members": {
        "KeyARN": {},
        "KeyType": {}
      }
    },
    "Sa": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "SizeInMBs": {
          "type": "integer"
        },
        "IntervalInSeconds": {
          "type": "integer"
        }
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "NoEncryptionConfig": {},
        "KMSEncryptionConfig": {
          "type": "structure",
          "required": [
            "AWSKMSKeyARN"
          ],
          "members": {
            "AWSKMSKeyARN": {}
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogGroupName": {},
        "LogStreamName": {}
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Processors": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "ParameterName",
                    "ParameterValue"
                  ],
                  "members": {
                    "ParameterName": {},
                    "ParameterValue": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "SchemaConfiguration": {
          "type": "structure",
          "members": {
            "RoleARN": {},
            "CatalogId": {},
            "DatabaseName": {},
            "TableName": {},
            "Region": {},
            "VersionId": {}
          }
        },
        "InputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Deserializer": {
              "type": "structure",
              "members": {
                "OpenXJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "ConvertDotsInJsonKeysToUnderscores": {
                      "type": "boolean"
                    },
                    "CaseInsensitive": {
                      "type": "boolean"
                    },
                    "ColumnToJsonKeyMappings": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    }
                  }
                },
                "HiveJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "TimestampFormats": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        },
        "OutputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Serializer": {
              "type": "structure",
              "members": {
                "ParquetSerDe": {
                  "type": "structure",
                  "members": {
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "PageSizeBytes": {
                      "type": "integer"
                    },
                    "Compression": {},
                    "EnableDictionaryCompression": {
                      "type": "boolean"
                    },
                    "MaxPaddingBytes": {
                      "type": "integer"
                    },
                    "WriterVersion": {}
                  }
                },
                "OrcSerDe": {
                  "type": "structure",
                  "members": {
                    "StripeSizeBytes": {
                      "type": "integer"
                    },
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "RowIndexStride": {
                      "type": "integer"
                    },
                    "EnablePadding": {
                      "type": "boolean"
                    },
                    "PaddingTolerance": {
                      "type": "double"
                    },
                    "Compression": {},
                    "BloomFilterColumns": {
                      "type": "list",
                      "member": {}
                    },
                    "BloomFilterFalsePositiveProbability": {
                      "type": "double"
                    },
                    "DictionaryKeyThreshold": {
                      "type": "double"
                    },
                    "FormatVersion": {}
                  }
                }
              }
            }
          }
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "RetryOptions": {
          "type": "structure",
          "members": {
            "DurationInSeconds": {
              "type": "integer"
            }
          }
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "DataTableName"
      ],
      "members": {
        "DataTableName": {},
        "DataTableColumns": {},
        "CopyOptions": {}
      }
    },
    "S1x": {
      "type": "string",
      "sensitive": true
    },
    "S1y": {
      "type": "string",
      "sensitive": true
    },
    "S1z": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2e": {
      "type": "structure",
      "required": [
        "SubnetIds",
        "RoleARN",
        "SecurityGroupIds"
      ],
      "members": {
        "SubnetIds": {
          "shape": "S2f"
        },
        "RoleARN": {},
        "SecurityGroupIds": {
          "shape": "S2g"
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "list",
      "member": {}
    },
    "S2n": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "Url"
      ],
      "members": {
        "Url": {
          "shape": "S33"
        },
        "Name": {},
        "AccessKey": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S33": {
      "type": "string",
      "sensitive": true
    },
    "S36": {
      "type": "structure",
      "members": {
        "SizeInMBs": {
          "type": "integer"
        },
        "IntervalInSeconds": {
          "type": "integer"
        }
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "ContentEncoding": {},
        "CommonAttributes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AttributeName",
              "AttributeValue"
            ],
            "members": {
              "AttributeName": {
                "type": "string",
                "sensitive": true
              },
              "AttributeValue": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S3i": {
      "type": "list",
      "member": {
        "shape": "S3j"
      }
    },
    "S3j": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S3p": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S45": {
      "type": "structure",
      "required": [
        "Type",
        "Details"
      ],
      "members": {
        "Type": {},
        "Details": {}
      }
    },
    "S4g": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN",
        "BufferingHints",
        "CompressionFormat",
        "EncryptionConfiguration"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    },
    "S4k": {
      "type": "structure",
      "required": [
        "SubnetIds",
        "RoleARN",
        "SecurityGroupIds",
        "VpcId"
      ],
      "members": {
        "SubnetIds": {
          "shape": "S2f"
        },
        "RoleARN": {},
        "SecurityGroupIds": {
          "shape": "S2g"
        },
        "VpcId": {}
      }
    },
    "S4z": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {
          "type": "blob"
        }
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Se"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Si"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sl"
        }
      }
    }
  }
}