{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-05-04",
    "endpointPrefix": "managedblockchain-query",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "ripServiceName": "chainquery",
    "serviceFullName": "Amazon Managed Blockchain Query",
    "serviceId": "ManagedBlockchain Query",
    "signatureVersion": "v4",
    "signingName": "managedblockchain-query",
    "uid": "managedblockchain-query-2023-05-04"
  },
  "operations": {
    "BatchGetTokenBalance": {
      "http": {
        "requestUri": "/batch-get-token-balance",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "getTokenBalanceInputs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "tokenIdentifier",
                "ownerIdentifier"
              ],
              "members": {
                "tokenIdentifier": {
                  "shape": "S4"
                },
                "ownerIdentifier": {
                  "shape": "S8"
                },
                "atBlockchainInstant": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tokenBalances",
          "errors"
        ],
        "members": {
          "tokenBalances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "balance",
                "atBlockchainInstant"
              ],
              "members": {
                "ownerIdentifier": {
                  "shape": "S8"
                },
                "tokenIdentifier": {
                  "shape": "S4"
                },
                "balance": {},
                "atBlockchainInstant": {
                  "shape": "S9"
                },
                "lastUpdatedTime": {
                  "shape": "S9"
                }
              }
            }
          },
          "errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "errorCode",
                "errorMessage",
                "errorType"
              ],
              "members": {
                "tokenIdentifier": {
                  "shape": "S4"
                },
                "ownerIdentifier": {
                  "shape": "S8"
                },
                "atBlockchainInstant": {
                  "shape": "S9"
                },
                "errorCode": {},
                "errorMessage": {},
                "errorType": {}
              }
            }
          }
        }
      }
    },
    "GetTokenBalance": {
      "http": {
        "requestUri": "/get-token-balance",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "tokenIdentifier",
          "ownerIdentifier"
        ],
        "members": {
          "tokenIdentifier": {
            "shape": "S4"
          },
          "ownerIdentifier": {
            "shape": "S8"
          },
          "atBlockchainInstant": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "balance",
          "atBlockchainInstant"
        ],
        "members": {
          "ownerIdentifier": {
            "shape": "S8"
          },
          "tokenIdentifier": {
            "shape": "S4"
          },
          "balance": {},
          "atBlockchainInstant": {
            "shape": "S9"
          },
          "lastUpdatedTime": {
            "shape": "S9"
          }
        }
      }
    },
    "GetTransaction": {
      "http": {
        "requestUri": "/get-transaction",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "transactionHash",
          "network"
        ],
        "members": {
          "transactionHash": {},
          "network": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "transaction"
        ],
        "members": {
          "transaction": {
            "type": "structure",
            "required": [
              "network",
              "transactionHash",
              "transactionTimestamp",
              "transactionIndex",
              "numberOfTransactions",
              "status",
              "to"
            ],
            "members": {
              "network": {},
              "blockHash": {},
              "transactionHash": {},
              "blockNumber": {},
              "transactionTimestamp": {
                "type": "timestamp"
              },
              "transactionIndex": {
                "type": "long"
              },
              "numberOfTransactions": {
                "type": "long"
              },
              "status": {},
              "to": {},
              "from": {},
              "contractAddress": {},
              "gasUsed": {},
              "cumulativeGasUsed": {},
              "effectiveGasPrice": {},
              "signatureV": {
                "type": "integer"
              },
              "signatureR": {},
              "signatureS": {},
              "transactionFee": {},
              "transactionId": {}
            }
          }
        }
      }
    },
    "ListTokenBalances": {
      "http": {
        "requestUri": "/list-token-balances",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "tokenFilter"
        ],
        "members": {
          "ownerFilter": {
            "type": "structure",
            "required": [
              "address"
            ],
            "members": {
              "address": {}
            }
          },
          "tokenFilter": {
            "type": "structure",
            "required": [
              "network"
            ],
            "members": {
              "network": {},
              "contractAddress": {},
              "tokenId": {}
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tokenBalances"
        ],
        "members": {
          "tokenBalances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "balance",
                "atBlockchainInstant"
              ],
              "members": {
                "ownerIdentifier": {
                  "shape": "S8"
                },
                "tokenIdentifier": {
                  "shape": "S4"
                },
                "balance": {},
                "atBlockchainInstant": {
                  "shape": "S9"
                },
                "lastUpdatedTime": {
                  "shape": "S9"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTransactionEvents": {
      "http": {
        "requestUri": "/list-transaction-events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "transactionHash",
          "network"
        ],
        "members": {
          "transactionHash": {},
          "network": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "events"
        ],
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "network",
                "transactionHash",
                "eventType"
              ],
              "members": {
                "network": {},
                "transactionHash": {},
                "eventType": {},
                "from": {},
                "to": {},
                "value": {},
                "contractAddress": {},
                "tokenId": {},
                "transactionId": {},
                "voutIndex": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTransactions": {
      "http": {
        "requestUri": "/list-transactions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "address",
          "network"
        ],
        "members": {
          "address": {},
          "network": {},
          "fromBlockchainInstant": {
            "shape": "S9"
          },
          "toBlockchainInstant": {
            "shape": "S9"
          },
          "sort": {
            "type": "structure",
            "members": {
              "sortBy": {},
              "sortOrder": {}
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "transactions"
        ],
        "members": {
          "transactions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "transactionHash",
                "network",
                "transactionTimestamp"
              ],
              "members": {
                "transactionHash": {},
                "network": {},
                "transactionTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "network"
      ],
      "members": {
        "network": {},
        "contractAddress": {},
        "tokenId": {}
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "address"
      ],
      "members": {
        "address": {}
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "time": {
          "type": "timestamp"
        }
      }
    }
  }
}